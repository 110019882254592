import { ManagingPlatform } from './externally-managed';
import { BaseFundTransfer } from './fund-transfer';

export enum PaymentType {
  Swift = 'swift',
  SEPA = 'SEPA',
  Wire = 'wire',
  ACH = 'ACH',
  FPS = 'FPS',
  EFT = 'EFT'
}

export enum PaymentBeneficiaryType {
  // Used for withdrawals. These beneficiaries represent the operational bank accounts held by partners outside of our platform.
  PartnerOwnedByPartner = 'partner-owned-by-partner',
  // Used for top-ups. These beneficiaries represent the partner accounts from which same-currency withdrawals can be made.
  // These accounts also receive top-ups from Grain, in order for us to hold our quoted commitment for either withdrawals or hedging.
  PartnerOwnedByGrain = 'partner-owned-by-grain',
  // Used for fees and getting excess amounts that can be the result of different spot rates.
  // These beneficiaries represent Grain's treasury (operational) accounts, held at banking vendors that give Grain access to liquidity.
  GrainTreasury = 'grain-treasury',
  // Used for payouts. These beneficiaries represent the operational bank accounts held by partners' vendors outside of our platform.
  Vendor = 'vendor'
}

export interface BankAccount {
  currency: string;
  routingInfos: RoutingInfo[];
}

export interface PaymentBeneficiary {
  id?: string;
  nickname: string;
  currency: string;
  isDeprecated?: boolean;
  type: PaymentBeneficiaryType;
  routingInfos: RoutingInfo[];
}

export interface CreateSandboxPaymentBeneficiaryRequest {
  organizationId: string;
  currencies?: string[];
}

export interface PaymentBeneficiarySummary {
  id: string;
  nickname: string;
  currency: string;
}

export interface RoutingInfo {
  id?: string;
  accountHolderName: string;
  externalId: string;
  accountNumber: string;
  routingCode?: string;
  bicSwift?: string;
  bankCode?: string;
  branchCode?: string;
  iban?: string;
  sortCode?: string;
  bankName: string;
  bankAddress: string;
  bankCountry: string;
  paymentType: PaymentType;
  managedBy: ManagingPlatform;
  additionalDetails?: Partial<AdditionalRoutingDetails>;
  paymentBeneficiary?: PaymentBeneficiary;
  specialInstructions?: string;
  bsbCode?: string;
}

export interface CollectionExternalTransfer {
  externalId: string;
  externalAccountId: string;
  name?: string;
  address?: string;
  country?: string;
  bic?: string;
  routingCode?: string;
  sendingAccountNumber?: string;
  receivingAccountNumber?: string;
  amount: number;
  currency: string;
  completedAt: Date;
  valueDate: Date;
  note: string;
  reference?: string;
}

export interface CreateSandboxBankAccountRequest {
  organizationId: string;
  currencies?: string[];
}

export interface AdditionalRoutingDetails {
  iban: string;
  bicSwift: string;
  acctNumber: string;
  clabe: string;
  bsbCode: string;
  bankCode: string;
  branchCode: string;
  cnaps: string;
  aba: string;
  sortCode: string;
  ifsc: string;
  [key: string]: string;
}

export interface BankAccountWithBalance extends BankAccount {
  id: string;
  balance: number;
  displayCurrencyBalance: number;
}

export interface BankAccountWithTransfers extends BankAccount {
  id: string;
  fundTransfers: BaseFundTransfer[];
}

export interface BankAccountWithExternalBalance extends BankAccount {
  externalBalance: number;
}
