import { FundTransfer, FundTransferType, FundTransferUpdatableFields } from '@grain/core-types';
import { WithAbortSignal } from '@grain/web-api-types';
import { BaseService } from '@grain/web-utils';

export default class FundTransfersService extends BaseService {
  private static instance: FundTransfersService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new FundTransfersService(baseUrl, handleError);
  }

  public static getInstance(): FundTransfersService {
    return this.instance;
  }

  async getFundTransfers(payload: WithAbortSignal<GetFundTransfersParams>): GetFundTransfersResponse {
    const { signal, ...requestParams } = payload || {};

    const { fundTransfers, totalFundTransfers }: FundTransfersResponse = await this.authenticatedCall({
      url: 'fund-transfers/v2',
      method: 'GET',
      params: requestParams,
      signal
    });

    return {
      fundTransfers,
      totalFundTransfers
    };
  }

  async updateFundTransfer(fundTransferId: string, fundTransferType: string, updatableFields: FundTransferUpdatableFields): Promise<void> {
    await this.authenticatedCall({
      url: `fund-transfers/${fundTransferId}`,
      method: 'PUT',
      data: { fundTransferType, updatableFields }
    });
  }

  async persistFundTransfers(): Promise<void> {
    await this.authenticatedCall({
      url: 'fund-transfers/persist',
      method: 'POST'
    });
  }
}

export type GetFundTransfersParams = {
  searchTerm?: string;
  type?: FundTransferType;
  page?: number;
  perPage?: number;
};

interface FundTransfersResponse {
  fundTransfers: FundTransfer[];
  totalFundTransfers: number;
}

type GetFundTransfersResponse = Promise<{
  fundTransfers: FundTransfer[];
  totalFundTransfers: number;
}>;
