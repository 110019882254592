import {
  CurrencyPair,
  CurrencyPairWithSettlement,
  ForexDirection,
  ForexResponse,
  GrainCurrencyPairHedgeForwardOrderProvider,
  HedgeSettlementType
} from '@grain/core-types';
import { getCountryDataList } from 'countries-list';
import { BaseHttpError, HttpError, StatusCodes } from '@grain/api-utils';

export const POALIM_SUPPORTED_CURRENCIES = [
  'AED',
  'AUD',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MXN',
  'NOK',
  'NZD',
  'PLN',
  'RON',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'USD',
  'ZAR'
];

export const TRANSFER_MATE_SUPPORTED_CURRENCIES = [
  'AED',
  'AUD',
  'BGN',
  'BHD',
  'BWP',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'MYR',
  'MXN',
  'NOK',
  'OMR',
  'PLN',
  'QAR',
  'SAR',
  'SEK',
  'SGD',
  'TND',
  'UGX',
  'USD',
  'ZAR'
];

export const PERIODIC_RATES_CURRENCIES_USING_SPREADS = ['BRL', 'CNY'];

export const MAJOR_CURRENCIES = ['EUR', 'GBP', 'USD'] as const;
export type MajorCurrency = (typeof MAJOR_CURRENCIES)[number];
export function isMajorCurrency(currency: string): currency is MajorCurrency {
  return MAJOR_CURRENCIES.includes(currency as MajorCurrency);
}

export const demoCurrencies = [...MAJOR_CURRENCIES, 'MXN'];

export const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'CHF', 'AUD', 'CNY', 'HKD', 'NOK', 'PLN', 'MXN'];

// Subset of the list found in our current exchange-rate API: https://oxr.readme.io/docs/supported-currencies
export const allCurrencies = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTC',
  'BTN',
  'BWP',
  'BYN',
  'BYR',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLF',
  'CLP',
  'CNH',
  'CNY',
  'COP',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MRU',
  'MTL',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'YER',
  'ZAR',
  'ZMK',
  'ZMW'
];

export const forexSymbols: string[] = [
  'AUD/CAD',
  'AUD/CHF',
  'AUD/CNH',
  'AUD/CZK',
  'AUD/DKK',
  'AUD/HKD',
  'AUD/HUF',
  'AUD/JPY',
  'AUD/MXN',
  'AUD/NOK',
  'AUD/NZD',
  'AUD/PLN',
  'AUD/SEK',
  'AUD/SGD',
  'AUD/USD',
  'AUD/ZAR',
  'CAD/CHF',
  'CAD/CNH',
  'CAD/CZK',
  'CAD/DKK',
  'CAD/HKD',
  'CAD/HUF',
  'CAD/JPY',
  'CAD/MXN',
  'CAD/NOK',
  'CAD/PLN',
  'CAD/SEK',
  'CAD/SGD',
  'CAD/ZAR',
  'CHF/CNH',
  'CHF/CZK',
  'CHF/DKK',
  'CHF/HKD',
  'CHF/HUF',
  'CHF/JPY',
  'CHF/MXN',
  'CHF/NOK',
  'CHF/PLN',
  'USD/SAR',
  'CAD/THB',
  'CHF/SEK',
  'CHF/SGD',
  'CHF/TRY',
  'CHF/ZAR',
  'DKK/CZK',
  'DKK/HKD',
  'DKK/HUF',
  'DKK/MXN',
  'DKK/NOK',
  'DKK/PLN',
  'DKK/SEK',
  'DKK/SGD',
  'DKK/ZAR',
  'EUR/AUD',
  'EUR/CAD',
  'EUR/CHF',
  'EUR/CNH',
  'EUR/CZK',
  'EUR/DKK',
  'EUR/GBP',
  'EUR/HKD',
  'EUR/HUF',
  'EUR/ILS',
  'EUR/JPY',
  'EUR/MXN',
  'EUR/NOK',
  'EUR/NZD',
  'EUR/PLN',
  'EUR/SAR',
  'GBP/SAR',
  'EUR/SEK',
  'EUR/SGD',
  'EUR/TRY',
  'EUR/USD',
  'EUR/ZAR',
  'GBP/AUD',
  'GBP/CAD',
  'GBP/CHF',
  'GBP/CNH',
  'GBP/CZK',
  'GBP/DKK',
  'GBP/HKD',
  'GBP/HUF',
  'GBP/JPY',
  'GBP/MXN',
  'GBP/NOK',
  'GBP/NZD',
  'GBP/PLN',
  'GBP/SEK',
  'GBP/SGD',
  'GBP/USD',
  'GBP/ZAR',
  'JPY/CZK',
  'JPY/DKK',
  'JPY/HKD',
  'JPY/HUF',
  'JPY/MXN',
  'JPY/NOK',
  'EUR/THB',
  'GBP/THB',
  'JPY/PLN',
  'JPY/SEK',
  'JPY/SGD',
  'JPY/ZAR',
  'NOK/CZK',
  'NOK/HKD',
  'NOK/HUF',
  'NOK/MXN',
  'NOK/PLN',
  'NOK/SEK',
  'NOK/SGD',
  'NOK/ZAR',
  'NZD/CAD',
  'NZD/CHF',
  'NZD/CZK',
  'NZD/DKK',
  'NZD/HKD',
  'NZD/HUF',
  'NZD/JPY',
  'NZD/MXN',
  'NZD/NOK',
  'NZD/PLN',
  'NZD/SEK',
  'NZD/SGD',
  'NZD/USD',
  'NZD/ZAR',
  'USD/CAD',
  'USD/CHF',
  'USD/THB',
  'CAD/TRY',
  'USD/CNH',
  'USD/CZK',
  'USD/DKK',
  'USD/HKD',
  'USD/HUF',
  'USD/ILS',
  'USD/JPY',
  'USD/MXN',
  'USD/NOK',
  'USD/PLN',
  'USD/SEK',
  'USD/SGD',
  'USD/TRY',
  'USD/ZAR',
  'CAD/AED',
  'EUR/AED',
  'GBP/AED',
  'USD/AED',
  'CAD/BGN',
  'EUR/BGN',
  'GBP/BGN',
  'USD/BGN',
  'CAD/BHD',
  'EUR/BHD',
  'GBP/BHD',
  'USD/BHD',
  'CAD/CNY',
  'EUR/CNY',
  'GBP/CNY',
  'USD/CNY',
  'CAD/ILS',
  'GBP/ILS',
  'CAD/KWD',
  'EUR/KWD',
  'GBP/KWD',
  'USD/KWD',
  'CAD/OMR',
  'EUR/OMR',
  'CAD/OMR',
  'GBP/OMR',
  'USD/OMR',
  'CAD/QAR',
  'EUR/QAR',
  'GBP/QAR',
  'USD/QAR',
  'CAD/RON',
  'EUR/RON',
  'GBP/RON',
  'USD/RON',
  'CAD/SAR',
  'GBP/TRY',
  'EUR/BRL',
  'USD/BRL',
  'GBP/BRL',
  'EUR/KRW',
  'USD/KRW',
  'GBP/KRW',
  'USD/IDR',
  'EUR/IDR',
  'GBP/IDR',
  'USD/INR',
  'EUR/INR',
  'GBP/INR',
  'USD/PHP',
  'EUR/PHP',
  'GBP/PHP'
];

const forexSymbolsSet = new Set(forexSymbols);

export enum CurrencyHolidaySource {
  MANUAL = 'manual', // from manual-defined-holidays.ts
  HOLIDAYS = 'holidays' // from date-holidays package
}

export interface CurrencyMapping {
  [currencyCode: string]: { countryCode: string; type: CurrencyHolidaySource };
}

export const currencyToCountryMapping: CurrencyMapping = {
  AED: { countryCode: 'AE', type: CurrencyHolidaySource.MANUAL },
  AUD: { countryCode: 'AU', type: CurrencyHolidaySource.MANUAL },
  BGN: { countryCode: 'BG', type: CurrencyHolidaySource.MANUAL },
  BHD: { countryCode: 'BH', type: CurrencyHolidaySource.MANUAL },
  BRL: { countryCode: 'BR', type: CurrencyHolidaySource.MANUAL },
  BWP: { countryCode: 'BW', type: CurrencyHolidaySource.MANUAL },
  CAD: { countryCode: 'CA', type: CurrencyHolidaySource.MANUAL },
  CHF: { countryCode: 'CH', type: CurrencyHolidaySource.MANUAL },
  CNH: { countryCode: 'CN', type: CurrencyHolidaySource.MANUAL },
  CNY: { countryCode: 'CN', type: CurrencyHolidaySource.MANUAL },
  CZK: { countryCode: 'CZ', type: CurrencyHolidaySource.MANUAL },
  DKK: { countryCode: 'DK', type: CurrencyHolidaySource.MANUAL },
  EUR: { countryCode: 'EU', type: CurrencyHolidaySource.MANUAL },
  GBP: { countryCode: 'GB', type: CurrencyHolidaySource.MANUAL },
  HKD: { countryCode: 'HK', type: CurrencyHolidaySource.MANUAL },
  HUF: { countryCode: 'HU', type: CurrencyHolidaySource.MANUAL },
  IDR: { countryCode: 'ID', type: CurrencyHolidaySource.MANUAL },
  ILS: { countryCode: 'IL', type: CurrencyHolidaySource.MANUAL },
  JPY: { countryCode: 'JP', type: CurrencyHolidaySource.MANUAL },
  KRW: { countryCode: 'KR', type: CurrencyHolidaySource.MANUAL },
  MYR: { countryCode: 'MY', type: CurrencyHolidaySource.MANUAL },
  MXN: { countryCode: 'MX', type: CurrencyHolidaySource.MANUAL },
  NOK: { countryCode: 'NO', type: CurrencyHolidaySource.MANUAL },
  NZD: { countryCode: 'NZ', type: CurrencyHolidaySource.MANUAL },
  OMR: { countryCode: 'OM', type: CurrencyHolidaySource.MANUAL },
  PHP: { countryCode: 'PH', type: CurrencyHolidaySource.MANUAL },
  PLN: { countryCode: 'PL', type: CurrencyHolidaySource.MANUAL },
  QAR: { countryCode: 'QA', type: CurrencyHolidaySource.MANUAL },
  RON: { countryCode: 'RO', type: CurrencyHolidaySource.MANUAL },
  SAR: { countryCode: 'SA', type: CurrencyHolidaySource.MANUAL },
  SEK: { countryCode: 'SE', type: CurrencyHolidaySource.MANUAL },
  SGD: { countryCode: 'SG', type: CurrencyHolidaySource.MANUAL },
  THB: { countryCode: 'TH', type: CurrencyHolidaySource.MANUAL },
  TND: { countryCode: 'TN', type: CurrencyHolidaySource.MANUAL },
  TRY: { countryCode: 'TR', type: CurrencyHolidaySource.MANUAL },
  UGX: { countryCode: 'UG', type: CurrencyHolidaySource.MANUAL },
  USD: { countryCode: 'US', type: CurrencyHolidaySource.MANUAL },
  ZAR: { countryCode: 'ZA', type: CurrencyHolidaySource.MANUAL },
  INR: { countryCode: 'IN', type: CurrencyHolidaySource.MANUAL }
};

export function convertCurrencyPairToPairString(currencyPair: CurrencyPair): string {
  return `${currencyPair.fromCurrency}${currencyPair.toCurrency}`;
}

export function convertCurrencyStringToCurrencyPair(pairString: string): CurrencyPair {
  return {
    fromCurrency: pairString.substring(0, 3),
    toCurrency: pairString.substring(3, 6)
  };
}

export const CURRENCY_ALIASES: { [currency: string]: string } = {
  CNH: 'CNY',
  MEX: 'MXN'
};

function _normalizeCurrency(currency: string): string {
  return CURRENCY_ALIASES[currency] || currency;
}

export function makeCurrenciesUnique(currencies: CurrencyPair[]): CurrencyPair[] {
  return Array.from(new Set(currencies.map(convertCurrencyPairToPairString))).map(convertCurrencyStringToCurrencyPair);
}

export function normalizeCurrencies(fromCurrency: string, toCurrency: string): [string, string] {
  return [_normalizeCurrency(fromCurrency), _normalizeCurrency(toCurrency)];
}

export function normalizeCurrencyPair({ fromCurrency, toCurrency }: CurrencyPair): CurrencyPair {
  return {
    fromCurrency: _normalizeCurrency(fromCurrency),
    toCurrency: _normalizeCurrency(toCurrency)
  };
}

export function convertForexSymbolStringToCurrencyPair(symbol: string): CurrencyPair {
  return {
    toCurrency: symbol.substring(0, 3),
    fromCurrency: symbol.substring(4, 7)
  };
}

export function convertCurrencyPairToForexSymbolString(currencyPair: CurrencyPair): string {
  return `${currencyPair.toCurrency}/${currencyPair.fromCurrency}`;
}

export function getForexCurrencyParams(fromCurrency: string, toCurrency: string): ForexResponse {
  let direction = ForexDirection.Buy;
  let symbol = convertCurrencyPairToForexSymbolString({
    fromCurrency,
    toCurrency
  });
  if (!forexSymbolsSet.has(symbol)) {
    symbol = convertCurrencyPairToForexSymbolString({
      fromCurrency: toCurrency,
      toCurrency: fromCurrency
    });
    if (!forexSymbolsSet.has(symbol)) {
      throw new Error(`${symbol} is not a valid forex symbol`);
    }
    direction = ForexDirection.Sell;
  }
  return {
    symbol,
    direction
  };
}

export function isDealtEqualLeadingCurrency(dealtCurrency: string, symbol: string): boolean {
  const leadingCurrency = symbol.split('/')[0];
  return dealtCurrency === leadingCurrency;
}

// Get all possible pairs from the list of currencies
export const DEMO_CURRENCY_PAIRS: CurrencyPairWithSettlement[] = demoCurrencies.flatMap((fromCurrency) =>
  demoCurrencies.flatMap((toCurrency) =>
    fromCurrency !== toCurrency
      ? {
          fromCurrency,
          toCurrency,
          settlementType: HedgeSettlementType.Collection
        }
      : []
  )
);

export const PERIODIC_RATES_USING_SPREADS_WITH_MAJOR_CURRENCIES_PAIRS: CurrencyPair[] = PERIODIC_RATES_CURRENCIES_USING_SPREADS.flatMap(
  (fromCurrency) =>
    MAJOR_CURRENCIES.flatMap((toCurrency) =>
      fromCurrency !== toCurrency
        ? {
            fromCurrency,
            toCurrency
          }
        : []
    )
);

export const manualForwardCurrencies = ['BRL', 'IDR', 'KRW', 'INR', 'PHP'];

export function calcRateByDealtCurrency(params: { isDealtAsLeadingCurrency: boolean; price: number }): number {
  const { isDealtAsLeadingCurrency, price } = params;
  return isDealtAsLeadingCurrency ? price : 1 / price;
}

export function normalizeBidPriceToRate(params: { fromCurrency: string; toCurrency: string; price: number }): number {
  const { fromCurrency, toCurrency, price } = params;
  const { symbol } = getForexCurrencyParams(fromCurrency, toCurrency);
  const dealtCurrency = toCurrency.toUpperCase();
  const isDealtAsLeadingCurrency = isDealtEqualLeadingCurrency(dealtCurrency, symbol);
  return calcRateByDealtCurrency({ isDealtAsLeadingCurrency, price });
}

export function convertSymbolToDoubleDirectionCurrencyPair(symbol: string): [CurrencyPair, CurrencyPair] {
  const [fromCurrency, toCurrency] = symbol.split('/');
  return [
    { fromCurrency, toCurrency },
    { fromCurrency: toCurrency, toCurrency: fromCurrency }
  ];
}

export function toSymbolNaive(fromCurrency: string, toCurrency: string): string {
  return `${fromCurrency}${toCurrency}`;
}

export const CURRENCIES_WITHOUT_DECIMALS = [
  'CLP',
  'DJF',
  'GNF',
  'HUF',
  'IDR',
  'ISK',
  'JPY',
  'KRW',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF'
];

export function isCurrencyWithoutDecimals(currency: string): boolean {
  return CURRENCIES_WITHOUT_DECIMALS.includes(currency);
}

export const SETTLEMENT_READY_TRANSFER_MATE_CURRENCIES = [
  'AED',
  'AUD',
  'BGN',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'ILS',
  'GBP',
  'MYR',
  'NOK',
  'PLN',
  'SAR',
  'SEK',
  'SGD',
  'USD',
  'ZAR'
];

const CURRENCIES_USED_IN_MULTIPLE_COUNTRIES_TO_MAIN_COUNTRY_CODE: Record<string, string> = {
  AUD: 'AU',
  BTN: 'BT',
  BWP: 'BW',
  DKK: 'DK',
  DZD: 'DZ',
  EUR: 'EU',
  GBP: 'GB',
  INR: 'IN',
  MAD: 'MA',
  MRU: 'MR',
  NOK: 'NO',
  NZD: 'NZ',
  USD: 'US',
  UYW: 'UY',
  ZAR: 'ZA'
};

const LEGACY_CURRENCIES_TO_COUNTRY_CODE: Record<string, string> = {
  HRK: 'HR' // croatian kuna, until 2023.
};

export const CURRENCY_TO_COUNTRY_CODE_MAPPING = getCountryDataList().reduce(
  (countriesByCurrency, country) => ({
    ...countriesByCurrency,
    ...country.currency.reduce(
      (countryByLocalCurrencies, currency) => ({
        ...countryByLocalCurrencies,
        [currency]: countriesByCurrency[currency] || country.iso2
      }),
      {}
    )
  }),
  {
    ...CURRENCIES_USED_IN_MULTIPLE_COUNTRIES_TO_MAIN_COUNTRY_CODE,
    ...LEGACY_CURRENCIES_TO_COUNTRY_CODE
  }
);

export const OPEN_EXCHANGE_NOT_SUPPORTED_CURRENCIES: string[] = ['XAG', 'XAU', 'XPD', 'XPT', 'BTC', 'XDR'];
export const MANUAL_EXCHANGE_EUR_TO_BGN = 1.956;
export const OPEN_EXCHANGE_RATES_BASE_CURRENCIES: string[] = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLF',
  'CLP',
  'CNH',
  'CNY',
  'COP',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW'
];

export const PeggedCurrencies = {
  CNY: { peggedToCurrency: 'CNH', ratio: 1 },
  SAR: { peggedToCurrency: 'USD', ratio: 0.27 },
  AED: { peggedToCurrency: 'USD', ratio: 0.27 },
  BGN: { peggedToCurrency: 'EUR', ratio: 0.51 },
  DKK: { peggedToCurrency: 'EUR', ratio: 0.13 }
} as const;

export type PeggedCurrency = keyof typeof PeggedCurrencies;
export type PeggedToCurrency = (typeof PeggedCurrencies)[PeggedCurrency]['peggedToCurrency'];
export function isPeggedCurrency(currency: string): currency is PeggedCurrency {
  return currency in PeggedCurrencies;
}
export function toPeggedOrKeepOriginal(currency: string): string | PeggedCurrency {
  if (isPeggedCurrency(currency)) {
    return PeggedCurrencies[currency].peggedToCurrency;
  }
  return currency;
}

export function transformPeggedSymbols(symbols: string[]): string[] {
  return [
    ...new Set<string>(
      symbols.map((symbol: string) => {
        const { fromCurrency, toCurrency } = convertForexSymbolStringToCurrencyPair(symbol);
        if (isPeggedCurrency(toCurrency)) {
          const peggedTo = PeggedCurrencies[toCurrency].peggedToCurrency;
          if (peggedTo !== fromCurrency) {
            const { symbol } = getForexCurrencyParams(fromCurrency, peggedTo);
            return symbol;
          }
        }
        if (isPeggedCurrency(fromCurrency)) {
          const peggedTo = PeggedCurrencies[fromCurrency].peggedToCurrency;
          if (peggedTo !== toCurrency) {
            const { symbol } = getForexCurrencyParams(peggedTo, toCurrency);
            return symbol;
          }
        }
        return symbol;
      })
    )
  ];
}

export async function convertToPegged(fromCurrency: string, toCurrency: string, amount?: number) {
  if (isPeggedCurrency(fromCurrency)) {
    const { peggedToCurrency } = PeggedCurrencies[fromCurrency];
    fromCurrency = peggedToCurrency;
  } else if (isPeggedCurrency(toCurrency)) {
    const { peggedToCurrency, ratio } = PeggedCurrencies[toCurrency];
    toCurrency = peggedToCurrency;
    amount ? (amount = amount * ratio) : null;
  }

  return { fromCurrency, toCurrency, amountInPegged: amount ? +amount.toFixed(6) : undefined };
}

export class AddGrainCurrencyPairValidationError extends BaseHttpError {
  constructor(
    message: string,
    private extraParams: Record<string, string> = {},
    private statusCode: StatusCodes = StatusCodes.BAD_REQUEST
  ) {
    super(message);
  }

  asHttpError(): HttpError {
    return new HttpError(this.statusCode, this.message, this.extraParams);
  }
}

export function peggedValidation(
  fromCurrency: string,
  toCurrency: string,
  forwardOrderProviders: GrainCurrencyPairHedgeForwardOrderProvider[]
): void {
  const fromPeggedTo = isPeggedCurrency(fromCurrency) && PeggedCurrencies[fromCurrency].peggedToCurrency;
  const toPeggedTo = isPeggedCurrency(toCurrency) && PeggedCurrencies[toCurrency].peggedToCurrency;
  const isForwardOrderProviderExist = forwardOrderProviders && forwardOrderProviders.length > 0;

  if (fromPeggedTo === toCurrency && isForwardOrderProviderExist) {
    throw new AddGrainCurrencyPairValidationError(
      `Add toCurrency ${toCurrency} which pegged to fromCurrency ${fromCurrency} only without Forward Order Provider`,
      {
        fields: 'toCurrency'
      }
    );
  }
  if (toPeggedTo === fromCurrency && isForwardOrderProviderExist) {
    throw new AddGrainCurrencyPairValidationError(
      `Add fromCurrency ${fromCurrency} which pegged to toCurrency ${toCurrency} only without Forward Order Provider`,
      {
        fields: 'fromCurrency'
      }
    );
  }
  if (fromPeggedTo && toPeggedTo) {
    throw new AddGrainCurrencyPairValidationError(
      `Both fromCurrency and toCurrency are pegged currencies fromCurrency ${fromCurrency} toCurrency ${toCurrency}`,
      { fields: 'fromCurrency,toCurrency' }
    );
  }
}
