import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle } from '@grain/web-components/dialogs/Dialog';
import React from 'react';
import { FileDropzone } from '@grain/web-components/file-dropzone/FileDropzone';
import { notifyError, notifySuccess } from '@grain/web-components/notifications/toasters';
import PartnersService from '../../../services/PartnersService';

interface UpdateHedgeEndDatesDialogProps {
  open: boolean;
  onClose?: () => void;
  partnerId: string;
}

export default function UpdateHedgeEndDatesDialog({ open, onClose, partnerId }: UpdateHedgeEndDatesDialogProps) {
  const handleFileUpload = async (file: File) => {
    try {
      await PartnersService.getInstance().uploadHedgeEndDatesFile(partnerId, file);
      onClose?.();
    } catch (error) {
      notifyError('Failed to process hedge end dates update file. Please contact the dev team.');
    }
  };
  const handleFileUploadFinished = () => {
    notifySuccess('Hedge end dates update file is being processed. You will receive a slack message when completed.');
    onClose?.();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="dialog-details-content min-w-72 bg-white">
        <DialogHeader>
          <DialogTitle className={'border-0'}>Update Hedge End Dates</DialogTitle>
        </DialogHeader>

        <DialogClose onClick={onClose} />

        <FileDropzone onFileUploaded={handleFileUploadFinished} uploadFile={handleFileUpload} />
      </DialogContent>
    </Dialog>
  );
}
