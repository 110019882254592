import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import BucketsService from '../../../../services/BucketsService';
import { queries } from 'queries';

export const useBookActiveBucket = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutateAsync: bookActiveBucketAsync,
    reset
  } = useMutation<void, Error, string>({
    mutationFn: async (id: string) => {
      await BucketsService.getInstance().bookActiveBucket(id);
    },
    onSuccess: async () => {
      notifySuccess('Active bucket booked successfully');
      await queryClient.invalidateQueries({ queryKey: queries.buckets.all.queryKey });
    }
  });

  return {
    bookActiveBucketAsync,
    isLoading,
    reset
  };
};
