import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import './Paginator.scss';

import { asAmount } from '@grain/common-utils';

export interface PaginatorProps {
  pageIndex: number;
  onPageIndexChange?: (pageIndex: number) => void;
  totalItems: number;
  totalPages: number;
  pageSize: number;
}

const FIRST_PAGE_INDEX = 0;

export function Paginator(props: PaginatorProps): ReactElement {
  const { onPageIndexChange, pageIndex, totalItems, totalPages, pageSize } = props;

  const humanReadablePageIndex = pageIndex + 1;

  const [pageInputValue, setPageInputValue] = useState<number | string>(humanReadablePageIndex);

  useEffect(
    function syncPageInputValueOnPropChange() {
      setPageInputValue(humanReadablePageIndex);
    },
    [humanReadablePageIndex]
  );

  const LAST_PAGE_INDEX = totalPages - 1;

  const canLast = pageIndex < LAST_PAGE_INDEX;
  const canNext = pageIndex < LAST_PAGE_INDEX;
  const canPrevious = pageIndex > FIRST_PAGE_INDEX;
  const minRowIndex = pageIndex * pageSize + 1;
  const maxRowIndex = Math.min(pageIndex * pageSize + pageSize, totalItems);
  const rangePrefix = totalPages > 1 ? `${asAmount(minRowIndex)}-${asAmount(maxRowIndex)} of` : '';
  const summaryRow = `${rangePrefix} ${asAmount(totalItems)} Results`;

  const changePageIndex = (pageIndex: number) => {
    onPageIndexChange?.(pageIndex);
    setPageInputValue(pageIndex + 1);
  };

  const handlePageInputBlur = () => {
    if (String(humanReadablePageIndex) !== String(pageInputValue)) {
      setPageInputValue(humanReadablePageIndex);
    }
  };

  const handlePageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: rawPageInputValue } = event.target;

    setPageInputValue(rawPageInputValue);

    const pageInputValue = Number(rawPageInputValue);

    if (!Number.isInteger(pageInputValue)) {
      return;
    }

    const pageIndex = pageInputValue - 1;

    if (pageIndex >= FIRST_PAGE_INDEX && pageIndex <= LAST_PAGE_INDEX) {
      onPageIndexChange?.(pageIndex);
    }
  };

  return (
    <div className="paginator-container">
      <div className="paginator-controls">
        <button
          className="paginator-button"
          data-testid="first-page"
          disabled={!canPrevious}
          onClick={() => changePageIndex(FIRST_PAGE_INDEX)}
          type="button"
        >
          First
        </button>
        <button
          className="paginator-button"
          data-testid="previous-page"
          disabled={!canPrevious}
          onClick={() => changePageIndex(pageIndex - 1)}
          type="button"
        >
          <ArrowLeft2 />
        </button>
        <input
          className="paginator-page-input"
          data-testid="page-number"
          disabled={!totalPages}
          inputMode="numeric"
          max={totalPages}
          min={FIRST_PAGE_INDEX}
          onBlur={handlePageInputBlur}
          onChange={handlePageInputChange}
          style={{
            width: `${totalPages != null ? (totalPages.toString().length + 1) * 8 : 28}px`
          }}
          type="text"
          value={pageInputValue}
        />
        <button
          className="paginator-button"
          data-testid="next-page"
          disabled={!canNext}
          onClick={() => changePageIndex(pageIndex + 1)}
          type="button"
        >
          <ArrowRight2 />
        </button>
        <button
          className="paginator-button"
          data-testid="last-page"
          disabled={!canLast}
          onClick={() => changePageIndex(LAST_PAGE_INDEX)}
          type="button"
        >
          Last
        </button>
      </div>
      <div className="paginator-total-items">{summaryRow}</div>
    </div>
  );
}
