import { useMutation, useQueryClient } from '@tanstack/react-query';
import FundTransfersService from '../../../services/FundTransfersService';
import { queries } from '../../../queries';
import { FundTransferUpdatableFields } from '@grain/core-types';

type UpdateFundTransferParams = {
  fundTransferId: string;
  fundTransferType: string;
  updatableFields: FundTransferUpdatableFields;
};
export function useUpdateFundTransfer() {
  const queryClient = useQueryClient();

  const { mutateAsync: updateFundTransfer, isPending } = useMutation<void, Error, UpdateFundTransferParams>({
    mutationFn: async (params): Promise<void> => {
      await FundTransfersService.getInstance().updateFundTransfer(params.fundTransferId, params.fundTransferType, params.updatableFields);
    },
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries({ queryKey: queries.fundTransfers.list._def })]);
    }
  });

  return { updateFundTransfer, isLoadingUpdateFundTransfer: isPending };
}
