import React, { ReactElement } from 'react';
import { paymentTypeDisplayName } from '@grain/web-utils';
import { Card } from '../layout/Card';
import CurrencyFlag from '../CurrencyFlag/CurrencyFlag';
import { Flag } from '../flag/Flag';
import { Separator } from '../separator/Separator';
import { PaymentType } from '@grain/core-types';
import './WalletCard.scss';
import { Tag } from '../Tag/Tag';
import { asAmount } from '@grain/common-utils';

export interface WalletCardProps {
  balance: number;
  countryCode?: string;
  currency: string;
  onClick?: () => void;
  values: readonly WalletCardValue[];
}

export type WalletCardValue = { title: string | ReactElement; value: string | number | ReactElement | null };

const FLAG_SIZE = 32;

export function WalletCard(props: WalletCardProps): ReactElement {
  const { currency, countryCode } = props;

  return (
    <Card className="wallet-card" onClick={props.onClick}>
      <div className="wallet-card-header">
        <div className="wallet-card-title">
          {countryCode ? <Flag countryCode={countryCode} size={FLAG_SIZE} /> : <CurrencyFlag currency={currency} size={FLAG_SIZE} />}
          <span>{`${currency} ${asAmount(props.balance, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</span>
        </div>
      </div>

      <Separator />

      <div className="wallet-card-details">
        {props.values.map((value, index) => (
          <div key={`${currency}-wallet-card-detail-${index}`} className="wallet-card-detail">
            <span className="wallet-card-detail-title">{value.title}</span>
            <span className="wallet-card-detail-value">{value.value}</span>
          </div>
        ))}
      </div>
    </Card>
  );
}

interface WalletCardPaymentTypesProps {
  paymentTypes: PaymentType[];
}

export function WalletCardPaymentTypes(props: WalletCardPaymentTypesProps): ReactElement {
  return (
    <div className="payment-types">
      {props.paymentTypes.map((paymentType) => (
        <Tag className="payment-type" key={paymentType} value={paymentTypeDisplayName(paymentType)} />
      ))}
    </div>
  );
}
