import React, { ChangeEvent, useState } from 'react';
import { cn } from '@grain/web-components';
import MultiStepsDialog from '@grain/web-components/dialogs/MultiStepsDialog';
import DialogBaseStep from '@grain/web-components/dialogs/DialogBaseStep';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { ImportCurve } from 'iconsax-react';
import BaseIcon from '@grain/web-components/icons/BaseIcon';
import CurrencyAmountInput from '@grain/web-components/CurrencyAmountInput/CurrencyAmountInput';
import DialogSuccessStep from '@grain/web-components/dialogs/DialogSuccessStep';
import './PennyTestDialog.scss';
import { usePaymentBeneficiaries } from '../../hooks';
import { PaymentBeneficiaryType } from '@grain/core-types';
import { KeyValuePair } from '@grain/web-components/key-value-pair/KeyValuePair';
import BaseInput from '@grain/web-components/forms/BaseInput';
import { asAmount } from '@grain/common-utils';

const INITIAL_STATE = {
  amount: undefined,
  note: ''
};
export default function PennyTestDialog({
  partnerId,
  paymentBeneficiaryType,
  allowNote,
  isSuccess,
  isExecuting,
  isFailure,
  open,
  error,
  onClose,
  onConfirm
}: PennyTestDialogProps) {
  const { paymentBeneficiaries, isLoadingPaymentBeneficiaries } = usePaymentBeneficiaries(partnerId, [paymentBeneficiaryType]);
  const uniqueCurrencies = [...new Set(paymentBeneficiaries?.map((beneficiary) => beneficiary.currency))];

  const [pennyTestData, setPennyData] = useState<{ amount?: number; note: string }>(INITIAL_STATE);
  const [pennyTestCurrency, setPennyTestCurrency] = useState(uniqueCurrencies[0]);
  const paymentBeneficiaryName = paymentBeneficiaries?.find((pb) => pb.currency === pennyTestCurrency)?.nickname;
  const isExecutionButtonDisabled = !pennyTestData.amount || isExecuting;

  const onPennyTestDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setPennyData((prevData) => ({
      ...prevData,
      [name]: name === 'amount' ? (value === '' ? undefined : +value) : value
    }));
  };

  const onPennyTestConfirm = async () => {
    await onConfirm(partnerId, pennyTestData.amount!, pennyTestCurrency, pennyTestData.note);
  };

  const clearDialog = () => {
    setPennyData(INITIAL_STATE);
    onClose();
  };

  let dialogContent;
  if (!isSuccess && !isFailure) {
    dialogContent = (
      <DialogBaseStep
        className="penny-test-base-dialog"
        formId="penny-test-dialog"
        button={
          <CTAButton
            leadingIcon={
              <BaseIcon appearance={isExecutionButtonDisabled ? 'grey' : 'light'}>
                <ImportCurve />
              </BaseIcon>
            }
            text="Execute"
            loading={isExecuting}
            disabled={isExecutionButtonDisabled}
            onClick={onPennyTestConfirm}
          />
        }
      >
        <div className="text-primary text-lg mb-9">Select the amount you want to transfer</div>
        <CurrencyAmountInput
          name="amount"
          currencyName="currency"
          labelText="Amount"
          value={pennyTestData.amount || ''}
          currency={pennyTestCurrency}
          currenciesOptions={uniqueCurrencies}
          disabled={isExecuting}
          onChange={onPennyTestDataChange}
          onCurrencyChange={(_, currency) => setPennyTestCurrency(currency)}
        />
        {allowNote && (
          <div className="mt-6">
            <BaseInput
              name="note"
              labelText="Note"
              value={pennyTestData.note}
              onChange={onPennyTestDataChange}
              innerLabel
              hideErrorText
              inputProps={{ maxLength: 50 }}
              endAdornment={<div className="text-light-grey w-16">{`${pennyTestData.note.length} / 50`}</div>}
            />
          </div>
        )}

        <KeyValuePair className="mt-10" title="Beneficiary Account" value={paymentBeneficiaryName || ''} />
      </DialogBaseStep>
    );
  } else if (isSuccess) {
    dialogContent = (
      <DialogSuccessStep
        title="Transaction in Process"
        summary={[
          { text: 'Beneficiary Account', value: paymentBeneficiaryName! },
          {
            text: 'Amount',
            value: `${asAmount(pennyTestData.amount!)} ${pennyTestCurrency} `
          }
        ]}
        imageUrl="/images/web-components/withdrawal_success.gif"
      />
    );
  }

  return (
    <MultiStepsDialog
      open={open}
      contentClassName={cn('penny-test-base-dialog-content', {
        error: !!error
      })}
      triggerAlt={!paymentBeneficiaries?.length ? 'Payment beneficiary is not configured' : ''}
      leftHeader="Penny test"
      clearDialog={clearDialog}
      loading={isLoadingPaymentBeneficiaries}
      error={error?.message}
      onCancel={clearDialog}
      onClose={clearDialog}
    >
      {dialogContent}
    </MultiStepsDialog>
  );
}

type PennyTestDialogProps = {
  open: boolean;
  partnerId: string;
  paymentBeneficiaryType: PaymentBeneficiaryType;
  allowNote?: boolean;
  isExecuting: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  error?: Error | null;
  onConfirm: (partnerId: string, amount: number, currency: string, note?: string) => Promise<void>;
  onClose: () => void;
};
