import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@grain/web-components/dialogs/Dialog';
import React, { useState } from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { Input } from '@grain/web-components/input';
import { useEditSymbolConfiguration } from './hooks/useEditSymbolConfiguration';
import { asMoney } from '@grain/common-utils';
import { AdminActiveBucket } from '@grain/admin-console-api-types';

const MIN_THRESHOLD = 5000;

interface IProps {
  onClose: () => void;
  editableBucket: AdminActiveBucket;
  onSuccess: () => void;
}

export default function EditSymbolConfiguration({ onClose, editableBucket, onSuccess }: IProps) {
  const { symbol, threshold: propsThreshold } = editableBucket;
  const [threshold, setThreshold] = useState<number>(propsThreshold || 0);
  const { isPending: loading, changePositionSymbolConfiguration, reset } = useEditSymbolConfiguration();

  const onSave = async () => {
    try {
      await changePositionSymbolConfiguration({ symbol, threshold });
      onSuccess();
      onClose();
      reset();
    } catch (e) {
      // No Op
    }
  };

  return (
    <Dialog
      open
      onOpenChange={() => {
        onClose();
      }}
    >
      <DialogContent className="dialog-details-content min-w-72 bg-white">
        <DialogHeader>
          <DialogTitle>Set Bucket Limit ({symbol})</DialogTitle>
        </DialogHeader>

        <div className="my-6">
          <div className="text-primary font-normal text-sm mb-3">Bucket Limit</div>
          <Input
            disabled={loading}
            name="threshold"
            value={asMoney(threshold, 'USD', 0)}
            endAdornment={
              <span className="pointer-events-none font-normal text-[12px] text-light-grey">{`Min: ${asMoney(MIN_THRESHOLD, 'USD', 0)}`}</span>
            }
            onChange={(e) => {
              const { value } = e.target;
              const numericValue = value.replace(/[^0-9]/g, '');
              setThreshold(Number(numericValue));
            }}
          />
        </div>
        <DialogFooter className="flex sm:justify-start">
          <CTAButton
            loading={loading}
            disabled={propsThreshold === threshold || threshold < MIN_THRESHOLD || loading}
            text="Save"
            onClick={onSave}
            size="small"
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
