import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import BucketsService from '../../../../services/BucketsService';
import { queries } from 'queries';

export const useEditSymbolConfiguration = () => {
  const queryClient = useQueryClient();
  const { isPending, mutateAsync, reset } = useMutation<void, Error, { symbol: string; threshold: number }>({
    mutationFn: (params) => {
      const { symbol, threshold } = params;
      return BucketsService.getInstance().editSymbolBucketThreshold(symbol, threshold);
    },
    onSuccess: async () => {
      notifySuccess('Symbol configuration updated successfully');
      await queryClient.invalidateQueries({ queryKey: queries.buckets.all.queryKey });
    }
  });

  return {
    changePositionSymbolConfiguration: mutateAsync,
    isPending,
    reset
  };
};
