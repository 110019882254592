import React, { ReactElement, useMemo } from 'react';
import { Edit } from 'iconsax-react';
import FormDialog from '@grain/web-components/dialogs/FormDialog';

// This returns only fields with values that are different from the initial values.
// It's returned in o(n^2) instead of o(n) because otherwise the types requirements make it unreadable.
function _getNewValues<T>(editedData: Partial<T>, initialData: Partial<T>): Partial<T> {
  return Object.fromEntries(
    Object.entries(editedData).filter(([key, value]) => Object.entries(initialData).some(([k, v]) => key === k && value !== v))
  ) as Partial<T>;
}

export default function EditConfigDialog<T>({
  children,
  onCloseAfterSuccess,
  onSubmit,
  editedData = {},
  initialData,
  triggerComponent,
  noInputsContainer
}: EditConfigDialogProps<T>) {
  const canSubmit = useMemo(() => !!Object.keys(_getNewValues(editedData, initialData)).length, [editedData, initialData]);

  const onSubmitClicked = async () => {
    await onSubmit(_getNewValues(editedData, initialData));
  };

  const onClose = (success: boolean) => {
    if (success) {
      onCloseAfterSuccess?.();
    }
  };

  return (
    <FormDialog
      className="partner-config-form"
      title="Edit Configuration"
      trigger={triggerComponent || <Edit variant="TwoTone" className="edit-button" />}
      canSubmit={canSubmit}
      onClose={onClose}
      onSubmit={onSubmitClicked}
      noInputsContainer={noInputsContainer}
    >
      {children}
    </FormDialog>
  );
}

type EditConfigDialogProps<T> = {
  children: React.ReactNode;
  onCloseAfterSuccess?: () => void;
  onSubmit: (newValues: Partial<T>) => void;
  editedData?: Partial<T>;
  initialData: Partial<T>;
  triggerComponent?: ReactElement;
  noInputsContainer?: boolean;
};
