import { Hedge } from './hedge';
import {
  CycleSettlementFundTransfer,
  CycleSettlementInboundFundTransfer,
  HedgingSettlementInboundFundTransfer,
  HedgingSettlementOutboundFundTransfer,
  HedgingSpotOutboundFundTransfer,
  OffsetSettlementFundTransfer,
  OffsetSettlementInboundFundTransfer
} from './fund-transfer';
import { OffsetDetails } from './offset';
import { Organization } from './organization';

export type SetSettlementAndOverdueAtParams = {
  fromCurrency?: string;
  toCurrency: string;
  endDate: Date;
};

export enum SettlementStatus {
  Active = 'Active',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Overdue = 'Overdue',
  InProcess = 'InProcess'
}

export interface BaseSettlement {
  id: string;
  fundsReceivedAt?: Date;
  completedAt?: Date;

  getStatus(): SettlementStatus;
  setSettlementAndOverdueAt(params: SetSettlementAndOverdueAtParams): void;
}

export interface OffsetSettlement extends BaseSettlement {
  currency: string;
  hedge: Hedge;
  offsetDetails?: OffsetDetails;
  offsetSettlementOutboundFundTransfer?: OffsetSettlementFundTransfer;
  offsetSettlementInboundFundTransfer?: OffsetSettlementInboundFundTransfer;
}

export interface CollectionSettlement extends BaseSettlement {
  hedge: Hedge;
  hedgingSettlementOutboundFundTransfer?: HedgingSettlementOutboundFundTransfer;
  hedgingSettlementInboundFundTransfer?: HedgingSettlementInboundFundTransfer;
}

export interface CycleSettlement extends BaseSettlement {
  currency: string;
  hedges: Hedge[];
  offsetDetails: OffsetDetails[];
  fromHedgeEndAt: Date;
  toHedgeEndAt: Date;
  cycleSettlementOutboundFundTransfer?: CycleSettlementFundTransfer;
  cycleSettlementInboundFundTransfer?: CycleSettlementInboundFundTransfer;
}

export interface BulkFullSettlement extends BaseSettlement {
  fromCurrency: string;
  toCurrency: string;
  organizationId: string;
  organization: Organization;
  hedgingSpotOutboundFundTransfer: HedgingSpotOutboundFundTransfer;
  hedgingSpotOutboundFundTransferId: string;
  hedgingSettlementInboundFundTransfer: HedgingSettlementInboundFundTransfer;
  hedgingSettlementInboundFundTransferId: string;
  hedgingSettlementOutboundFundTransfer: HedgingSettlementOutboundFundTransfer;
  hedgingSettlementOutboundFundTransferId: string;
  hedges: Hedge[];
}
