import React, { forwardRef } from 'react';
import cn from '../utils/cn';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  maxLength?: number;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  { value, onChange, className, maxLength = 50, placeholder = 'Add comment...', ...props },
  ref
) {
  return (
    <div className="relative mt-6 mb-6">
      <textarea
        value={value}
        onChange={onChange}
        className={cn(
          'flex w-full rounded-lg border border-primary bg-white p-4 text-primary h-[6.25rem]',
          'file:border-0 file:bg-transparent file:text-sm text-sm file:font-medium',
          'focus-visible:outline-none focus-visible:border-primary',
          'disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-button-grey disabled:text-disabled',
          'placeholder:text-light-grey placeholder:disabled:text-disabled resize-none',
          className
        )}
        ref={ref}
        maxLength={maxLength}
        placeholder={placeholder}
        {...props}
      />
      <span className="absolute bottom-1 right-2 text-xs text-gray-500 p-2">
        {value.length}/{maxLength}
      </span>
    </div>
  );
});

export { TextArea };
