import React from 'react';
import {
  ArrowCircleDown2,
  BookSquare,
  Chart2,
  Coin,
  Coin1,
  Dislike,
  Forward,
  MedalStar,
  Pointer,
  RepeatCircle,
  ShieldTick,
  UserEdit,
  Wallet
} from 'iconsax-react';
import BaseMenuItem from '@grain/web-components/layout/BaseMenuItem';
import ExpandableMenuItem from '@grain/web-components/layout/ExpandableMenuItem';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../store/userStore';
import { AdminConsoleRole } from '@grain/core-types';
import { useMatches } from 'react-router-dom';

export default function MenuItemsList() {
  const matches = useMatches();
  const currentUser = useSelector(userSelectors.getCurrentUser);

  const showUserManagement = currentUser && [AdminConsoleRole.SuperAdmin].includes(currentUser.role);

  return (
    <>
      <BaseMenuItem title="Partners" route="/partners" leftIcon={<MedalStar />} />
      <BaseMenuItem
        title="Currencies"
        route="/currencies"
        leftIcon={matches.some((m) => m.pathname === '/currencies') ? <Coin1 /> : <Coin />}
      />
      <BaseMenuItem title="Wallets" route="/wallets" leftIcon={<Wallet />} />
      <BaseMenuItem title="Hedges" route="/hedges" leftIcon={<ShieldTick />} />
      <BaseMenuItem title="Spots" route="/spots" leftIcon={<Pointer />} />
      <BaseMenuItem title="Transfers" route="/fund-transfers" leftIcon={<ArrowCircleDown2 />} />
      {showUserManagement && <BaseMenuItem title="User Management" route="/users" leftIcon={<UserEdit />} />}
      <ExpandableMenuItem
        title="Portfolio"
        restrictToRoles={[AdminConsoleRole.SuperAdmin, AdminConsoleRole.FinOps]}
        userRole={currentUser?.role}
      >
        <BaseMenuItem title="Forwards" route="/forwards" leftIcon={<Forward />} />
        <BaseMenuItem title="Exposure" route="/exposure" leftIcon={<Chart2 />} />
      </ExpandableMenuItem>
      <ExpandableMenuItem title="Monitoring">
        <BaseMenuItem title="Audit Logs" route="/audit-logs" leftIcon={<BookSquare />} />
        <BaseMenuItem title="Rejections" route="/action-rejections" leftIcon={<Dislike />} />
      </ExpandableMenuItem>
      <ExpandableMenuItem
        userRole={currentUser?.role}
        title="Tools"
        restrictToRoles={[AdminConsoleRole.SuperAdmin, AdminConsoleRole.Developer]}
      >
        <BaseMenuItem title="Scheduler Jobs" route="/jobs" leftIcon={<RepeatCircle />} />
      </ExpandableMenuItem>
    </>
  );
}
