import { Markups, OneYearTenor, Tenor, TwoYearsTenor } from './rates-snapshot';
import { HedgeSettlementType } from './hedge';

export interface Partner {
  id: string;
  name: string;
  demo: boolean;
  internal: boolean;
}

export interface PartnerConfig {
  id: string;
  partnerId: string;
  collateralPercentage: number;
  transactionExtraFieldsSchema: ExtraFieldsSchemaType;
  currencyTenorMarkup?: Markups<OneYearTenor> | Markups<TwoYearsTenor>;
  functionalCurrency?: string;
  enableReconciliation: boolean;
  cycleConfig: CycleConfig;
  settlementTypesInCycle: HedgeSettlementType[];
  maxHedgeDurationInDays: MaxHedgeDurationInDays;
  hedgingStrategy: HedgingStrategy;
}

export type ExtraFieldsSchemaType = {
  [key: string]: string;
};

export interface OrganizationStatistics {
  organizationId: string;
  key: string;
  value: number;
  fromCurrency?: string;
  toCurrency?: string;
}

export interface IPartnerOfflineMarkups<T extends Tenor> {
  id: string;
  markup: Markups<T>;
  revision: number;
  validUntilTs: number;
  partnerId: string;
}

export enum MaxHedgeDurationInDays {
  OneYear = '350',
  TwoYears = '710'
}

export enum CycleConfig {
  None = 'none',
  Weekly = 'weekly',
  Monthly = 'monthly',
  TwiceAMonth = 'twice-a-month'
}

export enum CycleConfigDates {
  StartOfMonth = 1,
  SecondWeekStart = 8,
  MidOfMonth = 16,
  FourthWeekStart = 23
}

export interface PartnerCurrencyPair {
  id: string;
  fromCurrency: string;
  toCurrency: string;
  partnerId: string;
  settlementType: HedgeSettlementType;
}

export enum LiquidityProviderAccountOverride {
  None = 'none',
  WorldPay = 'world-pay'
}

export enum ForwardPurchaseStrategy {
  OneToOne = 'one-to-one',
  Bucket = 'bucket'
}

export enum HedgingStrategy {
  Standard = 'standard',
  Dual = 'dual'
}

export type WithManaulCancelationPercentage = { type: 'manual-cancellation-percentage'; percentage: number };
export function isWithManaulCancelationPercentage(obj?: PCancelConfig): obj is WithManaulCancelationPercentage {
  return obj?.type === 'manual-cancellation-percentage' && 'percentage' in obj;
}

export type WithTransactions = { type: 'with-transactions' };
export type WithOutTransactions = { type: 'without-transactions' };
export type PCancelConfig = WithManaulCancelationPercentage | WithOutTransactions | WithTransactions;
export type PCancelConfigType = PCancelConfig['type'];
