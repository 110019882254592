import CTAButton from '@grain/web-components/buttons/CTAButton';
import React, { useState } from 'react';
import { FundTransfer } from '@grain/core-types';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@grain/web-components/dialogs/Dialog';
import { TextArea } from '@grain/web-components/text-area/TextArea';
import { useUpdateFundTransfer } from './hooks/useUpdateFundTransfer';

export default function EditFundTransferNoteDialog(props: EditFundTransferNoteDialogParams) {
  const [error, setError] = useState<string>('');
  const [displayValue, setDisplayValue] = useState<string>(props.fundTransfer?.note || '');
  const { onClose, fundTransfer } = props;
  const onDialogClose = () => {
    setError('');
    onClose();
  };

  const { updateFundTransfer, isLoadingUpdateFundTransfer } = useUpdateFundTransfer();
  const onSaveFundTransferNote = async (fundTransferId: string, fundTransferType: string, note: string) => {
    await updateFundTransfer({ fundTransferId, fundTransferType, updatableFields: { note } });
  };

  const onSubmit = async () => {
    try {
      if (fundTransfer) {
        await onSaveFundTransferNote(fundTransfer.id, fundTransfer.type, displayValue);
      }
      onDialogClose();
    } catch (e) {
      setError(e.message);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDisplayValue(e.target.value);
  };

  const canSubmit = displayValue !== props.fundTransfer?.note;
  return (
    <Dialog open={!!fundTransfer} onOpenChange={onDialogClose}>
      <DialogContent className="dialog-details-content max-w-[410px]">
        <DialogHeader>
          <DialogTitle>Edit Note</DialogTitle>
        </DialogHeader>
        <TextArea value={displayValue} onChange={handleChange} />
        <div className={'flex flex-row items-center w-full'}>
          <CTAButton
            className="mr-auto"
            text="Save"
            disabled={!canSubmit}
            loading={isLoadingUpdateFundTransfer}
            onClick={onSubmit}
            size={'small'}
          />
          <div className="text-sm text-error flex-1 ml-4">{error}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

type EditFundTransferNoteDialogParams = {
  fundTransfer: FundTransfer;
  onClose: () => void;
};
