import { GrainCurrencyPairHedgeForwardOrderProvider, GrainCurrencyPairHedgeSpotRateProvider, LiquidityProvider } from '@grain/core-types';

export const LiquidityProviderGrainCurrencyPairSpotProviderMapper: Record<
  GrainCurrencyPairHedgeSpotRateProvider,
  LiquidityProvider | null
> = {
  [GrainCurrencyPairHedgeSpotRateProvider.Hapoalim]: LiquidityProvider.HAPI,
  [GrainCurrencyPairHedgeSpotRateProvider.JPMorgan]: LiquidityProvider.JP,
  [GrainCurrencyPairHedgeSpotRateProvider.Swiss]: LiquidityProvider.SWISS,
  [GrainCurrencyPairHedgeSpotRateProvider.OpenExchangeRates]: null,
  [GrainCurrencyPairHedgeSpotRateProvider.Bloomberg]: LiquidityProvider.BLOOMBERG
};
export const LiquidityProviderGrainCurrencyPairForwardOrderProviderMapper: Record<
  GrainCurrencyPairHedgeForwardOrderProvider,
  LiquidityProvider | null
> = {
  [GrainCurrencyPairHedgeForwardOrderProvider.Hapoalim]: LiquidityProvider.HAPI,
  [GrainCurrencyPairHedgeForwardOrderProvider.JP]: LiquidityProvider.JP,
  [GrainCurrencyPairHedgeForwardOrderProvider.Swiss]: LiquidityProvider.SWISS,
  [GrainCurrencyPairHedgeForwardOrderProvider.GrainManual]: null
};
export const LiquidityProviderToGrainCurrencyPairHedgeSpotRateProviderMapper = flipMapper(
  LiquidityProviderGrainCurrencyPairSpotProviderMapper
);

function flipMapper<T extends Record<string, V | null>, V extends string>(mapper: T): Record<Exclude<V, null>, keyof T> {
  const flippedMapper = {} as Record<Exclude<V, null>, keyof T>;

  for (const key in mapper) {
    if (Object.prototype.hasOwnProperty.call(mapper, key)) {
      const value = mapper[key];
      if (value != null) {
        flippedMapper[value] = key as keyof T;
      }
    }
  }

  return flippedMapper;
}
