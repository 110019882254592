import React, { ReactElement } from 'react';
import { flexRender, Row, Table } from '@tanstack/react-table';
import './TableBody.scss';
import { cn } from '../utils';

export interface TableBodyProps<TData> {
  onRowClick?: (row: Row<TData>) => void;
  loading?: boolean;
  table: Table<TData>;
}

export function TableBody<TData>(props: TableBodyProps<TData>): ReactElement {
  const { onRowClick, loading, table } = props;

  return (
    <tbody className={cn('table-body', { loading })}>
      {table.getRowModel().rows.map((row) => (
        <tr
          className={cn('table-row', {
            'table-row-clickable': onRowClick
          })}
          key={row.id}
          onClick={onRowClick && (() => onRowClick(row))}
          onMouseEnter={() => {
            table.setHoveredRows((_) => ({
              [row.id]: true
            }));
          }}
          onMouseLeave={() => {
            table.setHoveredRows((__) => ({
              [row.id]: false
            }));
          }}
        >
          {row.getVisibleCells().map((cell) => (
            <td className="table-cell" align={cell.column.columnDef.meta?.align} key={cell.id} data-testid={`${cell.column.id}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}
