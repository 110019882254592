import { format } from 'date-fns';

export function asPrettyAmount(value: number, fractionDigits = 1): string {
  return asAmount(value, {
    notation: 'compact',
    maximumFractionDigits: fractionDigits
  });
}

export function asAmount(value: number, options: Intl.NumberFormatOptions = {}): string {
  return Intl.NumberFormat('en-US', options).format(value);
}

export function asMoney(
  value: number,
  currency: string,
  fractionDigits = 2,
  currencyDisplay: 'code' | 'symbol' | 'name' = 'symbol'
): string {
  return Intl.NumberFormat('en-US', { style: 'currency', currency, maximumFractionDigits: fractionDigits, currencyDisplay }).format(value);
}

export function asPrettyAmountMoney(value: number, currency: string, fractionDigits = 1): string {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    notation: 'compact',
    maximumFractionDigits: fractionDigits
  }).format(value);
}

export const VALUE_MISSING = '-';

export function asDecimalString(value?: string | number, fractionDigits = 2): string {
  return value != null ? Number(value).toFixed(fractionDigits) : VALUE_MISSING;
}

export function asDateRange(dateFrom: Date, dateTo: Date): string {
  return `${format(dateFrom, 'MMMM d')} - ${format(dateTo, 'MMMM d')}`;
}

export function asDateString(value?: string | number | Date, includeTime = false, shortYear = false): string {
  return value != null
    ? new Date(value).toLocaleDateString(undefined, {
        year: shortYear ? '2-digit' : 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: includeTime ? '2-digit' : undefined,
        minute: includeTime ? '2-digit' : undefined,
        second: includeTime ? '2-digit' : undefined
      })
    : VALUE_MISSING;
}
