import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@grain/web-components/dialogs/Dialog';
import React from 'react';
import { Separator } from '@grain/web-components/separator/Separator';
import { AdminActiveBucket } from '@grain/admin-console-api-types';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { useBookActiveBucket } from './hooks/useBookActiveBucket';

interface IProps {
  onClose: () => void;
  bucketToBook: AdminActiveBucket;
  onSuccess: () => void;
}

export default function BookActiveBucketDialog({ onClose, bucketToBook, onSuccess }: IProps) {
  const { isLoading, bookActiveBucketAsync, reset } = useBookActiveBucket();

  const { symbol, id } = bucketToBook;

  const onBook = async () => {
    try {
      await bookActiveBucketAsync(id);
      onSuccess();
      onClose();
      reset();
    } catch (e) {
      // No Op
    }
  };

  return (
    <Dialog
      open
      onOpenChange={() => {
        onClose();
      }}
    >
      <DialogContent className="dialog-details-content min-w-72 bg-white">
        <DialogHeader>
          <DialogTitle className={'border-0'}>Book Bucket</DialogTitle>
        </DialogHeader>
        <Separator />

        <DialogClose
          onClick={() => {
            onClose();
          }}
        />

        <div className="my-10">
          <div className="text-primary font-bold text-2xl tracking-normal text-center mb-4">Are you sure?</div>
          <div className="text-secondary text-base font-normal leading-[22.4px] tracking-normal text-center">
            This action will book the corresponding forward and close the bucket ({symbol}). Are you sure you want to continue?
          </div>
        </div>
        <DialogFooter className="flex flex-row justify-center sm:flex-row sm:justify-center gap-4 mb-1.5">
          <DialogClose asChild>
            <CTAButton disabled={isLoading} text="No" appearance="blue" onClick={onClose} />
          </DialogClose>
          <CTAButton disabled={isLoading} text="Yes" appearance="white" onClick={onBook} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
