import { BaseService } from '@grain/web-utils';
import { AdminActiveBucket } from '@grain/admin-console-api-types';
import { convertForexSymbolStringToCurrencyPair } from '@grain/rate-utils';

export default class BucketsService extends BaseService {
  private static instance: BucketsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new BucketsService(baseUrl, handleError);
  }

  public static getInstance(): BucketsService {
    return this.instance;
  }

  async getBuckets(): Promise<AdminActiveBucket[]> {
    return this.authenticatedCall<AdminActiveBucket[]>({
      url: 'buckets/',
      method: 'GET'
    });
  }

  async editSymbolBucketThreshold(symbol: string, threshold: number): Promise<void> {
    const { toCurrency, fromCurrency } = convertForexSymbolStringToCurrencyPair(symbol);
    const naiveSymbol = `${toCurrency}${fromCurrency}`;
    await this.authenticatedCall({
      url: `buckets/${naiveSymbol}`,
      method: 'PUT',
      data: {
        threshold
      }
    });
  }

  async bookActiveBucket(id: string): Promise<void> {
    await this.authenticatedCall({
      url: `buckets/${id}/book`,
      method: 'POST'
    });
  }
}
