import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { Input, type InputProps } from './Input';
import { cn } from '../utils';

import { asAmount } from '@grain/common-utils';

export const AmountInput = forwardRef<HTMLInputElement, AmountInputProps>(function AmountInput(props, ref) {
  const { className, value, onChange, fractionDigits = 2, allowNegative = false, placeholder, ...restProps } = props;

  const defaultPlaceholder = fractionDigits === 0 ? '0' : `0.${'0'.repeat(fractionDigits)}`;
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (value == null) {
      setDisplayValue('');
      return;
    }
    if (value.toString() !== displayValue) {
      setDisplayValue(asAmount(value));
    } else {
      setDisplayValue(`${value}`);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    if (!input || (allowNegative && input === '-')) {
      setDisplayValue(input);
      if (!input) onChange?.(undefined);
      return;
    }

    const hasNegative = input.startsWith('-');
    const digitsAndDecimalRegex = /[^\d.]/g;
    const unformattedText =
      hasNegative && allowNegative ? '-' + input.slice(1).replace(digitsAndDecimalRegex, '') : input.replace(digitsAndDecimalRegex, '');

    const [, decimalPart] = unformattedText.split('.');
    if (decimalPart?.length > fractionDigits) {
      return;
    }

    const validNumberRegex = allowNegative ? /^-?\d*\.?\d*$/ : /^\d*\.?\d*$/;
    if (!validNumberRegex.test(unformattedText)) {
      return;
    }

    setDisplayValue(unformattedText);

    const completeNumberRegex = allowNegative ? /^-?\d+\.?\d*$/ : /^\d+\.?\d*$/;
    if (completeNumberRegex.test(unformattedText)) {
      onChange?.(parseFloat(unformattedText));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (value != null) {
      setDisplayValue(asAmount(value));
    }

    props.onBlur?.(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (value != null) {
      setDisplayValue(`${value}`);
    }
    props.onFocus?.(e);
  };

  return (
    <div className="relative">
      <Input
        {...restProps}
        ref={ref}
        type="text"
        inputMode="decimal"
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder ?? defaultPlaceholder}
        className={cn(className)}
      />
    </div>
  );
});

export interface AmountInputProps extends Omit<InputProps, 'type' | 'value' | 'onChange'> {
  value?: number | undefined;
  onChange?: (value: number | undefined) => void;
  fractionDigits?: number;
  allowNegative?: boolean;
}
