import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../queries';
import { OrganizationType } from '@grain/core-types';

export const useWallets = (partnerId: string, organizationType: OrganizationType) => {
  const { data, isLoading, isError } = useQuery(queries.partners.wallets.list(partnerId, organizationType));

  return {
    wallets: data,
    isLoadingWallets: isLoading,
    isErrorLoadingWallets: isError
  };
};
