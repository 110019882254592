import { Company } from './company';
import { Transaction } from './transaction';
import { HedgeProposal } from './hedge-proposal';
import { Payment } from './payment';
import { InternalOffsetInboundFundTransfer, InternalOffsetOutboundFundTransfer } from './fund-transfer';
import {
  BulkFullSettlement as IBulkFullSettlement,
  CollectionSettlement as ICollectionSettlement,
  CycleSettlement as ICycleSettlement,
  OffsetSettlement as IOffsetSettlement
} from './settlement';
import { OffsetDetails as IOffsetDetails } from './offset';
import { FlatJson } from './json';

export type FullSettlementType = HedgeSettlementType.BulkFull | HedgeSettlementType.Collection;

export enum HedgeSettlementType {
  Collection = 'collection',
  Offset = 'offset',
  Manual = 'manual',
  Cycle = 'cycle',
  BulkFull = 'bulk_full'
}

export enum HedgeStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  InProcess = 'InProcess',
  Overdue = 'Overdue'
}

export interface Hedge {
  id: string;
  toCurrency: string;
  fromCurrency: string;
  toCurrencyAmount: number;
  endAt: Date;
  completedAt?: Date;
  quote: number;
  acceptedAt?: Date;
  transactionId: string;
  createdAt: Date;
  cancelledAt?: Date;
  cancelReason?: string;
  companyId: string;
  company: Company;
  payments: Payment[];
  proposal: HedgeProposal;
  status: HedgeStatus;
  transaction: Transaction;
  overdueAt: Date;
  settlementAt: Date;
  settlementType: HedgeSettlementType;
  internalOffsetOutboundFundTransfer?: InternalOffsetOutboundFundTransfer;
  internalOffsetInboundFundTransfer?: InternalOffsetInboundFundTransfer;
  offsetSettlement?: IOffsetSettlement;
  collectionSettlement?: ICollectionSettlement;
  cycleSettlement?: ICycleSettlement;
  offsetDetails?: IOffsetDetails;
  bulkFullSettlement?: IBulkFullSettlement;
}

export interface HedgeProposalResponsePayload {
  id?: string;
  fromCurrency?: string;
  toCurrency?: string;
  toCurrencyAmount?: number;
  fromCurrencyAmount?: number;
  quote: number;
  validUntilTs?: number;
}

export enum ManualHedgingStatusType {
  SKIP = 'skip',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export type HedgeActionResult = {
  hedgeId: string;
  transactionId: string;
};

export interface ManualHedgeActionResponse {
  hedgeResult?: HedgeActionResult;
  message?: string;
  extraParams?: ExtraErrorParams;
}

export type ManualHedgingSummary = {
  totalSkip: number;
  totalComplete: number;
  totalError: number;
};

export interface ManualHedgingMappingResponse {
  index: number;
  status: ManualHedgingStatusType;
  response: ManualHedgeActionResponse;
}

export type ExtraErrorParams = {
  [key: string]: number | string | string[] | FlatJson;
};
