'use client';

import * as React from 'react';
import { X } from 'lucide-react';

import { Badge } from '@mui/material';
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from '../command';
import cn from '../utils/cn';
import BaseField from '../forms/BaseField';

export type Option = Record<'value' | 'text', string>;

interface MultiSelectProps {
  name: string;
  options: Option[];
  placeholder?: string;
  labelText?: string;
  errorMessage?: string;
  required?: boolean;
  shadow?: boolean;
  hideErrorText?: boolean;
  sideLabel?: boolean;
  containerClassName?: string;
  selected: Option[];
  onSelectedChange: (selected: Option[]) => void;
}

export default function MultiSelect(props: MultiSelectProps) {
  const {
    options,
    placeholder,
    name,
    labelText,
    errorMessage,
    required,
    shadow,
    hideErrorText,
    sideLabel,
    containerClassName,
    selected,
    onSelectedChange
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const handleUnselect = React.useCallback(
    (option: Option) => {
      onSelectedChange(selected.filter((s) => s.value !== option.value));
    },
    [selected, onSelectedChange]
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            onSelectedChange([...selected.slice(0, -1)]);
          }
        }
        // This is not a default behaviour of the <input /> field
        if (e.key === 'Escape') {
          input.blur();
        }
      }
    },
    [selected, onSelectedChange]
  );

  const selectables = options.filter((option) => !selected.map((s) => s.value).includes(option.value));

  return (
    <div className={cn('w-full', containerClassName)}>
      <BaseField
        name={name}
        labelText={labelText}
        errorMessage={errorMessage}
        required={required}
        shadow={shadow}
        hideErrorText={hideErrorText}
        sideLabel={sideLabel}
      >
        <Command onKeyDown={handleKeyDown} className="overflow-visible bg-transparent">
          <div className="group rounded-[10px] border border-[#ECEEF5] px-3 py-2 text-sm ring-offset-background bg-white">
            <div className="flex flex-wrap gap-2">
              {selected.map((option) => {
                return (
                  <Badge key={option.value} className="bg-primary h-5 rounded-[5px] p-[5px] flex items-center justify-center">
                    {option.text}
                    <button
                      className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleUnselect(option);
                        }
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={() => handleUnselect(option)}
                    >
                      <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                    </button>
                  </Badge>
                );
              })}
              {/* Avoid having the "Search" Icon */}
              <CommandInput
                minimal
                ref={inputRef}
                value={inputValue}
                onValueChange={setInputValue}
                onBlur={() => setOpen(false)}
                onFocus={() => setOpen(true)}
                placeholder={selected.length > 0 ? '' : placeholder || 'Select options...'}
                className="ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
              />
            </div>
          </div>
          <div className="relative">
            <CommandList className="py-0">
              {open && selectables.length > 0 ? (
                <div className="absolute top-0 z-10 w-full rounded-md border bg-white text-popover-foreground shadow-md outline-none animate-in">
                  <CommandGroup className="h-full overflow-auto">
                    {selectables.map((option) => {
                      return (
                        <CommandItem
                          key={option.value}
                          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onSelect={() => {
                            setInputValue('');
                            onSelectedChange([...selected, option]);
                          }}
                          className={'cursor-pointer'}
                        >
                          {option.text}
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </div>
              ) : null}
            </CommandList>
          </div>
        </Command>
      </BaseField>
    </div>
  );
}
