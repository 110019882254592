import { useQuery } from '@tanstack/react-query';
import { queries } from 'queries';

export function useActiveBuckets() {
  const { data, isError, isLoading } = useQuery({
    ...queries.buckets.all,
    refetchInterval: 30_000,
    retry: false
  });

  return {
    listActiveBucketsLoading: isLoading,
    listActiveBucketsErrored: isError,
    activeBuckets: data
  };
}
