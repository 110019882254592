import {
  CollectionFundTransfer,
  CycleSettlement,
  CycleSettlementInboundFundTransfer,
  CycleSettlementOutboundFundTransfer,
  FundTransferType,
  Hedge,
  HedgingSettlementInboundFundTransfer,
  HedgingSettlementOutboundFundTransfer,
  OffsetSettlementInboundFundTransfer,
  OffsetSettlementOutboundFundTransfer,
  SpotInboundFundTransfer,
  SpotOutboundFundTransfer,
  WithdrawalFundTransfer
} from '@grain/core-types';

export type VisibleFundTransfer =
  | CollectionFundTransfer
  | WithdrawalFundTransfer
  | HedgingSettlementInboundFundTransfer
  | HedgingSettlementOutboundFundTransfer
  | OffsetSettlementInboundFundTransfer
  | OffsetSettlementOutboundFundTransfer
  | SpotInboundFundTransfer
  | SpotOutboundFundTransfer
  | CycleSettlementInboundFundTransfer
  | CycleSettlementOutboundFundTransfer;

// Base properties that we want to keep from BaseFundTransfer
export type EnrichmentBaseFundTransferProps = Pick<
  VisibleFundTransfer,
  | 'id'
  | 'amount'
  | 'currency'
  | 'managedBy'
  | 'status'
  | 'fromCounterparty'
  | 'toCounterparty'
  | 'completedAt'
  | 'createdAt'
  | 'externalId'
  | 'valueDate'
  | 'note'
  | 'type'
>;

export type OffsetSettlementEnrichmentProps = {
  hedgeToCurrency: string;
  hedgeToCurrencyAmount: number;
  hedgeId: Hedge['id'];
};

export type FundTransferEnrichmentProps =
  | CollectionFundTransferEnrichmentProps
  | WithdrawalFundTransferEnrichmentProps
  | HedgingSettlementEnrichmentProps
  | OffsetSettlementEnrichmentProps
  | SpotInboundFundTransferEnrichmentProps
  | SpotOutboundFundTransferEnrichmentProps
  | CycleSettlementFundTransferEnrichmentProps;

export type SpotOutboundFundTransferEnrichmentProps = {
  fromAmount: number;
  fromCurrency: string;
  toAmount: number;
  toCurrency: string;
};

export type SpotInboundFundTransferEnrichmentProps = {
  fromAmount: number;
  fromCurrency: string;
  toAmount: number;
  toCurrency: string;
};

export type CollectionFundTransferEnrichmentProps = {
  sender: CollectionFundTransfer['sender'];
  fromCounterparty?: CollectionFundTransfer['fromCounterparty'];
  reconciledAt?: CollectionFundTransfer['reconciledAt'];
  reference?: CollectionFundTransfer['reference'];
};

export type WithdrawalFundTransferEnrichmentProps = {
  paymentBeneficiaryRoutingInfo: WithdrawalFundTransfer['paymentBeneficiaryRoutingInfo'];
};

export type HedgingSettlementEnrichmentProps = {
  fromAmount: number;
  fromCurrency: string;
  toAmount: number;
  toCurrency: string;
  hedgeId?: Hedge['id'];
};

export type CycleSettlementFundTransferEnrichmentProps = {
  cycleSettlement: CycleSettlement;
};

export type EnrichedCollectionFundTransfer = EnrichmentBaseFundTransferProps &
  CollectionFundTransferEnrichmentProps & { type: FundTransferType.Collection };

export type EnrichedWithdrawalFundTransfer = EnrichmentBaseFundTransferProps &
  WithdrawalFundTransferEnrichmentProps & { type: FundTransferType.Withdraw };

export type EnrichedHedgingSettlementInboundFundTransfer = EnrichmentBaseFundTransferProps &
  HedgingSettlementEnrichmentProps & { type: FundTransferType.HedgingSettlementInbound };

export type EnrichedHedgingSettlementOutboundFundTransfer = EnrichmentBaseFundTransferProps &
  HedgingSettlementEnrichmentProps & { type: FundTransferType.HedgingSettlementOutbound };

export type EnrichedOffsetSettlementInboundFundTransfer = EnrichmentBaseFundTransferProps &
  OffsetSettlementEnrichmentProps & { type: FundTransferType.OffsetSettlementInbound };

export type EnrichedOffsetSettlementOutboundFundTransfer = EnrichmentBaseFundTransferProps &
  OffsetSettlementEnrichmentProps & { type: FundTransferType.OffsetSettlementOutbound };

export type EnrichedSpotInboundFundTransfer = EnrichmentBaseFundTransferProps &
  SpotInboundFundTransferEnrichmentProps & { type: FundTransferType.SpotInbound };

export type EnrichedSpotOutboundFundTransfer = EnrichmentBaseFundTransferProps &
  SpotOutboundFundTransferEnrichmentProps & { type: FundTransferType.SpotOutbound };

export type EnrichedCycleSettlementInboundFundTransfer = EnrichmentBaseFundTransferProps &
  CycleSettlementFundTransferEnrichmentProps & { type: FundTransferType.CycleSettlementInbound };

export type EnrichedCycleSettlementOutboundFundTransfer = EnrichmentBaseFundTransferProps &
  CycleSettlementFundTransferEnrichmentProps & { type: FundTransferType.CycleSettlementOutbound };

export type EnrichedFundTransfer =
  | EnrichedCollectionFundTransfer
  | EnrichedWithdrawalFundTransfer
  | EnrichedHedgingSettlementInboundFundTransfer
  | EnrichedHedgingSettlementOutboundFundTransfer
  | EnrichedOffsetSettlementInboundFundTransfer
  | EnrichedOffsetSettlementOutboundFundTransfer
  | EnrichedSpotInboundFundTransfer
  | EnrichedSpotOutboundFundTransfer
  | EnrichedCycleSettlementInboundFundTransfer
  | EnrichedCycleSettlementOutboundFundTransfer;

export type ReportFundTransfer = {
  id: string;
  date: string;
  type: FundTransferSingularDisplayName;
  amount: EnrichedFundTransfer['amount'];
  note: EnrichedFundTransfer['note'];
  currency: EnrichedFundTransfer['currency'];
  additionalInfo?: string; // TODO: fix type
};

export enum FundTransferSingularDisplayName {
  CurrencyExchange = 'Currency Exchange',
  Deposit = 'Deposit',
  CycleOffsetsSettlement = 'Cycle Offset Settlement',
  HedgeSettlement = 'Hedge Settlement',
  HedgeOffsetSettlement = 'Hedge Offset Settlement',
  Withdrawal = 'Withdrawal',
  Unknown = 'Unknown'
}

export const BANK_ACCOUNT_NOT_FOUND = 'Bank account not found';
export type BankAccountNotFound = typeof BANK_ACCOUNT_NOT_FOUND;
export type GetFundTransfersResponseSuccess = { totalResults: number; fundTransfers: EnrichedFundTransfer[] };
export type GetFundTransfersResponseError = { error: BankAccountNotFound };
export type GetFundTransfersResponse = GetFundTransfersResponseSuccess | GetFundTransfersResponseError;
