import './FundTransfersTable.scss';
import { AdminConsoleRole, AdminConsoleUser, FundTransfer, FundTransferType } from '@grain/core-types';
import { createColumnHelper, Table, useControlledPagination, useTable } from '@grain/web-components/table-v2';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import { capitalizeFirstLetter } from '@grain/formatters';
import { SeparatorRow } from '@grain/web-components/separator-row/SeparatorRow';
import { IconButton } from '@grain/web-components/buttons/IconButton';
import { DocumentText1 } from 'iconsax-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../store/userStore';
import EditFundTransferNoteDialog from './EditFundTransferNoteDialog';
import { asAmount, asDateString } from '@grain/common-utils';

type FundTransfersTableProps = {
  fundTransfers: FundTransfer[];
  totalFundTransfers: number;
  page: number;
  pageSize: number;
  loading?: boolean;
  onPageChange: (pageIndex: number) => void;
};

export default function FundTransfersTable(props: FundTransfersTableProps) {
  const [selectedFundTransfer, setSelectedFundTransfer] = useState<FundTransfer | null>(null);
  const { fundTransfers, loading, page, pageSize, totalFundTransfers, onPageChange } = props;

  const currentUser = useSelector(userSelectors.getCurrentUser) as AdminConsoleUser;

  const { paginationProps } = useControlledPagination({
    serverSide: true,
    initialPageSize: pageSize,
    totalItems: totalFundTransfers,
    pageIndex: Number(page) || 0,
    onPageIndexChange: onPageChange
  });

  const closeDialog = () => {
    setSelectedFundTransfer(null);
  };

  const { table } = useTable(
    {
      columns: getColumns({
        currentUser,
        onEditFundTransferNoteClick: (fundTransfer) => {
          setSelectedFundTransfer(fundTransfer);
        }
      }),
      data: fundTransfers
    },
    paginationProps
  );

  return (
    <>
      <Table className="fund-transfers-table-container" loading={loading} table={table} />;
      {selectedFundTransfer != null && <EditFundTransferNoteDialog fundTransfer={selectedFundTransfer} onClose={closeDialog} />}
    </>
  );
}

const columnHelper = createColumnHelper<FundTransfer>();

const getColumns = (params: FundTransfersTableGetColumnsParams) => {
  return [
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: (fundTransferCell) => asDateString(fundTransferCell.getValue()),
      enableSorting: false
    }),
    columnHelper.accessor('externalId', {
      header: 'External ID',
      enableSorting: false
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      enableSorting: false
    }),
    columnHelper.accessor('partnerName', {
      header: 'Partner Name',
      cell: (info) => (
        <div className="truncate" title={info.getValue()}>
          {info.getValue()}
        </div>
      ),
      enableSorting: false
    }),
    columnHelper.accessor('note', {
      header: 'Note',
      cell: (info) => (
        <div className="truncate" title={info.getValue()}>
          {info.getValue()}
        </div>
      ),
      enableSorting: false
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      cell: (fundTransferCell) => `${fundTransferCell.row.original.currency} ${asAmount(fundTransferCell.getValue())}`,
      enableSorting: false
    }),
    columnHelper.display({
      header: 'Status',
      cell: (fundTransferCell) => {
        const fundTransfer = fundTransferCell.row.original;
        return <StatusBadge className="fund-transfer-status-badge-container" status={capitalizeFirstLetter(fundTransfer.status)} />;
      },
      enableSorting: false
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: ({ row }) => {
        const fundTransfer = row.original;
        return (
          <div className={'flex flex-row justify-end'}>
            <div
              style={{
                height: '24px',
                display: 'flex'
              }}
            >
              {[AdminConsoleRole.SuperAdmin, AdminConsoleRole.FinOps].includes(params.currentUser.role) && row.getIsHovered() && (
                <SeparatorRow>
                  <IconButton icon={DocumentText1} onClick={() => params.onEditFundTransferNoteClick(fundTransfer)} />
                </SeparatorRow>
              )}
            </div>
          </div>
        );
      }
    })
  ];
};

function _isCollectionFundTransfer(fundTransfer: FundTransfer) {
  return fundTransfer.type === FundTransferType.Collection;
}

function _isWithdrawalFundTransfer(fundTransfer: FundTransfer) {
  return fundTransfer.type === FundTransferType.Withdraw;
}

function _isTreasuryInboundFundTransfer(fundTransfer: FundTransfer) {
  return fundTransfer.type === FundTransferType.TreasuryInbound;
}

interface FundTransfersTableGetColumnsParams {
  currentUser: AdminConsoleUser;
  onEditFundTransferNoteClick: (fundTransfer: FundTransfer) => void;
}
