import { addDays, isSameDay, isWeekend } from 'date-fns';
import Holidays from 'date-holidays';
import { ManualHoliday, manualHolidayMap } from './manual-defined-holidays';
import { CurrencyHolidaySource, currencyToCountryMapping } from '@grain/rate-utils';

export const countryToHolidayMap = getCountryToHolidayMap();

/**
 * Use given currencies to check if settlement day is holiday or weekend (defined as Saturday and Sunday).
 * Not all currencies holiday are defined (currencies.ts, currencyToCountryMapping).
 * In case a currency is not defined, only the other currency will be checked.
 * reverse determines if we are looking for the next business day going backwards in time.
 */
export function nextBusinessDay({
  fromCurrency,
  toCurrency,
  endDate,
  reverse = false
}: {
  fromCurrency?: string;
  toCurrency: string;
  endDate: Date;
  reverse?: boolean;
}) {
  const fromCurrencyMapping = fromCurrency && currencyToCountryMapping[fromCurrency];
  const toCurrencyMapping = currencyToCountryMapping[toCurrency];
  const fromCountryHolidays = fromCurrencyMapping ? countryToHolidayMap[fromCurrencyMapping.countryCode] : undefined;
  const toCountryHolidays = toCurrencyMapping ? countryToHolidayMap[toCurrencyMapping.countryCode] : undefined;

  let nextBusinessDay = endDate;
  const step = reverse ? -1 : 1;
  while (
    isWeekend(nextBusinessDay) ||
    // @ts-expect-error - moving to strict ts
    checkIsHoliday(fromCountryHolidays, nextBusinessDay) ||
    // @ts-expect-error - moving to strict ts
    checkIsHoliday(toCountryHolidays, nextBusinessDay)
  ) {
    nextBusinessDay = addDays(nextBusinessDay, step);
  }
  return nextBusinessDay;
}

/*
  gives the date of a business day which is daysAmount distance from startingFromDate
 */
export function dateByBusinessDaysDistance({
  fromCurrency,
  toCurrency,
  startingFromDate,
  daysAmount
}: {
  fromCurrency?: string;
  toCurrency: string;
  startingFromDate: Date;
  daysAmount: number;
}): Date {
  let result = startingFromDate;
  const step = daysAmount > 0 ? 1 : -1;
  for (let i = 0; i < Math.abs(daysAmount); i++) {
    result = addDays(result, step);
    result = nextBusinessDay({
      fromCurrency,
      toCurrency,
      endDate: result,
      reverse: step < 0
    });
  }

  return result;
}

export function getCountryToHolidayMap() {
  const holidaysByCountry: Record<string, Holidays | ManualHoliday[]> = {};
  Object.values(currencyToCountryMapping).forEach(({ countryCode, type }) => {
    if (type === CurrencyHolidaySource.HOLIDAYS) {
      holidaysByCountry[countryCode] = new Holidays(countryCode, {
        types: ['public']
      });
    } else {
      // @ts-expect-error - moving to strict ts
      holidaysByCountry[countryCode] = manualHolidayMap.get(countryCode);
    }
  });
  return holidaysByCountry;
}

export function checkIsHoliday(holiday: Holidays | ManualHoliday[], date: Date): boolean {
  let isHoliday = false;
  let holidayList;
  if (holiday) {
    if (holiday instanceof Holidays) {
      holidayList = holiday.getHolidays(date.getFullYear());
    } else {
      holidayList = holiday;
    }
    isHoliday = holidayList.some((holiday) => isSameDay(new Date(holiday.date), date));
  }
  return isHoliday;
}

// Because of business days considerations for various currency pairs we can't know the exact distance and take a buffer
export const PAYMENT_BUSINESS_DAYS_MAX_BUFFER_IN_DAYS = 14;

export function todayIsBusinessDay(params: { fromCurrency?: string; toCurrency: string }): boolean {
  const { fromCurrency, toCurrency } = params;
  const today = new Date();
  const nextBusinessDayFromToday = nextBusinessDay({
    fromCurrency,
    toCurrency,
    endDate: today
  });
  return isSameDay(nextBusinessDayFromToday, today);
}
