import React, { ReactElement, useEffect, useState } from 'react';
import { GrainConfig } from '@grain/admin-console-api-types';
import EditConfigDialog from '../config/EditConfigDialog';
import GrainSettingsService from '../../services/GrainSettingsService';
import { AmountInput } from '@grain/web-components/input';

export default function EditGrainConfigDialog({ initialData, onCloseAfterSuccess, triggerComponent }: EditGrainConfigDialogProps) {
  const [editedData, setEditedData] = useState<Partial<GrainConfig>>();

  useEffect(() => {
    setEditedData({ maxOpenPositionsTotal: initialData.maxOpenPositionsTotal });
  }, [initialData.maxOpenPositionsTotal]);

  const onChange = (name: string, value?: number) => {
    setEditedData({ ...editedData, [name]: value });
  };

  const onSubmit = async (newValues: Partial<GrainConfig>) => {
    await GrainSettingsService.getInstance().updateGrainConfig(newValues);
  };

  return (
    <EditConfigDialog
      onCloseAfterSuccess={onCloseAfterSuccess}
      onSubmit={onSubmit}
      editedData={editedData}
      initialData={initialData}
      triggerComponent={triggerComponent}
      noInputsContainer
    >
      <AmountInput
        label="Max Open Positions Total"
        value={editedData?.maxOpenPositionsTotal}
        onChange={(value) => onChange('maxOpenPositionsTotal', value)}
      />
    </EditConfigDialog>
  );
}

type EditGrainConfigDialogProps = {
  initialData: Partial<GrainConfig>;
  onCloseAfterSuccess: () => void;
  triggerComponent?: ReactElement;
};
