import * as React from 'react';
import { forwardRef, type InputHTMLAttributes, ReactNode } from 'react';
import { cn } from '../utils';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  endAdornment?: ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input({ className, type, label, endAdornment, ...props }, ref) {
  return (
    <div className="relative">
      <input
        type={type}
        className={cn(
          'flex w-full rounded-lg border border-primary bg-white px-4 text-primary',
          'file:border-0 file:bg-transparent file:text-sm file:font-medium',
          'focus-visible:outline-none focus-visible:border-primary',
          'disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-button-grey disabled:text-disabled',
          'placeholder:text-light-grey placeholder:disabled:text-disabled',
          label ? 'h-[76px] pb-4 pt-10' : 'h-[38px]',
          className
        )}
        ref={ref}
        {...props}
      />

      {label && (
        <label
          className={cn(
            'absolute left-4 top-4 text-sm leading-none pointer-events-none',
            props.disabled ? 'text-disabled' : 'text-secondary'
          )}
        >
          {label}
        </label>
      )}
      {endAdornment && <div className="absolute right-4 top-1/2 transform -translate-y-1/2">{endAdornment}</div>}
    </div>
  );
});

export { Input };
