import * as React from 'react';
import { type DialogProps } from '@radix-ui/react-dialog';
import { Command as CommandPrimitive } from 'cmdk';
import { Search } from 'lucide-react';

import { Dialog, DialogContent } from '../dialogs/Dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef, HTMLAttributes } from 'react';
import { cn } from '../utils';

const Command = forwardRef<ElementRef<typeof CommandPrimitive>, ComponentPropsWithoutRef<typeof CommandPrimitive>>(function Command(
  { className, ...props },
  ref
) {
  return (
    <CommandPrimitive
      ref={ref}
      className={cn('flex h-full w-full flex-col overflow-hidden rounded-md bg-white text-primary', className)}
      {...props}
    />
  );
});

type CommandDialogProps = DialogProps;

const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent className="overflow-hidden p-0 shadow-lg">
        <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  );
};

interface CommandInputProps extends ComponentPropsWithoutRef<typeof CommandPrimitive.Input> {
  inputClassName?: string;
  minimal?: boolean;
}

const CommandInput = forwardRef<ElementRef<typeof CommandPrimitive.Input>, CommandInputProps>(function CommandInput(
  { className, inputClassName, minimal, ...props },
  ref
) {
  if (minimal) {
    return (
      <CommandPrimitive.Input
        ref={ref}
        className={cn('ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground', inputClassName, className)}
        {...props}
      />
    );
  }
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div className="flex items-center border-b px-2" cmdk-input-wrapper="">
      <Search className="h-4 w-4 shrink-0 text-secondary" />
      <CommandPrimitive.Input
        ref={ref}
        className={cn(
          'flex w-full bg-transparent py-2 text-sm outline-none placeholder:text-light-grey',
          'pl-2',
          inputClassName,
          className
        )}
        {...props}
      />
    </div>
  );
});

const CommandList = forwardRef<ElementRef<typeof CommandPrimitive.List>, ComponentPropsWithoutRef<typeof CommandPrimitive.List>>(
  function CommandList({ className, ...props }, ref) {
    return <CommandPrimitive.List ref={ref} className={cn('max-h-80 overflow-y-auto overflow-x-hidden py-2', className)} {...props} />;
  }
);

const CommandEmpty = forwardRef<ElementRef<typeof CommandPrimitive.Empty>, ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>>(
  function CommandEmpty(props, ref) {
    return <CommandPrimitive.Empty ref={ref} className="px-2 text-sm pointer-events-none text-secondary" {...props} />;
  }
);

const CommandGroup = forwardRef<ElementRef<typeof CommandPrimitive.Group>, ComponentPropsWithoutRef<typeof CommandPrimitive.Group>>(
  function CommandGroup({ className, ...props }, ref) {
    return <CommandPrimitive.Group ref={ref} className={cn('overflow-hidden', className)} {...props} />;
  }
);

const CommandSeparator = forwardRef<
  ElementRef<typeof CommandPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(function CommandSeparator({ className, ...props }, ref) {
  return <CommandPrimitive.Separator ref={ref} className={cn('-mx-1 h-px bg-border', className)} {...props} />;
});

const CommandItem = forwardRef<ElementRef<typeof CommandPrimitive.Item>, ComponentPropsWithoutRef<typeof CommandPrimitive.Item>>(
  function CommandItem({ className, ...props }, ref) {
    return (
      <CommandPrimitive.Item
        ref={ref}
        className={cn(
          'relative flex select-none items-center rounded px-2 text-sm outline-none',
          'cursor-pointer',
          'hover:bg-primary h-6 m-1',
          'data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
          className
        )}
        {...props}
      />
    );
  }
);

const CommandShortcut = ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn('ml-auto text-xs tracking-widest text-muted-foreground', className)} {...props} />;
};

const CommandLoading = forwardRef<ElementRef<typeof CommandPrimitive.Loading>, ComponentPropsWithoutRef<typeof CommandPrimitive.Loading>>(
  function CommandLoading({ className, ...props }, ref) {
    return <CommandPrimitive.Loading ref={ref} className={cn('py-2.5 px-3 text-sm', className)} {...props} />;
  }
);

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
  CommandLoading
};
