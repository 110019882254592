// Currency Holidays data is taken from: https://fxreporting.trading.refinitiv.net/Dx/DxnHtm/default.htm
// Seems to be the most reliable source for currency holidays.

export const manualHolidayMap = new Map<string, ManualHoliday[]>();
export type ManualHoliday = { date: Date; name: string };

const EURHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-01'), name: 'Labour Day' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Christmas' }
];
manualHolidayMap.set('EU', EURHolidays);

const GBPHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'May Day Bank Holiday' },
  { date: new Date('2023-05-08'), name: 'Coronation of Charles III' },
  { date: new Date('2023-05-29'), name: 'Spring Bank Holiday' },
  { date: new Date('2023-08-28'), name: 'August Bank Holiday' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Boxing Day' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-06'), name: 'May Day Bank Holiday' },
  { date: new Date('2024-05-27'), name: 'Spring Bank Holiday' },
  { date: new Date('2024-08-26'), name: 'August Bank Holiday' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Boxing Day' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-05'), name: 'May Day Bank Holiday' },
  { date: new Date('2025-05-26'), name: 'Spring Bank Holiday' },
  { date: new Date('2025-08-25'), name: 'August Bank Holiday' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Boxing Day' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-04'), name: 'May Day Bank Holiday' },
  { date: new Date('2026-05-25'), name: 'Spring Bank Holiday' },
  { date: new Date('2026-08-31'), name: 'August Bank Holiday' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-28'), name: 'Mon after Boxing Day' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-03'), name: 'May Day Bank Holiday' },
  { date: new Date('2027-05-31'), name: 'Spring Bank Holiday' },
  { date: new Date('2027-08-30'), name: 'August Bank Holiday' },
  { date: new Date('2027-12-27'), name: 'Mon after Christmas' },
  { date: new Date('2027-12-28'), name: 'Boxing Day [Transfer]' }
];
manualHolidayMap.set('GB', GBPHolidays);

const USDHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-01-16'), name: "Martin Luther King's Day" },
  { date: new Date('2023-02-20'), name: 'Washington Day' },
  { date: new Date('2023-05-29'), name: 'Memorial Day' },
  { date: new Date('2023-06-19'), name: 'Juneteenth Nat. Ind. Day' },
  { date: new Date('2023-07-04'), name: 'Independence Day' },
  { date: new Date('2023-09-04'), name: 'Labor Day' },
  { date: new Date('2023-10-09'), name: 'Columbus Day' },
  { date: new Date('2023-11-11'), name: 'Veterans Day' },
  { date: new Date('2023-11-23'), name: 'Thanksgiving Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-15'), name: "Martin Luther King's Day" },
  { date: new Date('2024-02-19'), name: 'Washington Day' },
  { date: new Date('2024-05-27'), name: 'Memorial Day' },
  { date: new Date('2024-06-19'), name: 'Juneteenth Nat. Ind. Day' },
  { date: new Date('2024-07-04'), name: 'Independence Day' },
  { date: new Date('2024-09-02'), name: 'Labor Day' },
  { date: new Date('2024-10-14'), name: 'Columbus Day' },
  { date: new Date('2024-11-11'), name: 'Veterans Day' },
  { date: new Date('2024-11-28'), name: 'Thanksgiving Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-20'), name: "Martin Luther King's Day" },
  { date: new Date('2025-02-17'), name: 'Washington Day' },
  { date: new Date('2025-05-26'), name: 'Memorial Day' },
  { date: new Date('2025-06-19'), name: 'Juneteenth Nat. Ind. Day' },
  { date: new Date('2025-07-04'), name: 'Independence Day' },
  { date: new Date('2025-09-01'), name: 'Labor Day' },
  { date: new Date('2025-10-13'), name: 'Columbus Day' },
  { date: new Date('2025-11-11'), name: 'Veterans Day' },
  { date: new Date('2025-11-27'), name: 'Thanksgiving Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-19'), name: "Martin Luther King's Day" },
  { date: new Date('2026-02-16'), name: 'Washington Day' },
  { date: new Date('2026-05-25'), name: 'Memorial Day' },
  { date: new Date('2026-06-19'), name: 'Juneteenth Nat. Ind. Day' },
  { date: new Date('2026-07-04'), name: 'Independence Day' },
  { date: new Date('2026-09-07'), name: 'Labor Day' },
  { date: new Date('2026-10-12'), name: 'Columbus Day' },
  { date: new Date('2026-11-11'), name: 'Veterans Day' },
  { date: new Date('2026-11-26'), name: 'Thanksgiving Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-18'), name: "Martin Luther King's Day" },
  { date: new Date('2027-02-15'), name: 'Washington Day' },
  { date: new Date('2027-05-31'), name: 'Memorial Day' },
  { date: new Date('2027-06-19'), name: 'Juneteenth Nat. Ind. Day' },
  { date: new Date('2027-07-05'), name: 'Mon after Independence Day' },
  { date: new Date('2027-09-06'), name: 'Labor Day' },
  { date: new Date('2027-10-11'), name: 'Columbus Day' },
  { date: new Date('2027-11-11'), name: 'Veterans Day' },
  { date: new Date('2027-11-25'), name: 'Thanksgiving Day' },
  { date: new Date('2027-12-25'), name: 'Christmas' }
];

manualHolidayMap.set('US', USDHolidays);

const CADHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-02-20'), name: 'Family Day' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-05-22'), name: 'Mon before Victoria Day' },
  { date: new Date('2023-07-03'), name: 'Mon after Canada Day' },
  { date: new Date('2023-08-07'), name: 'Ontario Holiday' },
  { date: new Date('2023-09-04'), name: 'Labour Day' },
  {
    date: new Date('2023-10-02'),
    name: 'Mon after Nat. Day of Truth and Rec.'
  },
  { date: new Date('2023-10-09'), name: 'Thanksgiving Day' },
  { date: new Date('2023-11-13'), name: 'Mon after Remembrance Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Boxing Day' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-02-19'), name: 'Family Day' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-05-20'), name: 'Mon before Victoria Day' },
  { date: new Date('2024-07-01'), name: 'Canada Day' },
  { date: new Date('2024-08-05'), name: 'Ontario Holiday' },
  { date: new Date('2024-09-02'), name: 'Labour Day' },
  { date: new Date('2024-09-30'), name: 'Nat. Day of Truth and Rec.' },
  { date: new Date('2024-10-14'), name: 'Thanksgiving Day' },
  { date: new Date('2024-11-11'), name: 'Remembrance Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Boxing Day' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-02-17'), name: 'Family Day' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-05-19'), name: 'Mon before Victoria Day' },
  { date: new Date('2025-07-01'), name: 'Canada Day' },
  { date: new Date('2025-08-04'), name: 'Ontario Holiday' },
  { date: new Date('2025-09-01'), name: 'Labour Day' },
  { date: new Date('2025-09-30'), name: 'Nat. Day of Truth and Rec.' },
  { date: new Date('2025-10-13'), name: 'Thanksgiving Day' },
  { date: new Date('2025-11-11'), name: 'Remembrance Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Boxing Day' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-02-16'), name: 'Family Day' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-05-18'), name: 'Mon before Victoria Day' },
  { date: new Date('2026-07-01'), name: 'Canada Day' },
  { date: new Date('2026-08-03'), name: 'Ontario Holiday' },
  { date: new Date('2026-09-07'), name: 'Labour Day' },
  { date: new Date('2026-09-30'), name: 'Nat. Day of Truth and Rec.' },
  { date: new Date('2026-10-12'), name: 'Thanksgiving Day' },
  { date: new Date('2026-11-11'), name: 'Remembrance Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-28'), name: 'Mon after Boxing Day' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-02-15'), name: 'Family Day' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-05-24'), name: 'Mon before Victoria Day' },
  { date: new Date('2027-07-01'), name: 'Canada Day' },
  { date: new Date('2027-08-02'), name: 'Ontario Holiday' },
  { date: new Date('2027-09-06'), name: 'Labour Day' },
  { date: new Date('2027-09-30'), name: 'Nat. Day of Truth and Rec.' },
  { date: new Date('2027-10-11'), name: 'Thanksgiving Day' },
  { date: new Date('2027-11-11'), name: 'Remembrance Day' },
  { date: new Date('2027-12-27'), name: 'Mon after Christmas' },
  { date: new Date('2027-12-28'), name: 'Boxing Day [Transfer]' }
];

manualHolidayMap.set('CA', CADHolidays);

const AUDHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-01-26'), name: 'Australia Day' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-04-25'), name: 'Anzac Day' },
  { date: new Date('2023-06-12'), name: "King's Birthday" },
  { date: new Date('2023-08-07'), name: 'New South Wales Bank Holiday' },
  { date: new Date('2023-10-02'), name: 'New South Wales Labour Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Boxing Day' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-26'), name: 'Australia Day' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-04-25'), name: 'Anzac Day' },
  { date: new Date('2024-06-10'), name: "King's Birthday" },
  { date: new Date('2024-08-05'), name: 'New South Wales Bank Holiday' },
  { date: new Date('2024-10-07'), name: 'New South Wales Labour Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Boxing Day' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-27'), name: 'Mon after Australia Day' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-04-25'), name: 'Anzac Day' },
  { date: new Date('2025-06-09'), name: "King's Birthday" },
  { date: new Date('2025-08-04'), name: 'New South Wales Bank Holiday' },
  { date: new Date('2025-10-06'), name: 'New South Wales Labour Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Boxing Day' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-26'), name: 'Australia Day' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-04-25'), name: 'Anzac Day' },
  { date: new Date('2026-06-08'), name: "King's Birthday" },
  { date: new Date('2026-08-03'), name: 'New South Wales Bank Holiday' },
  { date: new Date('2026-10-05'), name: 'New South Wales Labour Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-28'), name: 'Mon after Boxing Day' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-26'), name: 'Australia Day' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-04-25'), name: 'Anzac Day' },
  { date: new Date('2027-06-14'), name: "King's Birthday" },
  { date: new Date('2027-08-02'), name: 'New South Wales Bank Holiday' },
  { date: new Date('2027-10-04'), name: 'New South Wales Labour Day' },
  { date: new Date('2027-12-27'), name: 'Mon after Christmas' },
  { date: new Date('2027-12-28'), name: 'Boxing Day [Transfer]' }
];

manualHolidayMap.set('AU', AUDHolidays);

const JPYHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-01-02'), name: 'New Year' },
  { date: new Date('2023-01-03'), name: 'New Year' },
  { date: new Date('2023-01-09'), name: "Adults' Day" },
  { date: new Date('2023-02-11'), name: 'National Foundation Day' },
  { date: new Date('2023-02-23'), name: "Emperor's Birthday" },
  { date: new Date('2023-03-21'), name: 'Vernal Equinox' },
  { date: new Date('2023-04-29'), name: 'Showa Day' },
  { date: new Date('2023-05-03'), name: 'Constitution Memorial Day' },
  { date: new Date('2023-05-04'), name: 'Greenery Day' },
  { date: new Date('2023-05-05'), name: "Children's Day" },
  { date: new Date('2023-07-17'), name: 'Marine Day' },
  { date: new Date('2023-08-11'), name: 'Mountain Day' },
  { date: new Date('2023-09-18'), name: 'Respect for the Aged Day' },
  { date: new Date('2023-09-23'), name: 'Autumnal Equinox' },
  { date: new Date('2023-10-09'), name: 'Sports Day' },
  { date: new Date('2023-11-03'), name: 'Culture Day' },
  { date: new Date('2023-11-23'), name: 'Labour Thanksgiving Day' },
  { date: new Date('2023-12-31'), name: "New Year's Eve" },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-02'), name: 'New Year' },
  { date: new Date('2024-01-03'), name: 'New Year' },
  { date: new Date('2024-01-08'), name: "Adults' Day" },
  { date: new Date('2024-02-12'), name: 'Mon after National Foundation Day' },
  { date: new Date('2024-02-23'), name: "Emperor's Birthday" },
  { date: new Date('2024-03-20'), name: 'Vernal Equinox' },
  { date: new Date('2024-04-29'), name: 'Showa Day' },
  { date: new Date('2024-05-03'), name: 'Constitution Memorial Day' },
  { date: new Date('2024-05-04'), name: 'Greenery Day' },
  { date: new Date('2024-05-06'), name: "Mon after Children's Day" },
  { date: new Date('2024-07-15'), name: 'Marine Day' },
  { date: new Date('2024-08-12'), name: 'Mon after Mountain Day' },
  { date: new Date('2024-09-16'), name: 'Respect for the Aged Day' },
  { date: new Date('2024-09-23'), name: 'Mon after Autumnal Equinox' },
  { date: new Date('2024-10-14'), name: 'Sports Day' },
  { date: new Date('2024-11-04'), name: 'Mon after Culture Day' },
  { date: new Date('2024-11-23'), name: 'Labour Thanksgiving Day' },
  { date: new Date('2024-12-31'), name: "New Year's Eve" },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-02'), name: 'New Year' },
  { date: new Date('2025-01-03'), name: 'New Year' },
  { date: new Date('2025-01-13'), name: "Adults' Day" },
  { date: new Date('2025-02-11'), name: 'National Foundation Day' },
  { date: new Date('2025-02-24'), name: "Mon after Emperor's Birthday" },
  { date: new Date('2025-03-20'), name: 'Vernal Equinox' },
  { date: new Date('2025-04-29'), name: 'Showa Day' },
  { date: new Date('2025-05-03'), name: 'Constitution Memorial Day' },
  { date: new Date('2025-05-05'), name: 'Mon after Greenery Day' },
  { date: new Date('2025-05-06'), name: "Children's Day [Transfer]" },
  { date: new Date('2025-07-21'), name: 'Marine Day' },
  { date: new Date('2025-08-11'), name: 'Mountain Day' },
  { date: new Date('2025-09-15'), name: 'Respect for the Aged Day' },
  { date: new Date('2025-09-23'), name: 'Autumnal Equinox' },
  { date: new Date('2025-10-13'), name: 'Sports Day' },
  { date: new Date('2025-11-03'), name: 'Culture Day' },
  { date: new Date('2025-11-24'), name: 'Mon after Labour Thanksgiving Day' },
  { date: new Date('2025-12-31'), name: "New Year's Eve" },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-02'), name: 'New Year' },
  { date: new Date('2026-01-03'), name: 'New Year' },
  { date: new Date('2026-01-12'), name: "Adults' Day" },
  { date: new Date('2026-02-11'), name: 'National Foundation Day' },
  { date: new Date('2026-02-23'), name: "Emperor's Birthday" },
  { date: new Date('2026-03-20'), name: 'Vernal Equinox' },
  { date: new Date('2026-04-29'), name: 'Showa Day' },
  { date: new Date('2026-05-04'), name: 'Mon after Constitution Memorial Day' },
  { date: new Date('2026-05-05'), name: 'Greenery Day [Transfer]' },
  { date: new Date('2026-05-06'), name: "Children's Day [Transfer]" },
  { date: new Date('2026-07-20'), name: 'Marine Day' },
  { date: new Date('2026-08-11'), name: 'Mountain Day' },
  { date: new Date('2026-09-21'), name: 'Respect for the Aged Day' },
  { date: new Date('2026-09-22'), name: 'National Holiday' },
  { date: new Date('2026-09-23'), name: 'Autumnal Equinox' },
  { date: new Date('2026-10-12'), name: 'Sports Day' },
  { date: new Date('2026-11-03'), name: 'Culture Day' },
  { date: new Date('2026-11-23'), name: 'Labour Thanksgiving Day' },
  { date: new Date('2026-12-31'), name: "New Year's Eve" },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-02'), name: 'New Year' },
  { date: new Date('2027-01-03'), name: 'New Year' },
  { date: new Date('2027-01-11'), name: "Adults' Day" },
  { date: new Date('2027-02-11'), name: 'National Foundation Day' },
  { date: new Date('2027-02-23'), name: "Emperor's Birthday" },
  { date: new Date('2027-03-22'), name: 'Mon after Vernal Equinox' },
  { date: new Date('2027-04-29'), name: 'Showa Day' },
  { date: new Date('2027-05-03'), name: 'Constitution Memorial Day' },
  { date: new Date('2027-05-04'), name: 'Greenery Day' },
  { date: new Date('2027-05-05'), name: "Children's Day" },
  { date: new Date('2027-07-19'), name: 'Marine Day' },
  { date: new Date('2027-08-11'), name: 'Mountain Day' },
  { date: new Date('2027-09-20'), name: 'Respect for the Aged Day' },
  { date: new Date('2027-09-23'), name: 'Autumnal Equinox' },
  { date: new Date('2027-10-11'), name: 'Sports Day' },
  { date: new Date('2027-11-03'), name: 'Culture Day' },
  { date: new Date('2027-11-23'), name: 'Labour Thanksgiving Day' },
  { date: new Date('2027-12-31'), name: "New Year's Eve" }
];

manualHolidayMap.set('JP', JPYHolidays);

const MXNHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-01-02'), name: 'US New Year Holiday' },
  { date: new Date('2023-01-16'), name: 'US M.L.King Day' },
  { date: new Date('2023-02-06'), name: 'Constitution Day' },
  { date: new Date('2023-02-20'), name: 'US Washington' },
  { date: new Date('2023-03-20'), name: 'Benito Juarez Anniversary' },
  { date: new Date('2023-04-06'), name: 'Maundy Thursday' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-05-01'), name: 'May Day' },
  { date: new Date('2023-05-29'), name: 'US Memorial' },
  { date: new Date('2023-06-19'), name: 'US Juneteenth Nat. Ind. Day' },
  { date: new Date('2023-07-04'), name: 'US Independence' },
  { date: new Date('2023-09-04'), name: 'US Labor Day' },
  { date: new Date('2023-09-16'), name: 'Independence Day' },
  { date: new Date('2023-10-09'), name: 'US Columbus Day' },
  { date: new Date('2023-11-02'), name: "All Souls' Day" },
  { date: new Date('2023-11-11'), name: 'US Veterans Day' },
  { date: new Date('2023-11-20'), name: 'Revolution Day' },
  { date: new Date('2023-11-23'), name: 'US Thanksgiving' },
  { date: new Date('2023-12-12'), name: 'Virgin of Guadalupe' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-15'), name: 'US M.L.King Day' },
  { date: new Date('2024-02-05'), name: 'Constitution Day' },
  { date: new Date('2024-02-19'), name: 'US Washington' },
  { date: new Date('2024-03-18'), name: 'Benito Juarez Anniversary' },
  { date: new Date('2024-03-28'), name: 'Maundy Thursday' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-05-01'), name: 'May Day' },
  { date: new Date('2024-05-27'), name: 'US Memorial' },
  { date: new Date('2024-06-19'), name: 'US Juneteenth Nat. Ind. Day' },
  { date: new Date('2024-07-04'), name: 'US Independence' },
  { date: new Date('2024-09-02'), name: 'US Labor Day' },
  { date: new Date('2024-09-16'), name: 'Independence Day' },
  { date: new Date('2024-10-14'), name: 'US Columbus Day' },
  { date: new Date('2024-11-02'), name: "All Souls' Day" },
  { date: new Date('2024-11-11'), name: 'US Veterans Day' },
  { date: new Date('2024-11-18'), name: 'Revolution Day' },
  { date: new Date('2024-11-28'), name: 'US Thanksgiving' },
  { date: new Date('2024-12-12'), name: 'Virgin of Guadalupe' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-20'), name: 'US M.L.King Day' },
  { date: new Date('2025-02-03'), name: 'Constitution Day' },
  { date: new Date('2025-02-17'), name: 'US Washington' },
  { date: new Date('2025-03-17'), name: 'Benito Juarez Anniversary' },
  { date: new Date('2025-04-17'), name: 'Maundy Thursday' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-05-01'), name: 'May Day' },
  { date: new Date('2025-05-26'), name: 'US Memorial' },
  { date: new Date('2025-06-19'), name: 'US Juneteenth Nat. Ind. Day' },
  { date: new Date('2025-07-04'), name: 'US Independence' },
  { date: new Date('2025-09-01'), name: 'US Labor Day' },
  { date: new Date('2025-09-16'), name: 'Independence Day' },
  { date: new Date('2025-10-13'), name: 'US Columbus Day' },
  { date: new Date('2025-11-02'), name: "All Souls' Day" },
  { date: new Date('2025-11-11'), name: 'US Veterans Day' },
  { date: new Date('2025-11-17'), name: 'Revolution Day' },
  { date: new Date('2025-11-27'), name: 'US Thanksgiving' },
  { date: new Date('2025-12-12'), name: 'Virgin of Guadalupe' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-19'), name: 'US M.L.King Day' },
  { date: new Date('2026-02-02'), name: 'Constitution Day' },
  { date: new Date('2026-02-16'), name: 'US Washington' },
  { date: new Date('2026-03-16'), name: 'Benito Juarez Anniversary' },
  { date: new Date('2026-04-02'), name: 'Maundy Thursday' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-05-01'), name: 'May Day' },
  { date: new Date('2026-05-25'), name: 'US Memorial' },
  { date: new Date('2026-06-19'), name: 'US Juneteenth Nat. Ind. Day' },
  { date: new Date('2026-07-04'), name: 'US Independence' },
  { date: new Date('2026-09-07'), name: 'US Labor Day' },
  { date: new Date('2026-09-16'), name: 'Independence Day' },
  { date: new Date('2026-10-12'), name: 'US Columbus Day' },
  { date: new Date('2026-11-02'), name: "All Souls' Day" },
  { date: new Date('2026-11-11'), name: 'US Veterans Day' },
  { date: new Date('2026-11-16'), name: 'Revolution Day' },
  { date: new Date('2026-11-26'), name: 'US Thanksgiving' },
  { date: new Date('2026-12-12'), name: 'Virgin of Guadalupe' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-18'), name: 'US M.L.King Day' },
  { date: new Date('2027-02-01'), name: 'Constitution Day' },
  { date: new Date('2027-02-15'), name: 'US Washington' },
  { date: new Date('2027-03-15'), name: 'Benito Juarez Anniversary' },
  { date: new Date('2027-03-25'), name: 'Maundy Thursday' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-05-01'), name: 'May Day' },
  { date: new Date('2027-05-31'), name: 'US Memorial' },
  { date: new Date('2027-06-19'), name: 'US Juneteenth Nat. Ind. Day' },
  { date: new Date('2027-07-05'), name: 'Mon after US Independence' },
  { date: new Date('2027-09-06'), name: 'US Labor Day' },
  { date: new Date('2027-09-16'), name: 'Independence Day' },
  { date: new Date('2027-10-11'), name: 'US Columbus Day' },
  { date: new Date('2027-11-02'), name: "All Souls' Day" },
  { date: new Date('2027-11-11'), name: 'US Veterans Day' },
  { date: new Date('2027-11-15'), name: 'Revolution Day' },
  { date: new Date('2027-11-25'), name: 'US Thanksgiving' },
  { date: new Date('2027-12-12'), name: 'Virgin of Guadalupe' },
  { date: new Date('2027-12-25'), name: 'Christmas' }
];

manualHolidayMap.set('MX', MXNHolidays);

const ILSHolidayS = [
  { date: new Date('2023-03-07'), name: 'Purim' },
  { date: new Date('2023-04-06'), name: 'Pesach' },
  { date: new Date('2023-04-12'), name: 'Pesach II' },
  { date: new Date('2023-04-26'), name: 'Yom Haatzmaut' },
  { date: new Date('2023-05-26'), name: 'Shavuot' },
  { date: new Date('2023-07-27'), name: "Tishah B'av" },
  { date: new Date('2023-09-16'), name: 'Rosh Hashanah' },
  { date: new Date('2023-09-17'), name: 'Rosh Hashanah' },
  { date: new Date('2023-09-24'), name: 'Eve of Yom Kippur' },
  { date: new Date('2023-09-25'), name: 'Yom Kippur' },
  { date: new Date('2023-09-30'), name: 'Sukkot' },
  { date: new Date('2023-10-07'), name: 'Simchat Torah' },
  { date: new Date('2023-10-31'), name: 'Municipal Elections' },
  { date: new Date('2024-03-24'), name: 'Purim' },
  { date: new Date('2024-04-23'), name: 'Pesach' },
  { date: new Date('2024-04-29'), name: 'Pesach II' },
  { date: new Date('2024-05-14'), name: 'Tue after Yom Haatzmaut' },
  { date: new Date('2024-06-12'), name: 'Shavuot' },
  { date: new Date('2024-08-13'), name: "Tishah B'av" },
  { date: new Date('2024-10-03'), name: 'Rosh Hashanah' },
  { date: new Date('2024-10-04'), name: 'Rosh Hashanah' },
  { date: new Date('2024-10-11'), name: 'Eve of Yom Kippur' },
  { date: new Date('2024-10-12'), name: 'Yom Kippur' },
  { date: new Date('2024-10-17'), name: 'Sukkot' },
  { date: new Date('2024-10-24'), name: 'Simchat Torah' },
  { date: new Date('2025-03-14'), name: 'Purim' },
  { date: new Date('2025-04-13'), name: 'Pesach' },
  { date: new Date('2025-04-19'), name: 'Pesach II' },
  { date: new Date('2025-05-01'), name: 'Thur before Yom Haatzmaut' },
  { date: new Date('2025-06-02'), name: 'Shavuot' },
  { date: new Date('2025-08-03'), name: "Tishah B'av" },
  { date: new Date('2025-09-23'), name: 'Rosh Hashanah' },
  { date: new Date('2025-09-24'), name: 'Rosh Hashanah' },
  { date: new Date('2025-10-01'), name: 'Eve of Yom Kippur' },
  { date: new Date('2025-10-02'), name: 'Yom Kippur' },
  { date: new Date('2025-10-07'), name: 'Sukkot' },
  { date: new Date('2025-10-14'), name: 'Simchat Torah' },
  { date: new Date('2026-03-03'), name: 'Purim' },
  { date: new Date('2026-04-02'), name: 'Pesach' },
  { date: new Date('2026-04-08'), name: 'Pesach II' },
  { date: new Date('2026-04-22'), name: 'Yom Haatzmaut' },
  { date: new Date('2026-05-22'), name: 'Shavuot' },
  { date: new Date('2026-07-23'), name: "Tishah B'av" },
  { date: new Date('2026-09-12'), name: 'Rosh Hashanah' },
  { date: new Date('2026-09-13'), name: 'Rosh Hashanah' },
  { date: new Date('2026-09-20'), name: 'Eve of Yom Kippur' },
  { date: new Date('2026-09-21'), name: 'Yom Kippur' },
  { date: new Date('2026-09-26'), name: 'Sukkot' },
  { date: new Date('2026-10-03'), name: 'Simchat Torah' },
  { date: new Date('2027-03-23'), name: 'Purim' },
  { date: new Date('2027-04-22'), name: 'Pesach' },
  { date: new Date('2027-04-28'), name: 'Pesach II' },
  { date: new Date('2027-05-12'), name: 'Yom Haatzmaut' },
  { date: new Date('2027-06-11'), name: 'Shavuot' },
  { date: new Date('2027-08-12'), name: "Tishah B'av" },
  { date: new Date('2027-10-02'), name: 'Rosh Hashanah' },
  { date: new Date('2027-10-03'), name: 'Rosh Hashanah' },
  { date: new Date('2027-10-10'), name: 'Eve of Yom Kippur' },
  { date: new Date('2027-10-11'), name: 'Yom Kippur' },
  { date: new Date('2027-10-16'), name: 'Sukkot' },
  { date: new Date('2027-10-23'), name: 'Simchat Torah' }
];

manualHolidayMap.set('IL', ILSHolidayS);

const NZDSHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-01-03'), name: 'New Year' },
  { date: new Date('2023-01-23'), name: 'Mon after Wellington Anniversary' },
  { date: new Date('2023-01-30'), name: 'Mon after Auckland Anniversary' },
  { date: new Date('2023-02-06'), name: 'Waitangi Day' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-04-25'), name: 'Anzac Day' },
  { date: new Date('2023-06-05'), name: "Queen's Birthday" },
  { date: new Date('2023-07-14'), name: 'Matariki' },
  { date: new Date('2023-10-23'), name: 'Labour Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-02'), name: 'New Year' },
  { date: new Date('2024-01-22'), name: 'Wellington Anniversary' },
  { date: new Date('2024-01-29'), name: 'Auckland Anniversary' },
  { date: new Date('2024-02-06'), name: 'Waitangi Day' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-04-25'), name: 'Anzac Day' },
  { date: new Date('2024-06-03'), name: "Queen's Birthday" },
  { date: new Date('2024-06-28'), name: 'Matariki' },
  { date: new Date('2024-10-28'), name: 'Labour Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-02'), name: 'New Year' },
  { date: new Date('2025-01-20'), name: 'Mon before Wellington Anniversary' },
  { date: new Date('2025-01-27'), name: 'Mon before Auckland Anniversary' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-04-25'), name: 'Anzac Day' },
  { date: new Date('2025-06-02'), name: "Queen's Birthday" },
  { date: new Date('2025-06-20'), name: 'Matariki' },
  { date: new Date('2025-10-27'), name: 'Labour Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-02'), name: 'New Year' },
  { date: new Date('2026-01-19'), name: 'Mon before Wellington Anniversary' },
  { date: new Date('2026-01-26'), name: 'Mon before Auckland Anniversary' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-04-27'), name: 'Mon after Anzac Day' },
  { date: new Date('2026-06-01'), name: "Queen's Birthday" },
  { date: new Date('2026-07-10'), name: 'Matariki' },
  { date: new Date('2026-10-26'), name: 'Labour Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-28'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-04'), name: 'New Year' },
  { date: new Date('2027-01-25'), name: 'Mon after Wellington Anniversary' },
  { date: new Date('2027-02-01'), name: 'Mon after Auckland Anniversary' },
  { date: new Date('2027-02-08'), name: 'Mon after Waitangi Day' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-04-26'), name: 'Mon after Anzac Day' },
  { date: new Date('2027-06-07'), name: "Queen's Birthday" },
  { date: new Date('2027-06-25'), name: 'Matariki' },
  { date: new Date('2027-10-25'), name: 'Labour Day' },
  { date: new Date('2027-12-27'), name: 'Mon after Christmas' },
  { date: new Date('2027-12-28'), name: 'Christmas' }
];

manualHolidayMap.set('NZ', NZDSHolidays);

const BRLSHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-02-20'), name: 'Carnival' },
  { date: new Date('2023-02-21'), name: 'Carnival' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-21'), name: 'Tiradentes Day' },
  { date: new Date('2023-05-01'), name: 'May Day' },
  { date: new Date('2023-06-08'), name: 'Corpus Christi' },
  { date: new Date('2023-09-07'), name: 'Independence Day' },
  { date: new Date('2023-10-12'), name: 'Aparecida' },
  { date: new Date('2023-11-02'), name: "All Souls' Day" },
  { date: new Date('2023-11-15'), name: 'Republic Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-02-12'), name: 'Carnival' },
  { date: new Date('2024-02-13'), name: 'Carnival' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-21'), name: 'Tiradentes Day' },
  { date: new Date('2024-05-01'), name: 'May Day' },
  { date: new Date('2024-05-30'), name: 'Corpus Christi' },
  { date: new Date('2024-09-07'), name: 'Independence Day' },
  { date: new Date('2024-10-12'), name: 'Aparecida' },
  { date: new Date('2024-11-02'), name: "All Souls' Day" },
  { date: new Date('2024-11-15'), name: 'Republic Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-03-03'), name: 'Carnival' },
  { date: new Date('2025-03-04'), name: 'Carnival' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Tiradentes Day' },
  { date: new Date('2025-05-01'), name: 'May Day' },
  { date: new Date('2025-06-19'), name: 'Corpus Christi' },
  { date: new Date('2025-09-07'), name: 'Independence Day' },
  { date: new Date('2025-10-12'), name: 'Aparecida' },
  { date: new Date('2025-11-02'), name: "All Souls' Day" },
  { date: new Date('2025-11-15'), name: 'Republic Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-02-16'), name: 'Carnival' },
  { date: new Date('2026-02-17'), name: 'Carnival' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-21'), name: 'Tiradentes Day' },
  { date: new Date('2026-05-01'), name: 'May Day' },
  { date: new Date('2026-06-04'), name: 'Corpus Christi' },
  { date: new Date('2026-09-07'), name: 'Independence Day' },
  { date: new Date('2026-10-12'), name: 'Aparecida' },
  { date: new Date('2026-11-02'), name: "All Souls' Day" },
  { date: new Date('2026-11-15'), name: 'Republic Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-02-08'), name: 'Carnival' },
  { date: new Date('2027-02-09'), name: 'Carnival' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-04-21'), name: 'Tiradentes Day' },
  { date: new Date('2027-05-01'), name: 'May Day' },
  { date: new Date('2027-05-27'), name: 'Corpus Christi' },
  { date: new Date('2027-09-07'), name: 'Independence Day' },
  { date: new Date('2027-10-12'), name: 'Aparecida' },
  { date: new Date('2027-11-02'), name: "All Souls' Day" },
  { date: new Date('2027-11-15'), name: 'Republic Day' },
  { date: new Date('2027-12-25'), name: 'Christmas' }
];

manualHolidayMap.set('BR', BRLSHolidays);

const CHFHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-01-02'), name: 'Berchtelistag' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-05-18'), name: 'Ascension' },
  { date: new Date('2023-05-29'), name: 'Whit Monday' },
  { date: new Date('2023-08-01'), name: 'Swiss National Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-02'), name: 'Berchtelistag' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-05-09'), name: 'Ascension' },
  { date: new Date('2024-05-20'), name: 'Whit Monday' },
  { date: new Date('2024-08-01'), name: 'Swiss National Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-02'), name: 'Berchtelistag' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-05-29'), name: 'Ascension' },
  { date: new Date('2025-06-09'), name: 'Whit Monday' },
  { date: new Date('2025-08-01'), name: 'Swiss National Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-02'), name: 'Berchtelistag' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  { date: new Date('2026-05-14'), name: 'Ascension' },
  { date: new Date('2026-05-25'), name: 'Whit Monday' },
  { date: new Date('2026-08-01'), name: 'Swiss National Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-02'), name: 'Berchtelistag' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-01'), name: 'Labour Day' },
  { date: new Date('2027-05-06'), name: 'Ascension' },
  { date: new Date('2027-05-17'), name: 'Whit Monday' },
  { date: new Date('2027-08-01'), name: 'Swiss National Day' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Christmas' }
];

manualHolidayMap.set('CH', CHFHolidays);

const CZKHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'May Day' },
  { date: new Date('2023-05-08'), name: 'Liberation Day' },
  { date: new Date('2023-07-05'), name: 'Cyril and Methodius' },
  { date: new Date('2023-07-06'), name: 'Master Jan Hus' },
  { date: new Date('2023-09-28'), name: 'Statehood Day' },
  { date: new Date('2023-10-28'), name: 'Independence Day' },
  { date: new Date('2023-11-17'), name: "Students' Day" },
  { date: new Date('2023-12-24'), name: 'Christmas Eve' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-01'), name: 'May Day' },
  { date: new Date('2024-05-08'), name: 'Liberation Day' },
  { date: new Date('2024-07-05'), name: 'Cyril and Methodius' },
  { date: new Date('2024-07-06'), name: 'Master Jan Hus' },
  { date: new Date('2024-09-28'), name: 'Statehood Day' },
  { date: new Date('2024-10-28'), name: 'Independence Day' },
  { date: new Date('2024-11-17'), name: "Students' Day" },
  { date: new Date('2024-12-24'), name: 'Christmas Eve' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'May Day' },
  { date: new Date('2025-05-08'), name: 'Liberation Day' },
  { date: new Date('2025-07-05'), name: 'Cyril and Methodius' },
  { date: new Date('2025-07-06'), name: 'Master Jan Hus' },
  { date: new Date('2025-09-28'), name: 'Statehood Day' },
  { date: new Date('2025-10-28'), name: 'Independence Day' },
  { date: new Date('2025-11-17'), name: "Students' Day" },
  { date: new Date('2025-12-24'), name: 'Christmas Eve' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'May Day' },
  { date: new Date('2026-05-08'), name: 'Liberation Day' },
  { date: new Date('2026-07-05'), name: 'Cyril and Methodius' },
  { date: new Date('2026-07-06'), name: 'Master Jan Hus' },
  { date: new Date('2026-09-28'), name: 'Statehood Day' },
  { date: new Date('2026-10-28'), name: 'Independence Day' },
  { date: new Date('2026-11-17'), name: "Students' Day" },
  { date: new Date('2026-12-24'), name: 'Christmas Eve' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-01'), name: 'May Day' },
  { date: new Date('2027-05-08'), name: 'Liberation Day' },
  { date: new Date('2027-07-05'), name: 'Cyril and Methodius' },
  { date: new Date('2027-07-06'), name: 'Master Jan Hus' },
  { date: new Date('2027-09-28'), name: 'Statehood Day' },
  { date: new Date('2027-10-28'), name: 'Independence Day' },
  { date: new Date('2027-11-17'), name: "Students' Day" },
  { date: new Date('2027-12-24'), name: 'Christmas Eve' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Christmas' }
];

manualHolidayMap.set('CZ', CZKHolidays);

const DKKHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-04-06'), name: 'Maundy Thursday' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-05'), name: 'General Prayer Day' },
  { date: new Date('2023-05-18'), name: 'Ascension' },
  { date: new Date('2023-05-19'), name: 'Ascension' },
  { date: new Date('2023-05-29'), name: 'Whit Monday' },
  { date: new Date('2023-06-05'), name: 'Constitution Day' },
  { date: new Date('2023-12-24'), name: 'Christmas Eve' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Boxing Day' },
  { date: new Date('2023-12-31'), name: "New Year's Eve" },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-03-28'), name: 'Maundy Thursday' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-09'), name: 'Ascension' },
  { date: new Date('2024-05-10'), name: 'Ascension' },
  { date: new Date('2024-05-20'), name: 'Whit Monday' },
  { date: new Date('2024-06-05'), name: 'Constitution Day' },
  { date: new Date('2024-12-24'), name: 'Christmas Eve' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Boxing Day' },
  { date: new Date('2024-12-31'), name: "New Year's Eve" },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-04-17'), name: 'Maundy Thursday' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-29'), name: 'Ascension' },
  { date: new Date('2025-05-30'), name: 'Ascension' },
  { date: new Date('2025-06-05'), name: 'Constitution Day' },
  { date: new Date('2025-06-09'), name: 'Whit Monday' },
  { date: new Date('2025-12-24'), name: 'Christmas Eve' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Boxing Day' },
  { date: new Date('2025-12-31'), name: "New Year's Eve" },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-04-02'), name: 'Maundy Thursday' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-14'), name: 'Ascension' },
  { date: new Date('2026-05-15'), name: 'Ascension' },
  { date: new Date('2026-05-25'), name: 'Whit Monday' },
  { date: new Date('2026-06-05'), name: 'Constitution Day' },
  { date: new Date('2026-12-24'), name: 'Christmas Eve' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Boxing Day' },
  { date: new Date('2026-12-31'), name: "New Year's Eve" },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-25'), name: 'Maundy Thursday' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-06'), name: 'Ascension' },
  { date: new Date('2027-05-07'), name: 'Ascension' },
  { date: new Date('2027-05-17'), name: 'Whit Monday' },
  { date: new Date('2027-06-05'), name: 'Constitution Day' },
  { date: new Date('2027-12-24'), name: 'Christmas Eve' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Boxing Day' },
  { date: new Date('2027-12-31'), name: "New Year's Eve" }
];

manualHolidayMap.set('DK', DKKHolidays);

const HKDHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-01-23'), name: 'Mon after Lunar New Year' },
  { date: new Date('2023-01-24'), name: 'Lunar New Year' },
  { date: new Date('2023-01-25'), name: 'Lunar New Year' },
  { date: new Date('2023-04-05'), name: 'Ching Ming' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-05-26'), name: "Buddha's Birthday[Estimated]" },
  { date: new Date('2023-06-22'), name: 'Tuen Ng' },
  { date: new Date('2023-07-01'), name: 'SAR Establishment Day' },
  { date: new Date('2023-09-30'), name: 'Mid Autumn [Estimated]' },
  { date: new Date('2023-10-02'), name: 'Mon after National Day' },
  { date: new Date('2023-10-23'), name: 'Chung Yeung' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-02-10'), name: 'Lunar New Year' },
  { date: new Date('2024-02-12'), name: 'Lunar New Year' },
  { date: new Date('2024-02-13'), name: 'Lunar New Year' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-04-04'), name: 'Ching Ming' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-05-15'), name: "Buddha's Birthday[Estimated]" },
  { date: new Date('2024-06-10'), name: 'Tuen Ng' },
  { date: new Date('2024-07-01'), name: 'SAR Establishment Day' },
  { date: new Date('2024-09-18'), name: 'Mid Autumn [Estimated]' },
  { date: new Date('2024-10-01'), name: 'National Day' },
  { date: new Date('2024-10-11'), name: 'Chung Yeung' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-29'), name: 'Lunar New Year' },
  { date: new Date('2025-01-30'), name: 'Lunar New Year' },
  { date: new Date('2025-01-31'), name: 'Lunar New Year' },
  { date: new Date('2025-04-04'), name: 'Ching Ming' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-05-05'), name: "Buddha's Birthday[Estimated]" },
  { date: new Date('2025-05-31'), name: 'Tuen Ng' },
  { date: new Date('2025-07-01'), name: 'SAR Establishment Day' },
  { date: new Date('2025-10-01'), name: 'National Day' },
  { date: new Date('2025-10-07'), name: 'Mid Autumn [Estimated]' },
  { date: new Date('2025-10-29'), name: 'Chung Yeung' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-02-17'), name: 'Lunar New Year' },
  { date: new Date('2026-02-18'), name: 'Lunar New Year' },
  { date: new Date('2026-02-19'), name: 'Lunar New Year' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-04'), name: 'Ching Ming' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  {
    date: new Date('2026-05-25'),
    name: "Mon after Buddha's Birthday[Estimated]"
  },
  { date: new Date('2026-06-19'), name: 'Tuen Ng' },
  { date: new Date('2026-07-01'), name: 'SAR Establishment Day' },
  { date: new Date('2026-09-26'), name: 'Mid Autumn [Estimated]' },
  { date: new Date('2026-10-01'), name: 'National Day' },
  { date: new Date('2026-10-19'), name: 'Mon after Chung Yeung' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-02-06'), name: 'Lunar New Year' },
  { date: new Date('2027-02-08'), name: 'Lunar New Year' },
  { date: new Date('2027-02-09'), name: 'Lunar New Year' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-04-05'), name: 'Ching Ming' },
  { date: new Date('2027-05-01'), name: 'Labour Day' },
  { date: new Date('2027-05-13'), name: "Buddha's Birthday[Estimated]" },
  { date: new Date('2027-06-09'), name: 'Tuen Ng' },
  { date: new Date('2027-07-01'), name: 'SAR Establishment Day' },
  { date: new Date('2027-09-16'), name: 'Mid Autumn [Estimated]' },
  { date: new Date('2027-10-01'), name: 'National Day' },
  { date: new Date('2027-10-08'), name: 'Chung Yeung' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-27'), name: 'Christmas' }
];

manualHolidayMap.set('HK', HKDHolidays);

const HUFHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-03-15'), name: 'Ann. of 1848 Revolution' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'May Day' },
  { date: new Date('2023-05-29'), name: 'Whit Monday' },
  { date: new Date('2023-08-20'), name: 'Feast of St. Stephen' },
  { date: new Date('2023-10-23'), name: 'Ann. of 1956 Revolution' },
  { date: new Date('2023-11-01'), name: 'Old Friends' },
  { date: new Date('2023-12-24'), name: 'Christmas Eve' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-03-15'), name: 'Ann. of 1848 Revolution' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-01'), name: 'May Day' },
  { date: new Date('2024-05-20'), name: 'Whit Monday' },
  { date: new Date('2024-08-19'), name: 'Feast of St. Stephen [Bridge]' },
  { date: new Date('2024-08-20'), name: 'Feast of St. Stephen' },
  { date: new Date('2024-10-23'), name: 'Ann. of 1956 Revolution' },
  { date: new Date('2024-11-01'), name: 'Old Friends' },
  { date: new Date('2024-12-24'), name: 'Christmas Eve' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2024-12-27'), name: 'Christmas [Bridge]' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-03-15'), name: 'Ann. of 1848 Revolution' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'May Day' },
  { date: new Date('2025-05-02'), name: 'May Day [Bridge]' },
  { date: new Date('2025-06-09'), name: 'Whit Monday' },
  { date: new Date('2025-08-20'), name: 'Feast of St. Stephen' },
  { date: new Date('2025-10-23'), name: 'Ann. of 1956 Revolution' },
  { date: new Date('2025-10-24'), name: 'Ann. of 1956 Revolution [Bridge]' },
  { date: new Date('2025-11-01'), name: 'Old Friends' },
  { date: new Date('2025-12-24'), name: 'Christmas Eve' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-02'), name: 'New Year [Bridge]' },
  { date: new Date('2026-03-15'), name: 'Ann. of 1848 Revolution' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'May Day' },
  { date: new Date('2026-05-25'), name: 'Whit Monday' },
  { date: new Date('2026-08-20'), name: 'Feast of St. Stephen' },
  { date: new Date('2026-08-21'), name: 'Feast of St. Stephen [Bridge]' },
  { date: new Date('2026-10-23'), name: 'Ann. of 1956 Revolution' },
  { date: new Date('2026-11-01'), name: 'Old Friends' },
  { date: new Date('2026-12-24'), name: 'Christmas Eve' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-15'), name: 'Ann. of 1848 Revolution' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-01'), name: 'May Day' },
  { date: new Date('2027-05-17'), name: 'Whit Monday' },
  { date: new Date('2027-08-20'), name: 'Feast of St. Stephen' },
  { date: new Date('2027-10-23'), name: 'Ann. of 1956 Revolution' },
  { date: new Date('2027-11-01'), name: 'Old Friends' },
  { date: new Date('2027-12-24'), name: 'Christmas Eve' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Christmas' }
];

manualHolidayMap.set('HU', HUFHolidays);

const NOKHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-04-06'), name: 'Maundy Thursday' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-05-17'), name: 'Constitution Day' },
  { date: new Date('2023-05-18'), name: 'Ascension' },
  { date: new Date('2023-05-29'), name: 'Whit Monday' },
  { date: new Date('2023-12-24'), name: 'Christmas Eve' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Boxing Day' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-03-28'), name: 'Maundy Thursday' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-05-09'), name: 'Ascension' },
  { date: new Date('2024-05-17'), name: 'Constitution Day' },
  { date: new Date('2024-05-20'), name: 'Whit Monday' },
  { date: new Date('2024-12-24'), name: 'Christmas Eve' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Boxing Day' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-04-17'), name: 'Maundy Thursday' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-05-17'), name: 'Constitution Day' },
  { date: new Date('2025-05-29'), name: 'Ascension' },
  { date: new Date('2025-06-09'), name: 'Whit Monday' },
  { date: new Date('2025-12-24'), name: 'Christmas Eve' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Boxing Day' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-04-02'), name: 'Maundy Thursday' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  { date: new Date('2026-05-14'), name: 'Ascension' },
  { date: new Date('2026-05-17'), name: 'Constitution Day' },
  { date: new Date('2026-05-25'), name: 'Whit Monday' },
  { date: new Date('2026-12-24'), name: 'Christmas Eve' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Boxing Day' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-25'), name: 'Maundy Thursday' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-01'), name: 'Labour Day' },
  { date: new Date('2027-05-06'), name: 'Ascension' },
  { date: new Date('2027-05-17'), name: 'Whit Monday' },
  { date: new Date('2027-12-24'), name: 'Christmas Eve' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Boxing Day' }
];

manualHolidayMap.set('NO', NOKHolidays);

const ZARHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-03-21'), name: 'Human Rights Day' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Family Day' },
  { date: new Date('2023-04-27'), name: 'Freedom Day' },
  { date: new Date('2023-05-01'), name: "Workers' Day" },
  { date: new Date('2023-06-16'), name: 'Youth Day' },
  { date: new Date('2023-08-09'), name: "National Women's Day" },
  { date: new Date('2023-09-25'), name: 'Mon after Heritage Day' },
  { date: new Date('2023-12-16'), name: 'Day of Reconciliation' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Day of Good Will' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-03-21'), name: 'Human Rights Day' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Family Day' },
  { date: new Date('2024-04-27'), name: 'Freedom Day' },
  { date: new Date('2024-05-01'), name: "Workers' Day" },
  { date: new Date('2024-06-17'), name: 'Mon after Youth Day' },
  { date: new Date('2024-08-09'), name: "National Women's Day" },
  { date: new Date('2024-09-24'), name: 'Heritage Day' },
  { date: new Date('2024-12-16'), name: 'Day of Reconciliation' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Day of Good Will' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-03-21'), name: 'Human Rights Day' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Family Day' },
  { date: new Date('2025-04-28'), name: 'Mon after Freedom Day' },
  { date: new Date('2025-05-01'), name: "Workers' Day" },
  { date: new Date('2025-06-16'), name: 'Youth Day' },
  { date: new Date('2025-08-09'), name: "National Women's Day" },
  { date: new Date('2025-09-24'), name: 'Heritage Day' },
  { date: new Date('2025-12-16'), name: 'Day of Reconciliation' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Day of Good Will' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-03-21'), name: 'Human Rights Day' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Family Day' },
  { date: new Date('2026-04-27'), name: 'Freedom Day' },
  { date: new Date('2026-05-01'), name: "Workers' Day" },
  { date: new Date('2026-06-16'), name: 'Youth Day' },
  { date: new Date('2026-08-10'), name: "Mon after National Women's Day" },
  { date: new Date('2026-09-24'), name: 'Heritage Day' },
  { date: new Date('2026-12-16'), name: 'Day of Reconciliation' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Day of Good Will' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-22'), name: 'Mon after Human Rights Day' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Family Day' },
  { date: new Date('2027-04-27'), name: 'Freedom Day' },
  { date: new Date('2027-05-01'), name: "Workers' Day" },
  { date: new Date('2027-06-16'), name: 'Youth Day' },
  { date: new Date('2027-08-09'), name: "National Women's Day" },
  { date: new Date('2027-09-24'), name: 'Heritage Day' },
  { date: new Date('2027-12-16'), name: 'Day of Reconciliation' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-27'), name: 'Mon after Day of Good Will' }
];

manualHolidayMap.set('ZA', ZARHolidays);

const PLNHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-01-06'), name: 'Epiphany' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'May Day' },
  { date: new Date('2023-05-03'), name: 'Constitution Day' },
  { date: new Date('2023-06-08'), name: 'Corpus Christi' },
  { date: new Date('2023-08-15'), name: 'Assumption' },
  { date: new Date('2023-11-01'), name: "All Saints' Day" },
  { date: new Date('2023-11-11'), name: 'Independence Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-06'), name: 'Epiphany' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-01'), name: 'May Day' },
  { date: new Date('2024-05-03'), name: 'Constitution Day' },
  { date: new Date('2024-05-30'), name: 'Corpus Christi' },
  { date: new Date('2024-08-15'), name: 'Assumption' },
  { date: new Date('2024-11-01'), name: "All Saints' Day" },
  { date: new Date('2024-11-11'), name: 'Independence Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-06'), name: 'Epiphany' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'May Day' },
  { date: new Date('2025-05-03'), name: 'Constitution Day' },
  { date: new Date('2025-06-19'), name: 'Corpus Christi' },
  { date: new Date('2025-08-15'), name: 'Assumption' },
  { date: new Date('2025-11-01'), name: "All Saints' Day" },
  { date: new Date('2025-11-11'), name: 'Independence Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-06'), name: 'Epiphany' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'May Day' },
  { date: new Date('2026-05-03'), name: 'Constitution Day' },
  { date: new Date('2026-06-04'), name: 'Corpus Christi' },
  { date: new Date('2026-08-15'), name: 'Assumption' },
  { date: new Date('2026-11-01'), name: "All Saints' Day" },
  { date: new Date('2026-11-11'), name: 'Independence Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-06'), name: 'Epiphany' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-01'), name: 'May Day' },
  { date: new Date('2027-05-03'), name: 'Constitution Day' },
  { date: new Date('2027-05-27'), name: 'Corpus Christi' },
  { date: new Date('2027-08-15'), name: 'Assumption' },
  { date: new Date('2027-11-01'), name: "All Saints' Day" },
  { date: new Date('2027-11-11'), name: 'Independence Day' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Christmas' }
];

manualHolidayMap.set('PL', PLNHolidays);

const RONHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-01-02'), name: 'New Year' },
  { date: new Date('2023-01-24'), name: 'Day of Unification' },
  { date: new Date('2023-04-14'), name: 'Orthodox Good Friday' },
  { date: new Date('2023-04-17'), name: 'Orthodox Easter Monday' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-06-01'), name: "Children's Day" },
  { date: new Date('2023-06-05'), name: 'Rusaliile' },
  { date: new Date('2023-08-15'), name: 'Assumption' },
  { date: new Date('2023-11-30'), name: "St. Andrew's Day" },
  { date: new Date('2023-12-01'), name: 'National Day' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-02'), name: 'New Year' },
  { date: new Date('2024-01-06'), name: 'Feast of the Theophany' },
  { date: new Date('2024-01-07'), name: 'Synaxis of John the Baptist' },
  { date: new Date('2024-01-24'), name: 'Day of Unification' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-05-03'), name: 'Orthodox Good Friday' },
  { date: new Date('2024-05-06'), name: 'Orthodox Easter Monday' },
  { date: new Date('2024-06-01'), name: "Children's Day" },
  { date: new Date('2024-06-24'), name: 'Rusaliile' },
  { date: new Date('2024-08-15'), name: 'Assumption' },
  { date: new Date('2024-11-30'), name: "St. Andrew's Day" },
  { date: new Date('2024-12-01'), name: 'National Day' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-02'), name: 'New Year' },
  { date: new Date('2025-01-06'), name: 'Feast of the Theophany' },
  { date: new Date('2025-01-07'), name: 'Synaxis of John the Baptist' },
  { date: new Date('2025-01-24'), name: 'Day of Unification' },
  { date: new Date('2025-04-18'), name: 'Orthodox Good Friday' },
  { date: new Date('2025-04-21'), name: 'Orthodox Easter Monday' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-06-01'), name: "Children's Day" },
  { date: new Date('2025-06-09'), name: 'Rusaliile' },
  { date: new Date('2025-08-15'), name: 'Assumption' },
  { date: new Date('2025-11-30'), name: "St. Andrew's Day" },
  { date: new Date('2025-12-01'), name: 'National Day' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-02'), name: 'New Year' },
  { date: new Date('2026-01-06'), name: 'Feast of the Theophany' },
  { date: new Date('2026-01-07'), name: 'Synaxis of John the Baptist' },
  { date: new Date('2026-01-24'), name: 'Day of Unification' },
  { date: new Date('2026-04-10'), name: 'Orthodox Good Friday' },
  { date: new Date('2026-04-13'), name: 'Orthodox Easter Monday' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  { date: new Date('2026-06-01'), name: 'Rusaliile' },
  { date: new Date('2026-08-15'), name: 'Assumption' },
  { date: new Date('2026-11-30'), name: "St. Andrew's Day" },
  { date: new Date('2026-12-01'), name: 'National Day' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-02'), name: 'New Year' },
  { date: new Date('2027-01-06'), name: 'Feast of the Theophany' },
  { date: new Date('2027-01-07'), name: 'Synaxis of John the Baptist' },
  { date: new Date('2027-01-24'), name: 'Day of Unification' },
  { date: new Date('2027-04-30'), name: 'Orthodox Good Friday' },
  { date: new Date('2027-05-01'), name: 'Labour Day' },
  { date: new Date('2027-05-03'), name: 'Orthodox Easter Monday' },
  { date: new Date('2027-06-01'), name: "Children's Day" },
  { date: new Date('2027-06-21'), name: 'Rusaliile' },
  { date: new Date('2027-08-15'), name: 'Assumption' },
  { date: new Date('2027-11-30'), name: "St. Andrew's Day" },
  { date: new Date('2027-12-01'), name: 'National Day' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Christmas' }
];

manualHolidayMap.set('RO', RONHolidays);

const SEKHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-01-06'), name: 'Epiphany' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-10'), name: 'Easter Monday' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-05-18'), name: 'Ascension' },
  { date: new Date('2023-06-06'), name: 'National Day' },
  { date: new Date('2023-06-23'), name: 'Midsummer Eve' },
  { date: new Date('2023-12-24'), name: 'Christmas Eve' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2023-12-26'), name: 'Boxing Day' },
  { date: new Date('2023-12-31'), name: "New Year's Eve" },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-06'), name: 'Epiphany' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-01'), name: 'Easter Monday' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-05-09'), name: 'Ascension' },
  { date: new Date('2024-06-06'), name: 'National Day' },
  { date: new Date('2024-06-21'), name: 'Midsummer Eve' },
  { date: new Date('2024-12-24'), name: 'Christmas Eve' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2024-12-26'), name: 'Boxing Day' },
  { date: new Date('2024-12-31'), name: "New Year's Eve" },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-06'), name: 'Epiphany' },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-04-21'), name: 'Easter Monday' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-05-29'), name: 'Ascension' },
  { date: new Date('2025-06-06'), name: 'National Day' },
  { date: new Date('2025-06-20'), name: 'Midsummer Eve' },
  { date: new Date('2025-12-24'), name: 'Christmas Eve' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2025-12-26'), name: 'Boxing Day' },
  { date: new Date('2025-12-31'), name: "New Year's Eve" },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-01-06'), name: 'Epiphany' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-04-06'), name: 'Easter Monday' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  { date: new Date('2026-05-14'), name: 'Ascension' },
  { date: new Date('2026-06-06'), name: 'National Day' },
  { date: new Date('2026-06-19'), name: 'Midsummer Eve' },
  { date: new Date('2026-12-24'), name: 'Christmas Eve' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2026-12-26'), name: 'Boxing Day' },
  { date: new Date('2026-12-31'), name: "New Year's Eve" },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-01-06'), name: 'Epiphany' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-03-29'), name: 'Easter Monday' },
  { date: new Date('2027-05-01'), name: 'Labour Day' },
  { date: new Date('2027-05-06'), name: 'Ascension' },
  { date: new Date('2027-06-06'), name: 'National Day' },
  { date: new Date('2027-06-25'), name: 'Midsummer Eve' },
  { date: new Date('2027-12-24'), name: 'Christmas Eve' },
  { date: new Date('2027-12-25'), name: 'Christmas' },
  { date: new Date('2027-12-26'), name: 'Boxing Day' },
  { date: new Date('2027-12-31'), name: "New Year's Eve" }
];

manualHolidayMap.set('SE', SEKHolidays);

const SGDHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-01-23'), name: 'Mon after Chinese New Year' },
  { date: new Date('2023-01-24'), name: 'Chinese New Year' },
  { date: new Date('2023-04-07'), name: 'Good Friday' },
  { date: new Date('2023-04-22'), name: 'Hari Raya Puasa [Estimated]' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-06-02'), name: 'Vesak Day [Estimated]' },
  { date: new Date('2023-06-29'), name: 'Hari Raya Haji [Estimated]' },
  { date: new Date('2023-08-09'), name: 'National Day' },
  { date: new Date('2023-09-01'), name: 'Polling Day' },
  { date: new Date('2023-11-13'), name: 'Mon after Deepavali [Estimated]' },
  { date: new Date('2023-12-25'), name: 'Christmas' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-02-10'), name: 'Chinese New Year' },
  { date: new Date('2024-02-12'), name: 'Chinese New Year' },
  { date: new Date('2024-03-29'), name: 'Good Friday' },
  { date: new Date('2024-04-10'), name: 'Hari Raya Puasa [Estimated]' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-05-22'), name: 'Vesak Day [Estimated]' },
  {
    date: new Date('2024-06-17'),
    name: 'Mon after Hari Raya Haji [Estimated]'
  },
  { date: new Date('2024-08-09'), name: 'National Day' },
  { date: new Date('2024-10-31'), name: 'Deepavali [Estimated]' },
  { date: new Date('2024-12-25'), name: 'Christmas' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-01-29'), name: 'Chinese New Year' },
  { date: new Date('2025-01-30'), name: 'Chinese New Year' },
  {
    date: new Date('2025-03-31'),
    name: 'Mon after Hari Raya Puasa [Estimated]'
  },
  { date: new Date('2025-04-18'), name: 'Good Friday' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-05-12'), name: 'Vesak Day [Estimated]' },
  { date: new Date('2025-06-06'), name: 'Hari Raya Haji [Estimated]' },
  { date: new Date('2025-08-09'), name: 'National Day' },
  { date: new Date('2025-10-21'), name: 'Deepavali [Estimated]' },
  { date: new Date('2025-12-25'), name: 'Christmas' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-02-17'), name: 'Chinese New Year' },
  { date: new Date('2026-02-18'), name: 'Chinese New Year' },
  { date: new Date('2026-03-20'), name: 'Hari Raya Puasa [Estimated]' },
  { date: new Date('2026-04-03'), name: 'Good Friday' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  { date: new Date('2026-05-27'), name: 'Hari Raya Haji [Estimated]' },
  { date: new Date('2026-06-01'), name: 'Mon after Vesak Day [Estimated]' },
  { date: new Date('2026-08-10'), name: 'Mon after National Day' },
  { date: new Date('2026-11-09'), name: 'Mon after Deepavali [Estimated]' },
  { date: new Date('2026-12-25'), name: 'Christmas' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-02-06'), name: 'Chinese New Year' },
  { date: new Date('2027-02-08'), name: 'Chinese New Year' },
  { date: new Date('2027-03-09'), name: 'Hari Raya Puasa [Estimated]' },
  { date: new Date('2027-03-26'), name: 'Good Friday' },
  { date: new Date('2027-05-01'), name: 'Labour Day' },
  {
    date: new Date('2027-05-17'),
    name: 'Mon after Hari Raya Haji [Estimated]'
  },
  { date: new Date('2027-05-20'), name: 'Vesak Day [Estimated]' },
  { date: new Date('2027-08-09'), name: 'National Day' },
  { date: new Date('2027-10-29'), name: 'Deepavali [Estimated]' },
  { date: new Date('2027-12-25'), name: 'Christmas' }
];

manualHolidayMap.set('SG', SGDHolidays);

const THBHolidays = [
  { date: new Date('2023-01-02'), name: 'Mon after New Year' },
  { date: new Date('2023-03-06'), name: 'Makha Bucha Day' },
  { date: new Date('2023-04-06'), name: 'Chakri Memorial Day' },
  { date: new Date('2023-04-13'), name: 'Songkran Festival' },
  { date: new Date('2023-04-14'), name: 'Songkran Festival' },
  { date: new Date('2023-04-15'), name: 'Songkran Festival' },
  { date: new Date('2023-05-01'), name: 'Labour Day' },
  { date: new Date('2023-05-04'), name: 'Coronation Day' },
  { date: new Date('2023-05-05'), name: 'Special Holiday' },
  { date: new Date('2023-06-05'), name: "Mon after HM Queen's Birthday" },
  { date: new Date('2023-07-28'), name: "HM King's Birthday" },
  { date: new Date('2023-08-01'), name: 'Asarnha Bucha' },
  { date: new Date('2023-08-14'), name: "Mon after Queen Dowager's Birthday" },
  { date: new Date('2023-10-13'), name: 'King Rama IX Anniversary' },
  { date: new Date('2023-10-23'), name: 'Chulalongkorn Day' },
  { date: new Date('2023-12-05'), name: "Father's Day" },
  { date: new Date('2023-12-11'), name: 'Mon after Constitution Day' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-01-02'), name: "New Year's Eve [Transfer]" },
  { date: new Date('2024-04-08'), name: 'Mon after Chakri Memorial Day' },
  { date: new Date('2024-04-13'), name: 'Songkran Festival' },
  { date: new Date('2024-04-15'), name: 'Mon after Songkran Festival' },
  { date: new Date('2024-04-16'), name: 'Songkran Festival [Transfer]' },
  { date: new Date('2024-05-01'), name: 'Labour Day' },
  { date: new Date('2024-05-06'), name: 'Mon after Coronation Day' },
  { date: new Date('2024-06-03'), name: "HM Queen's Birthday" },
  { date: new Date('2024-07-29'), name: "Mon after HM King's Birthday" },
  { date: new Date('2024-08-12'), name: "Queen Dowager's Birthday" },
  { date: new Date('2024-10-14'), name: 'Mon after King Rama IX Anniversary' },
  { date: new Date('2024-10-23'), name: 'Chulalongkorn Day' },
  { date: new Date('2024-12-05'), name: "Father's Day" },
  { date: new Date('2024-12-10'), name: 'Constitution Day' },
  { date: new Date('2024-12-31'), name: "New Year's Eve" },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-04-07'), name: 'Mon after Chakri Memorial Day' },
  { date: new Date('2025-04-13'), name: 'Songkran Festival' },
  { date: new Date('2025-04-14'), name: 'Songkran Festival' },
  { date: new Date('2025-04-15'), name: 'Songkran Festival' },
  { date: new Date('2025-05-01'), name: 'Labour Day' },
  { date: new Date('2025-05-05'), name: 'Mon after Coronation Day' },
  { date: new Date('2025-06-03'), name: "HM Queen's Birthday" },
  { date: new Date('2025-07-28'), name: "HM King's Birthday" },
  { date: new Date('2025-08-12'), name: "Queen Dowager's Birthday" },
  { date: new Date('2025-10-13'), name: 'King Rama IX Anniversary' },
  { date: new Date('2025-10-23'), name: 'Chulalongkorn Day' },
  { date: new Date('2025-12-05'), name: "Father's Day" },
  { date: new Date('2025-12-10'), name: 'Constitution Day' },
  { date: new Date('2025-12-31'), name: "New Year's Eve" },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-04-06'), name: 'Chakri Memorial Day' },
  { date: new Date('2026-04-13'), name: 'Songkran Festival' },
  { date: new Date('2026-04-14'), name: 'Songkran Festival' },
  { date: new Date('2026-04-15'), name: 'Songkran Festival' },
  { date: new Date('2026-05-01'), name: 'Labour Day' },
  { date: new Date('2026-05-04'), name: 'Coronation Day' },
  { date: new Date('2026-06-03'), name: "HM Queen's Birthday" },
  { date: new Date('2026-07-28'), name: "HM King's Birthday" },
  { date: new Date('2026-08-12'), name: "Queen Dowager's Birthday" },
  { date: new Date('2026-10-13'), name: 'King Rama IX Anniversary' },
  { date: new Date('2026-10-23'), name: 'Chulalongkorn Day' },
  { date: new Date('2026-12-07'), name: "Mon after Father's Day" },
  { date: new Date('2026-12-10'), name: 'Constitution Day' },
  { date: new Date('2026-12-31'), name: "New Year's Eve" },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-04-06'), name: 'Chakri Memorial Day' },
  { date: new Date('2027-04-13'), name: 'Songkran Festival' },
  { date: new Date('2027-04-14'), name: 'Songkran Festival' },
  { date: new Date('2027-04-15'), name: 'Songkran Festival' },
  { date: new Date('2027-05-03'), name: 'Mon after Labour Day' },
  { date: new Date('2027-05-04'), name: 'Coronation Day' },
  { date: new Date('2027-06-03'), name: "HM Queen's Birthday" },
  { date: new Date('2027-07-28'), name: "HM King's Birthday" },
  { date: new Date('2027-08-12'), name: "Queen Dowager's Birthday" },
  { date: new Date('2027-10-13'), name: 'King Rama IX Anniversary' },
  { date: new Date('2027-10-25'), name: 'Mon after Chulalongkorn Day' },
  { date: new Date('2027-12-06'), name: "Mon after Father's Day" },
  { date: new Date('2027-12-10'), name: 'Constitution Day' },
  { date: new Date('2027-12-31'), name: "New Year's Eve" }
];

manualHolidayMap.set('TH', THBHolidays);

const TRYHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-04-21'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2023-04-22'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2023-04-23'), name: 'Nat. Sovereignty & Children' },
  { date: new Date('2023-05-01'), name: 'May Day' },
  { date: new Date('2023-05-19'), name: 'Ataturk Commemoration Day' },
  { date: new Date('2023-06-28'), name: 'Feast of Sacrifice' },
  { date: new Date('2023-06-29'), name: 'Feast of Sacrifice' },
  { date: new Date('2023-06-30'), name: 'Feast of Sacrifice' },
  { date: new Date('2023-07-01'), name: 'Feast of Sacrifice' },
  { date: new Date('2023-07-15'), name: 'Unity Day' },
  { date: new Date('2023-08-30'), name: 'Victory Day' },
  { date: new Date('2023-10-29'), name: 'Republic Holiday' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-04-10'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2024-04-11'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2024-04-12'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2024-04-23'), name: 'Nat. Sovereignty & Children' },
  { date: new Date('2024-05-01'), name: 'May Day' },
  { date: new Date('2024-05-19'), name: 'Ataturk Commemoration Day' },
  { date: new Date('2024-06-16'), name: 'Feast of Sacrifice' },
  { date: new Date('2024-06-17'), name: 'Feast of Sacrifice' },
  { date: new Date('2024-06-18'), name: 'Feast of Sacrifice' },
  { date: new Date('2024-06-19'), name: 'Feast of Sacrifice' },
  { date: new Date('2024-07-15'), name: 'Unity Day' },
  { date: new Date('2024-08-30'), name: 'Victory Day' },
  { date: new Date('2024-10-29'), name: 'Republic Holiday' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-03-30'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2025-03-31'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2025-04-01'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2025-04-23'), name: 'Nat. Sovereignty & Children' },
  { date: new Date('2025-05-01'), name: 'May Day' },
  { date: new Date('2025-05-19'), name: 'Ataturk Commemoration Day' },
  { date: new Date('2025-06-06'), name: 'Feast of Sacrifice' },
  { date: new Date('2025-06-07'), name: 'Feast of Sacrifice' },
  { date: new Date('2025-06-08'), name: 'Feast of Sacrifice' },
  { date: new Date('2025-06-09'), name: 'Feast of Sacrifice' },
  { date: new Date('2025-07-15'), name: 'Unity Day' },
  { date: new Date('2025-08-30'), name: 'Victory Day' },
  { date: new Date('2025-10-29'), name: 'Republic Holiday' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-03-20'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2026-03-21'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2026-03-22'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2026-04-23'), name: 'Nat. Sovereignty & Children' },
  { date: new Date('2026-05-01'), name: 'May Day' },
  { date: new Date('2026-05-19'), name: 'Ataturk Commemoration Day' },
  { date: new Date('2026-05-27'), name: 'Feast of Sacrifice' },
  { date: new Date('2026-05-28'), name: 'Feast of Sacrifice' },
  { date: new Date('2026-05-29'), name: 'Feast of Sacrifice' },
  { date: new Date('2026-05-30'), name: 'Feast of Sacrifice' },
  { date: new Date('2026-07-15'), name: 'Unity Day' },
  { date: new Date('2026-08-30'), name: 'Victory Day' },
  { date: new Date('2026-10-29'), name: 'Republic Holiday' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-09'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2027-03-10'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2027-03-11'), name: 'Feast of Ramazan [Estimated]' },
  { date: new Date('2027-04-23'), name: 'Nat. Sovereignty & Children' },
  { date: new Date('2027-05-01'), name: 'May Day' },
  { date: new Date('2027-05-16'), name: 'Feast of Sacrifice' },
  { date: new Date('2027-05-17'), name: 'Feast of Sacrifice' },
  { date: new Date('2027-05-18'), name: 'Feast of Sacrifice' },
  { date: new Date('2027-05-19'), name: 'Feast of Sacrifice' },
  { date: new Date('2027-07-15'), name: 'Unity Day' },
  { date: new Date('2027-08-30'), name: 'Victory Day' },
  { date: new Date('2027-10-29'), name: 'Republic Holiday' }
];

manualHolidayMap.set('TR', TRYHolidays);

const AEDHolidays = [
  { date: new Date('2023-01-01'), name: 'New Year' },
  { date: new Date('2023-04-20'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2023-04-21'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2023-04-22'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2023-04-23'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2023-06-27'), name: 'Mount Arafat Day [Estimated]' },
  { date: new Date('2023-06-28'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2023-06-29'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2023-06-30'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2023-07-21'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2023-09-29'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2023-12-02'), name: 'UAE National Day' },
  { date: new Date('2023-12-03'), name: 'UAE National Day' },
  { date: new Date('2024-01-01'), name: 'New Year' },
  { date: new Date('2024-04-08'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-04-09'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-04-10'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-04-11'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-04-12'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-06-15'), name: 'Mount Arafat Day [Estimated]' },
  { date: new Date('2024-06-16'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2024-06-17'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2024-06-18'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2024-07-07'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2024-09-15'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2024-12-02'), name: 'UAE National Day' },
  { date: new Date('2024-12-03'), name: 'UAE National Day' },
  { date: new Date('2025-01-01'), name: 'New Year' },
  { date: new Date('2025-03-29'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2025-03-30'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2025-03-31'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2025-04-01'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2025-06-05'), name: 'Mount Arafat Day [Estimated]' },
  { date: new Date('2025-06-06'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2025-06-07'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2025-06-08'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2025-06-26'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2025-09-04'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2025-12-02'), name: 'UAE National Day' },
  { date: new Date('2025-12-03'), name: 'UAE National Day' },
  { date: new Date('2026-01-01'), name: 'New Year' },
  { date: new Date('2026-03-18'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-03-19'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-03-20'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-03-21'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-03-22'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-05-26'), name: 'Mount Arafat Day [Estimated]' },
  { date: new Date('2026-05-27'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2026-05-28'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2026-05-29'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2026-06-16'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2026-08-25'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2026-12-02'), name: 'UAE National Day' },
  { date: new Date('2026-12-03'), name: 'UAE National Day' },
  { date: new Date('2027-01-01'), name: 'New Year' },
  { date: new Date('2027-03-08'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2027-03-09'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2027-03-10'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2027-03-11'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2027-05-15'), name: 'Mount Arafat Day [Estimated]' },
  { date: new Date('2027-05-16'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2027-05-17'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2027-05-18'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2027-06-06'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2027-08-14'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2027-12-02'), name: 'UAE National Day' },
  { date: new Date('2027-12-03'), name: 'UAE National Day' }
];

manualHolidayMap.set('AE', AEDHolidays);

const IDRHolidays = [
  { date: new Date('2024-Jan-01'), name: 'New Year' },
  { date: new Date('2024-Feb-08'), name: 'Lailat Al Miraj [Estimated]' },
  { date: new Date('2024-Feb-09'), name: 'National Leave' },
  { date: new Date('2024-Feb-10'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-14'), name: 'Legislative elections' },
  { date: new Date('2024-Mar-11'), name: 'Nyepi Day [Estimated]' },
  { date: new Date('2024-Mar-12'), name: 'National Leave' },
  { date: new Date('2024-Mar-29'), name: 'Good Friday' },
  { date: new Date('2024-Apr-08'), name: 'National Leave [Estimated]' },
  { date: new Date('2024-Apr-09'), name: 'National Leave [Estimated]' },
  { date: new Date('2024-Apr-10'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-Apr-11'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-Apr-12'), name: 'National Leave [Estimated]' },
  { date: new Date('2024-Apr-13'), name: 'National Leave [Estimated]' },
  { date: new Date('2024-Apr-15'), name: 'National Leave' },
  { date: new Date('2024-May-01'), name: 'Labour Day' },
  { date: new Date('2024-May-09'), name: 'Ascension' },
  { date: new Date('2024-May-10'), name: 'National Leave' },
  { date: new Date('2024-May-23'), name: 'Vesak Day [Estimated]' },
  { date: new Date('2024-May-24'), name: 'National Leave' },
  { date: new Date('2024-Jun-01'), name: 'Pancasila Day' },
  { date: new Date('2024-Jun-17'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2024-Jun-18'), name: 'National Leave' },
  { date: new Date('2024-Jul-07'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2024-Aug-17'), name: 'Independence Day' },
  { date: new Date('2024-Sep-16'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2024-Nov-27'), name: 'Regional elections' },
  { date: new Date('2024-Dec-25'), name: 'Christmas' },
  { date: new Date('2024-Dec-26'), name: 'National Leave' },
  { date: new Date('2025-Jan-01'), name: 'New Year' },
  { date: new Date('2025-Jan-27'), name: 'Lailat Al Miraj [Estimated]' },
  { date: new Date('2025-Feb-09'), name: 'National Leave' },
  { date: new Date('2025-Feb-10'), name: 'Chinese New Year' },
  { date: new Date('2025-Mar-09'), name: 'Nyepi Day [Estimated]' },
  { date: new Date('2025-Apr-04'), name: 'Good Friday' },
  { date: new Date('2025-Apr-08'), name: 'National Leave [Estimated]' },
  { date: new Date('2025-Apr-09'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2025-Apr-10'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2025-Apr-11'), name: 'National Leave [Estimated]' },
  { date: new Date('2025-Apr-12'), name: 'National Leave [Estimated]' },
  { date: new Date('2025-Apr-15'), name: 'National Leave' },
  { date: new Date('2025-May-01'), name: 'Labour Day' },
  { date: new Date('2025-May-29'), name: 'Ascension' },
  { date: new Date('2025-May-30'), name: 'National Leave' },
  { date: new Date('2025-May-31'), name: 'Vesak Day [Estimated]' },
  { date: new Date('2025-Jun-01'), name: 'Pancasila Day' },
  { date: new Date('2025-Jul-27'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2025-Jul-28'), name: 'National Leave' },
  { date: new Date('2025-Aug-17'), name: 'Independence Day' },
  { date: new Date('2025-Aug-18'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2025-Sep-06'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2025-Dec-25'), name: 'Christmas' },
  { date: new Date('2025-Dec-26'), name: 'National Leave' },
  { date: new Date('2026-Jan-01'), name: 'New Year' },
  { date: new Date('2026-Jan-16'), name: 'Lailat Al Miraj [Estimated]' },
  { date: new Date('2026-Feb-17'), name: 'Chinese New Year' },
  { date: new Date('2026-Mar-18'), name: 'National Leave [Estimated]' },
  { date: new Date('2026-Mar-19'), name: 'Nyepi Day [Estimated]' },
  { date: new Date('2026-Mar-20'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-Mar-21'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-Mar-22'), name: 'National Leave [Estimated]' },
  { date: new Date('2026-Mar-23'), name: 'National Leave [Estimated]' },
  { date: new Date('2026-Apr-03'), name: 'Good Friday' },
  { date: new Date('2026-May-01'), name: 'Labour Day' },
  { date: new Date('2026-May-14'), name: 'Ascension' },
  { date: new Date('2026-May-27'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2026-May-31'), name: 'Vesak Day [Estimated]' },
  { date: new Date('2026-Jun-01'), name: 'Pancasila Day' },
  { date: new Date('2026-Jun-16'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2026-Aug-17'), name: 'Independence Day' },
  { date: new Date('2026-Aug-25'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2026-Dec-25'), name: 'Christmas' },
  { date: new Date('2027-Jan-01'), name: 'New Year' },
  { date: new Date('2027-Feb-06'), name: 'Lailat Al Miraj [Estimated]' },
  { date: new Date('2027-Feb-07'), name: 'National Leave' },
  { date: new Date('2027-Feb-08'), name: 'Chinese New Year' },
  { date: new Date('2027-Mar-08'), name: 'Nyepi Day [Estimated]' },
  { date: new Date('2027-Mar-10'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2027-Mar-11'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2027-Mar-12'), name: 'National Leave [Estimated]' },
  { date: new Date('2027-Mar-26'), name: 'Good Friday' },
  { date: new Date('2027-May-01'), name: 'Labour Day' },
  { date: new Date('2027-May-06'), name: 'Ascension' },
  { date: new Date('2027-May-16'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2027-May-20'), name: 'Vesak Day [Estimated]' },
  { date: new Date('2027-Jun-01'), name: 'Pancasila Day' },
  { date: new Date('2027-Jun-06'), name: 'Hijri New Year [Estimated]' },
  { date: new Date('2027-Aug-14'), name: 'Mawlid An Nabi [Estimated]' },
  { date: new Date('2027-Aug-17'), name: 'Independence Day' },
  { date: new Date('2027-Dec-24'), name: 'Fri before Christmas' },
  { date: new Date('2027-Dec-25'), name: 'Christmas' }
];

manualHolidayMap.set('ID', IDRHolidays);

const KRWHolidays = [
  { date: new Date('2024-Jan-01'), name: 'New Year' },
  { date: new Date('2024-Feb-09'), name: 'Lunar New Year' },
  { date: new Date('2024-Feb-10'), name: 'Lunar New Year' },
  { date: new Date('2024-Feb-12'), name: 'Lunar New Year' },
  { date: new Date('2024-Mar-01'), name: 'Independence Movement Day' },
  { date: new Date('2024-Apr-10'), name: 'Election Day' },
  { date: new Date('2024-May-01'), name: 'May Day' },
  { date: new Date('2024-May-06'), name: "Mon after Children's Day" },
  { date: new Date('2024-May-15'), name: "Buddha's Birthday[Estimated]" },
  { date: new Date('2024-Jun-06'), name: 'Memorial Day' },
  { date: new Date('2024-Aug-15'), name: 'Liberation Day' },
  { date: new Date('2024-Sep-16'), name: 'Harvest Moon' },
  { date: new Date('2024-Sep-17'), name: 'Harvest Moon' },
  { date: new Date('2024-Sep-18'), name: 'Harvest Moon' },
  { date: new Date('2024-Oct-03'), name: 'National Foundation Day' },
  { date: new Date('2024-Oct-09'), name: 'Hangul Day' },
  { date: new Date('2024-Dec-25'), name: 'Christmas' },
  { date: new Date('2025-Jan-01'), name: 'New Year' },
  { date: new Date('2025-Jan-28'), name: 'Lunar New Year' },
  { date: new Date('2025-Jan-29'), name: 'Lunar New Year' },
  { date: new Date('2025-Jan-30'), name: 'Lunar New Year' },
  {
    date: new Date('2025-Mar-03'),
    name: 'Mon after Independence Movement Day'
  },
  { date: new Date('2025-May-01'), name: 'May Day' },
  { date: new Date('2025-May-05'), name: "Children's Day" },
  {
    date: new Date('2025-May-06'),
    name: "Buddha's Birthday[Estimated] [Transfer]"
  },
  { date: new Date('2025-Jun-06'), name: 'Memorial Day' },
  { date: new Date('2025-Aug-15'), name: 'Liberation Day' },
  { date: new Date('2025-Oct-03'), name: 'National Foundation Day' },
  { date: new Date('2025-Oct-06'), name: 'Mon after Harvest Moon' },
  { date: new Date('2025-Oct-07'), name: 'Harvest Moon' },
  { date: new Date('2025-Oct-08'), name: 'Harvest Moon' },
  { date: new Date('2025-Oct-09'), name: 'Hangul Day' },
  { date: new Date('2025-Dec-25'), name: 'Christmas' },
  { date: new Date('2026-Jan-01'), name: 'New Year' },
  { date: new Date('2026-Feb-16'), name: 'Lunar New Year' },
  { date: new Date('2026-Feb-17'), name: 'Lunar New Year' },
  { date: new Date('2026-Feb-18'), name: 'Lunar New Year' },
  {
    date: new Date('2026-Mar-02'),
    name: 'Mon after Independence Movement Day'
  },
  { date: new Date('2026-May-01'), name: 'May Day' },
  { date: new Date('2026-May-05'), name: "Children's Day" },
  {
    date: new Date('2026-May-25'),
    name: "Mon after Buddha's Birthday[Estimated]"
  },
  { date: new Date('2026-Jun-06'), name: 'Memorial Day' },
  { date: new Date('2026-Aug-17'), name: 'Mon after Liberation Day' },
  { date: new Date('2026-Sep-24'), name: 'Harvest Moon' },
  { date: new Date('2026-Sep-25'), name: 'Harvest Moon' },
  { date: new Date('2026-Sep-26'), name: 'Harvest Moon' },
  { date: new Date('2026-Oct-05'), name: 'Mon after National Foundation Day' },
  { date: new Date('2026-Oct-09'), name: 'Hangul Day' },
  { date: new Date('2026-Dec-25'), name: 'Christmas' },
  { date: new Date('2027-Jan-01'), name: 'New Year' },
  { date: new Date('2027-Feb-06'), name: 'Lunar New Year' },
  { date: new Date('2027-Feb-08'), name: 'Lunar New Year' },
  { date: new Date('2027-Feb-09'), name: 'Lunar New Year' },
  { date: new Date('2027-Mar-01'), name: 'Independence Movement Day' },
  { date: new Date('2027-May-01'), name: 'May Day' },
  { date: new Date('2027-May-05'), name: "Children's Day" },
  { date: new Date('2027-May-13'), name: "Buddha's Birthday[Estimated]" },
  { date: new Date('2027-Jun-06'), name: 'Memorial Day' },
  { date: new Date('2027-Aug-16'), name: 'Mon after Liberation Day' },
  { date: new Date('2027-Sep-14'), name: 'Harvest Moon' },
  { date: new Date('2027-Sep-15'), name: 'Harvest Moon' },
  { date: new Date('2027-Sep-16'), name: 'Harvest Moon' },
  { date: new Date('2027-Oct-04'), name: 'Mon after National Foundation Day' },
  { date: new Date('2027-Oct-11'), name: 'Mon after Hangul Day' },
  { date: new Date('2027-Dec-27'), name: 'Mon after Christmas' }
];

manualHolidayMap.set('KR', KRWHolidays);

const CNHolidays = [
  { date: new Date('2024-Jan-01'), name: 'New Year' },
  { date: new Date('2024-Feb-10'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-11'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-12'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-13'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-14'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-15'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-16'), name: 'Chinese New Year' },
  { date: new Date('2024-Apr-04'), name: 'Ching Ming' },
  { date: new Date('2024-Apr-05'), name: 'Ching Ming [Bridge]' },
  { date: new Date('2024-May-01'), name: 'May Day Holiday' },
  { date: new Date('2024-May-02'), name: 'May Day Holiday' },
  { date: new Date('2024-May-03'), name: 'May Day Holiday' },
  { date: new Date('2024-Jun-10'), name: 'Dragon Boat Festival' },
  { date: new Date('2024-Sep-16'), name: 'Mid Autumn Festival [Bridge]' },
  { date: new Date('2024-Sep-17'), name: 'Mid Autumn Festival' },
  { date: new Date('2024-Oct-01'), name: 'National Holiday' },
  { date: new Date('2024-Oct-02'), name: 'National Holiday' },
  { date: new Date('2024-Oct-03'), name: 'National Holiday' },
  { date: new Date('2024-Oct-04'), name: 'National Holiday' },
  { date: new Date('2024-Oct-05'), name: 'National Holiday' },
  { date: new Date('2024-Oct-06'), name: 'National Holiday' },
  { date: new Date('2024-Oct-07'), name: 'National Holiday' },
  { date: new Date('2025-Jan-01'), name: 'New Year' },
  { date: new Date('2025-Jan-28'), name: 'Chinese New Year Eve' },
  { date: new Date('2025-Jan-29'), name: 'Chinese New Year' },
  { date: new Date('2025-Jan-30'), name: 'Chinese New Year' },
  { date: new Date('2025-Jan-31'), name: 'Chinese New Year' },
  { date: new Date('2025-Feb-01'), name: 'Chinese New Year' },
  { date: new Date('2025-Feb-02'), name: 'Chinese New Year' },
  { date: new Date('2025-Feb-03'), name: 'Chinese New Year' },
  { date: new Date('2025-Apr-04'), name: 'Ching Ming' },
  { date: new Date('2025-May-01'), name: 'May Day Holiday' },
  { date: new Date('2025-May-02'), name: 'May Day Holiday' },
  { date: new Date('2025-May-05'), name: 'May Day Holiday' },
  { date: new Date('2025-Jun-02'), name: 'Mon after Dragon Boat Festival' },
  { date: new Date('2025-Oct-01'), name: 'National Holiday' },
  { date: new Date('2025-Oct-02'), name: 'National Holiday' },
  { date: new Date('2025-Oct-03'), name: 'National Holiday' },
  { date: new Date('2025-Oct-04'), name: 'National Holiday' },
  { date: new Date('2025-Oct-05'), name: 'National Holiday' },
  { date: new Date('2025-Oct-06'), name: 'Mid Autumn Festival' },
  { date: new Date('2025-Oct-07'), name: 'National Holiday' },
  { date: new Date('2026-Jan-01'), name: 'New Year' },
  { date: new Date('2026-Jan-02'), name: 'New Year [Bridge]' },
  { date: new Date('2026-Feb-16'), name: 'Chinese New Year Eve' },
  { date: new Date('2026-Feb-17'), name: 'Chinese New Year' },
  { date: new Date('2026-Feb-18'), name: 'Chinese New Year' },
  { date: new Date('2026-Feb-19'), name: 'Chinese New Year' },
  { date: new Date('2026-Feb-20'), name: 'Chinese New Year' },
  { date: new Date('2026-Feb-21'), name: 'Chinese New Year' },
  { date: new Date('2026-Feb-22'), name: 'Chinese New Year' },
  { date: new Date('2026-Apr-06'), name: 'Mon after Ching Ming' },
  { date: new Date('2026-May-01'), name: 'May Day Holiday' },
  { date: new Date('2026-May-02'), name: 'May Day Holiday' },
  { date: new Date('2026-May-04'), name: 'May Day Holiday' },
  { date: new Date('2026-May-05'), name: 'May Day Holiday' },
  { date: new Date('2026-Jun-19'), name: 'Dragon Boat Festival' },
  { date: new Date('2026-Sep-25'), name: 'Mid Autumn Festival' },
  { date: new Date('2026-Oct-01'), name: 'National Holiday' },
  { date: new Date('2026-Oct-02'), name: 'National Holiday' },
  { date: new Date('2026-Oct-03'), name: 'National Holiday' },
  { date: new Date('2026-Oct-04'), name: 'National Holiday' },
  { date: new Date('2026-Oct-05'), name: 'National Holiday' },
  { date: new Date('2026-Oct-06'), name: 'National Holiday' },
  { date: new Date('2026-Oct-07'), name: 'National Holiday' },
  { date: new Date('2027-Jan-01'), name: 'New Year' },
  { date: new Date('2027-Feb-05'), name: 'Chinese New Year Eve' },
  { date: new Date('2027-Feb-06'), name: 'Chinese New Year' },
  { date: new Date('2027-Feb-07'), name: 'Chinese New Year' },
  { date: new Date('2027-Feb-08'), name: 'Chinese New Year' },
  { date: new Date('2027-Feb-09'), name: 'Chinese New Year' },
  { date: new Date('2027-Feb-10'), name: 'Chinese New Year' },
  { date: new Date('2027-Feb-11'), name: 'Chinese New Year' },
  { date: new Date('2027-Apr-05'), name: 'Ching Ming' },
  { date: new Date('2027-May-01'), name: 'May Day Holiday' },
  { date: new Date('2027-May-02'), name: 'May Day Holiday' },
  { date: new Date('2027-May-03'), name: 'May Day Holiday' },
  { date: new Date('2027-May-04'), name: 'May Day Holiday' },
  { date: new Date('2027-May-05'), name: 'May Day Holiday' },
  { date: new Date('2027-Jun-09'), name: 'Dragon Boat Festival' },
  { date: new Date('2027-Sep-15'), name: 'Mid Autumn Festival' },
  { date: new Date('2027-Oct-01'), name: 'National Holiday' },
  { date: new Date('2027-Oct-02'), name: 'National Holiday' },
  { date: new Date('2027-Oct-03'), name: 'National Holiday' },
  { date: new Date('2027-Oct-04'), name: 'National Holiday' },
  { date: new Date('2027-Oct-05'), name: 'National Holiday' },
  { date: new Date('2027-Oct-06'), name: 'National Holiday' },
  { date: new Date('2027-Oct-07'), name: 'National Holiday' }
];

manualHolidayMap.set('CN', CNHolidays);

const PHPHolidays = [
  { date: new Date('2024-Jan-01'), name: 'New Year' },
  { date: new Date('2024-Feb-09'), name: 'Chinese New Year' },
  { date: new Date('2024-Feb-10'), name: 'Chinese New Year' },
  { date: new Date('2024-Mar-28'), name: 'Maundy Thursday' },
  { date: new Date('2024-Mar-29'), name: 'Good Friday' },
  { date: new Date('2024-Apr-09'), name: 'Araw Ng Kagitingan' },
  { date: new Date('2024-Apr-10'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2024-May-01'), name: 'May Day' },
  { date: new Date('2024-Jun-12'), name: 'Independence Day' },
  { date: new Date('2024-Jun-17'), name: 'Eid Al Adha' },
  { date: new Date('2024-Aug-21'), name: 'Ninoy Aquino' },
  { date: new Date('2024-Aug-26'), name: "National Heroes' Day" },
  { date: new Date('2024-Nov-01'), name: "All Saints' Day" },
  { date: new Date('2024-Nov-30'), name: 'Bonifacio Day' },
  { date: new Date('2024-Dec-08'), name: 'Immaculate Conception' },
  { date: new Date('2024-Dec-24'), name: 'Christmas Eve' },
  { date: new Date('2024-Dec-25'), name: 'Christmas' },
  { date: new Date('2024-Dec-30'), name: 'Rizal Day' },
  { date: new Date('2024-Dec-31'), name: 'Last Day of the year' },
  { date: new Date('2025-Jan-01'), name: 'New Year' },
  { date: new Date('2025-Jan-28'), name: 'Chinese New Year Eve' },
  { date: new Date('2025-Jan-29'), name: 'Chinese New Year' },
  { date: new Date('2025-Jan-30'), name: 'Chinese New Year' },
  { date: new Date('2025-Feb-25'), name: 'EDSA Revolution' },
  { date: new Date('2025-Mar-30'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2025-Apr-09'), name: 'Araw Ng Kagitingan' },
  { date: new Date('2025-Apr-17'), name: 'Maundy Thursday' },
  { date: new Date('2025-Apr-18'), name: 'Good Friday' },
  { date: new Date('2025-May-01'), name: 'May Day' },
  { date: new Date('2025-Jun-06'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2025-Jun-12'), name: 'Independence Day' },
  { date: new Date('2025-Aug-21'), name: 'Ninoy Aquino' },
  { date: new Date('2025-Aug-25'), name: "National Heroes' Day" },
  { date: new Date('2025-Nov-01'), name: "All Saints' Day" },
  { date: new Date('2025-Nov-30'), name: 'Bonifacio Day' },
  { date: new Date('2025-Dec-01'), name: 'Baringay Elections [Est]' },
  { date: new Date('2025-Dec-08'), name: 'Immaculate Conception' },
  { date: new Date('2025-Dec-24'), name: 'Christmas Eve' },
  { date: new Date('2025-Dec-25'), name: 'Christmas' },
  { date: new Date('2025-Dec-30'), name: 'Rizal Day' },
  { date: new Date('2025-Dec-31'), name: 'Last Day of the year' },
  { date: new Date('2026-Jan-01'), name: 'New Year' },
  { date: new Date('2026-Feb-17'), name: 'Chinese New Year' },
  { date: new Date('2026-Feb-25'), name: 'EDSA Revolution' },
  { date: new Date('2026-Mar-20'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2026-Apr-02'), name: 'Maundy Thursday' },
  { date: new Date('2026-Apr-03'), name: 'Good Friday' },
  { date: new Date('2026-Apr-09'), name: 'Araw Ng Kagitingan' },
  { date: new Date('2026-May-01'), name: 'May Day' },
  { date: new Date('2026-May-27'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2026-Jun-12'), name: 'Independence Day' },
  { date: new Date('2026-Aug-21'), name: 'Ninoy Aquino' },
  { date: new Date('2026-Aug-31'), name: "National Heroes' Day" },
  { date: new Date('2026-Nov-01'), name: "All Saints' Day" },
  { date: new Date('2026-Nov-30'), name: 'Bonifacio Day' },
  { date: new Date('2026-Dec-08'), name: 'Immaculate Conception' },
  { date: new Date('2026-Dec-24'), name: 'Christmas Eve' },
  { date: new Date('2026-Dec-25'), name: 'Christmas' },
  { date: new Date('2026-Dec-30'), name: 'Rizal Day' },
  { date: new Date('2026-Dec-31'), name: 'Last Day of the year' },
  { date: new Date('2027-Jan-01'), name: 'New Year' },
  { date: new Date('2027-Feb-06'), name: 'Chinese New Year' },
  { date: new Date('2027-Feb-25'), name: 'EDSA Revolution' },
  { date: new Date('2027-Mar-09'), name: 'Eid Al Fitr [Estimated]' },
  { date: new Date('2027-Mar-25'), name: 'Maundy Thursday' },
  { date: new Date('2027-Mar-26'), name: 'Good Friday' },
  { date: new Date('2027-Apr-09'), name: 'Araw Ng Kagitingan' },
  { date: new Date('2027-May-01'), name: 'May Day' },
  { date: new Date('2027-May-16'), name: 'Eid Al Adha [Estimated]' },
  { date: new Date('2027-Jun-12'), name: 'Independence Day' },
  { date: new Date('2027-Aug-21'), name: 'Ninoy Aquino' },
  { date: new Date('2027-Aug-30'), name: "National Heroes' Day" },
  { date: new Date('2027-Nov-01'), name: "All Saints' Day" },
  { date: new Date('2027-Nov-30'), name: 'Bonifacio Day' },
  { date: new Date('2027-Dec-08'), name: 'Immaculate Conception' },
  { date: new Date('2027-Dec-24'), name: 'Christmas Eve' },
  { date: new Date('2027-Dec-25'), name: 'Christmas' },
  { date: new Date('2027-Dec-30'), name: 'Rizal Day' },
  { date: new Date('2027-Dec-31'), name: 'Last Day of the year' }
];

manualHolidayMap.set('PH', PHPHolidays);
