import { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import { AdminActiveBucket } from '@grain/admin-console-api-types';
import React, { FormEvent, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit2 } from 'iconsax-react';
import { asMoney } from '@grain/common-utils';
import { isEmpty } from 'lodash';
import EditSymbolConfiguration from './EditSymbolConfiguration';
import BookActiveBucketDialog from './BookActiveBucketDialog';
import { StretchCard } from '@grain/web-components/layout/Card';
import { Table, usePagination, useTable } from '@grain/web-components/table-v2';
import { IconButton } from '@grain/web-components/buttons/IconButton';
import TextButton from '@grain/web-components/buttons/TextButton';

interface IProps {
  buckets: AdminActiveBucket[];
  setSearchTerm: (searchTerm: string) => void;
  isLoading: boolean;
}

export default function BucketsTable({ buckets, setSearchTerm, isLoading }: IProps) {
  const [editableBucket, setEditableBucket] = useState<AdminActiveBucket | null>(null);
  const [bucketToBook, setBucketToBook] = useState<AdminActiveBucket | null>(null);

  const onEditSymbol = (adminActiveBucket: AdminActiveBucket) => {
    setEditableBucket(adminActiveBucket);
  };
  const onBookBucket = (adminActiveBucket: AdminActiveBucket) => {
    setBucketToBook(adminActiveBucket);
  };

  const columns: BaseTableColumnDef<AdminActiveBucket>[] = [
    {
      accessorFn: (bucket) => bucket.symbol,
      id: 'symbol',
      header: 'Symbol'
    },
    {
      accessorFn: (bucket) => bucket.activeDays,
      id: 'activeDays',
      header: 'Active Days',
      cell: (activeDays) => {
        return `${activeDays.getValue()} Days`;
      }
    },
    {
      accessorFn: (bucket) => bucket.leadingCurrencyAmount,
      id: 'leadingCurrencyAmount',
      header: 'Lead Currency Amount',
      enableSorting: false
    },
    {
      accessorFn: (bucket) => bucket.ledCurrencyAmount,
      id: 'ledCurrencyAmount',
      header: 'Led Currency Amount',
      enableSorting: false
    },
    {
      accessorFn: (bucket) => bucket.netUSDAmount,
      id: 'netUSDAmount',
      header: 'Net Amount (USD)',
      cell: ({ row }) => {
        const { original } = row;
        const { netUSDAmount } = original;
        return asMoney(netUSDAmount, 'USD');
      }
    },
    {
      accessorFn: (bucket) => bucket.threshold,
      id: 'threshold',
      header: 'Bucket Limit (USD)',
      cell: ({ row }) => {
        const { original } = row;
        const { threshold } = original;

        const onClick = (e: FormEvent) => {
          e.preventDefault();
          onEditSymbol(original);
        };

        return (
          <div className="group flex items-center gap-2">
            <u onClick={onClick} className="cursor-pointer">
              {asMoney(threshold, 'USD', 0)}
            </u>
            <IconButton>
              <Edit2
                visibility={row.getIsHovered() ? 'visible' : 'hidden'}
                className="h-[30px] w-[16px] cursor-pointer"
                onClick={onClick}
              />
            </IconButton>
          </div>
        );
      }
    },
    createColumnHelper<AdminActiveBucket>().display({
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (!id) return null;
        return (
          <div className="flex">
            <TextButton text="Book >" underline onClick={() => onBookBucket(original)} color="blue" />
          </div>
        );
      },
      id: 'book',
      header: ''
    })
  ];

  const { paginationProps } = usePagination();

  const { table } = useTable(
    {
      columns,
      data: buckets
    },
    paginationProps
  );

  return (
    <StretchCard>
      <Table table={table} loading={isLoading} />
      {!isEmpty(editableBucket) && (
        <EditSymbolConfiguration
          editableBucket={editableBucket}
          onClose={() => setEditableBucket(null)}
          onSuccess={() => setSearchTerm(editableBucket.symbol)}
        />
      )}
      {!isEmpty(bucketToBook) && (
        <BookActiveBucketDialog
          bucketToBook={bucketToBook}
          onClose={() => setBucketToBook(null)}
          onSuccess={() => setSearchTerm(bucketToBook.symbol)}
        />
      )}
    </StretchCard>
  );
}
