import { ChangeEvent, useState } from 'react';
import BaseInput from '@grain/web-components/forms/BaseInput';
import {
  AdminConsoleApiPartnerConfig,
  CycleConfig,
  ExhaustiveCheck,
  ForwardPurchaseStrategy,
  HedgingStrategy,
  HedgeSettlementType,
  isWithManaulCancelationPercentage,
  LiquidityProviderAccountOverride,
  MaxHedgeDurationInDays,
  PCancelConfig,
  PCancelConfigType
} from '@grain/admin-console-api-types';
import EditConfigDialog from '../config/EditConfigDialog';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import './EditPartnerConfigDialog.scss';
import { MAJOR_CURRENCIES } from '@grain/rate-utils';
import { toSentenceCase } from '@grain/formatters';
import { formatLiquidityProviderAccountOptionText } from './utils';
import { useUpdatePartnerConfig } from './hooks/useUpdatePartnerConfig';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import BaseIcon from '@grain/web-components/icons/BaseIcon';
import MultiSelect from '@grain/web-components/MultiSelect/MultiSelect';
import { getDisplaySettlementType } from './currency-pairs/utils';
export default function EditPartnerConfigDialog(props: EditPartnerConfigDialogProps) {
  const { partnerId, initialData } = props;
  const { updatePartnerConfig } = useUpdatePartnerConfig();
  const [editedData, setEditedData] = useState<Partial<AdminConsoleApiPartnerConfig<any>>>();
  const booleanInputOptions = [
    { value: 'false', text: 'false' },
    { value: 'true', text: 'true' }
  ];
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const onSubmit = async (newValues: Partial<AdminConsoleApiPartnerConfig<any>>) => {
    await updatePartnerConfig({
      partnerId,
      config: newValues
    });
    setEditedData({});
  };

  const onPCancelChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as PCancelConfigType;
    const percentage = (editedData?.pCancelConfig && 'percentage' in editedData.pCancelConfig && editedData.pCancelConfig.percentage) || 0;
    const pCancelConfig = _toPcancelConfig(newValue, percentage);

    setEditedData({ ...editedData, pCancelConfig });
  };

  return (
    <EditConfigDialog
      triggerComponent={
        <CTAButton
          appearance="secondary"
          size="small"
          leadingIcon={
            <BaseIcon appearance="secondary">
              <EditIcon />
            </BaseIcon>
          }
          text={<span className="font-medium">More Details</span>}
        />
      }
      onSubmit={onSubmit}
      editedData={editedData}
      initialData={initialData}
    >
      <BaseDropdown
        name="functionalCurrency"
        labelText="Functional Currency"
        options={MAJOR_CURRENCIES.map((currency) => ({
          value: currency,
          text: currency
        }))}
        defaultValue={initialData.functionalCurrency}
        onChange={onChange}
        hideErrorText
        small
      />
      <MultiSelect
        name="settlementTypesInCycle"
        options={[HedgeSettlementType.BulkFull, HedgeSettlementType.Manual, HedgeSettlementType.Offset].map((settlementType) => ({
          value: settlementType,
          text: getDisplaySettlementType(settlementType)
        }))}
        labelText="Cycle Configuration"
        placeholder="Settlement types..."
        hideErrorText
        selected={(editedData?.settlementTypesInCycle || initialData?.settlementTypesInCycle || []).map((type) => ({
          value: type,
          text: getDisplaySettlementType(type)
        }))}
        onSelectedChange={(selected) => {
          const settlementTypes = selected.map((option) => option.value as HedgeSettlementType);
          if (selected.length === 0) {
            setEditedData({ ...editedData, settlementTypesInCycle: [], cycleConfig: CycleConfig.None });
          } else if (editedData?.cycleConfig === CycleConfig.None || initialData?.cycleConfig === CycleConfig.None) {
            setEditedData({ ...editedData, settlementTypesInCycle: settlementTypes, cycleConfig: CycleConfig.Monthly });
          } else {
            setEditedData({ ...editedData, settlementTypesInCycle: settlementTypes });
          }
        }}
      />
      <BaseDropdown
        name="cycleConfig"
        labelText="Cycle Frequency"
        options={_getCycleConfigOptions(initialData.settlementTypesInCycle, editedData?.settlementTypesInCycle)}
        value={editedData?.cycleConfig || initialData.cycleConfig}
        disabled={_shouldHideCycleCadenceInput(initialData.settlementTypesInCycle, editedData?.settlementTypesInCycle)}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="liquidityProviderOverrideAccount"
        labelText="Liquidity Provider Account"
        options={Object.values(LiquidityProviderAccountOverride).map((liquidityProviderAccountOverride) => ({
          value: liquidityProviderAccountOverride,
          text: formatLiquidityProviderAccountOptionText(liquidityProviderAccountOverride)
        }))}
        defaultValue={initialData.liquidityProviderOverrideAccount}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="forwardPurchaseStrategy"
        labelText="Forward Purchase Strategy"
        options={Object.values(ForwardPurchaseStrategy).map((forwardPurchaseStrategy) => ({
          value: forwardPurchaseStrategy,
          text: toSentenceCase(forwardPurchaseStrategy)
        }))}
        defaultValue={initialData.forwardPurchaseStrategy}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="pCancelConfig"
        labelText="P-Cancel Configuration"
        options={pCancelOptions}
        defaultValue={initialData.pCancelConfig?.type}
        onChange={onPCancelChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="maxHedgeDurationInDays"
        labelText="Max Hedge Duration In Days"
        options={Object.values(MaxHedgeDurationInDays).map((hd) => ({ value: hd, text: `${hd} Days` }))}
        defaultValue={initialData.maxHedgeDurationInDays}
        onChange={onChange}
        hideErrorText
        small
      />
      {_shouldShowPCancelPercentageInput(initialData?.pCancelConfig, editedData?.pCancelConfig) && (
        <BaseInput
          name="manualCancellationPercentage"
          labelText="Manual cancellation percentage"
          defaultValue={(isWithManaulCancelationPercentage(initialData?.pCancelConfig) && initialData?.pCancelConfig.percentage) || 0}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;

            const exactPercentageConfig = editedData?.pCancelConfig || initialData?.pCancelConfig;
            if (isWithManaulCancelationPercentage(exactPercentageConfig)) {
              const parsedPercentage = Number(newValue) ?? 0;
              const withUpdatedPercentage = { ...exactPercentageConfig, percentage: parsedPercentage };
              setEditedData({ ...editedData, pCancelConfig: withUpdatedPercentage });
            }
          }}
          hideErrorText
          small
        />
      )}
      <BaseInput
        name="collateralPercentage"
        labelText="Collateral Percentage"
        defaultValue={initialData.collateralPercentage}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="hedgeProposalMinutesValidDuration"
        labelText="Hedge Proposal Valid Duration in Minutes"
        defaultValue={initialData.hedgeProposalMinutesValidDuration}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="isMultiPartner"
        labelText="Is Multi Partner Enabled"
        options={booleanInputOptions}
        value={editedData?.isMultiPartner || initialData.isMultiPartner}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxOwnedPartners"
        labelText="Max Owned Partners"
        defaultValue={initialData.maxOwnedPartners}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxExposureAmountUSD"
        labelText="The maximum amount of all active hedges for the partner's organization (in USD)"
        defaultValue={initialData.maxExposureAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="minimumHedgeAmount"
        labelText="The minimum amount of a hedge (in USD)"
        defaultValue={initialData.minimumHedgeAmount}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxSingleHedgeAmountUSD"
        labelText="The maximum amount for a single hedge (in USD)"
        defaultValue={initialData.maxSingleHedgeAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxSandboxExposureAmountUSD"
        labelText="The maximum amount of all active hedges for the partner's organization (in USD) for sandbox organization"
        defaultValue={initialData.maxSandboxExposureAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxSandboxSingleHedgeAmountUSD"
        labelText="The maximum amount for a single hedge (in USD) for sandbox organization"
        defaultValue={initialData.maxSandboxSingleHedgeAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="enableReconciliation"
        labelText="Is Reconciliation Enabled"
        options={booleanInputOptions}
        value={editedData?.enableReconciliation || initialData.enableReconciliation}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="offlineRevisionTTL"
        labelText="Offline revision TTL in seconds"
        defaultValue={initialData.offlineRevisionTTL}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="hedgingStrategy"
        labelText="Hedging Strategy"
        options={Object.values(HedgingStrategy).map((strategy) => ({
          value: strategy,
          text: toSentenceCase(strategy)
        }))}
        defaultValue={initialData.hedgingStrategy}
        onChange={onChange}
        hideErrorText
        small
      />
    </EditConfigDialog>
  );
}

function _shouldHideCycleCadenceInput(initConfig?: HedgeSettlementType[], editConfig?: HedgeSettlementType[]): boolean {
  return Boolean((!editConfig && !initConfig?.length) || (editConfig && !editConfig.length));
}

function _shouldShowPCancelPercentageInput(initConfig?: PCancelConfig, editConfig?: PCancelConfig): boolean {
  if (isWithManaulCancelationPercentage(editConfig)) {
    return true;
  }
  // We are editing but not on manual cancellation percentage mode
  if (editConfig) {
    return false;
  }
  if (isWithManaulCancelationPercentage(initConfig)) {
    return true;
  }
  return false;
}

type EditPartnerConfigDialogProps = {
  partnerId: string;
  initialData: Partial<AdminConsoleApiPartnerConfig<any>>;
};

const pCancelValue = {
  withTransactions: 'with-transactions',
  withoutTransactions: 'without-transactions',
  manualCancellationPercentage: 'manual-cancellation-percentage'
} as const;

const pCancelOptions = [
  { value: pCancelValue.withTransactions, text: 'With transactions' },
  { value: pCancelValue.withoutTransactions, text: 'Without transactions' },
  { value: pCancelValue.manualCancellationPercentage, text: 'Manually override' }
] satisfies { value: PCancelConfig['type']; text: string }[];

function _toPcancelConfig(pCancelConfigType: PCancelConfigType, percentage: number): PCancelConfig {
  switch (pCancelConfigType) {
    case 'manual-cancellation-percentage':
      return { type: 'manual-cancellation-percentage', percentage };
    case 'without-transactions':
      return { type: 'without-transactions' };
    case 'with-transactions':
      return { type: 'with-transactions' };
    default: {
      throw new ExhaustiveCheck(pCancelConfigType);
    }
  }
}

function _getCycleConfigOptions(settlementTypesInCycle?: HedgeSettlementType[], editedSettlementTypesInCycle?: HedgeSettlementType[]) {
  if (_shouldHideCycleCadenceInput(settlementTypesInCycle, editedSettlementTypesInCycle)) {
    return [{ value: CycleConfig.None, text: toSentenceCase(CycleConfig.None) }];
  }
  return Object.values(CycleConfig)
    .filter((cc) => cc !== CycleConfig.None)
    .map((cycleConfig) => ({
      value: cycleConfig,
      text: toSentenceCase(cycleConfig)
    }));
}
