export const withTimeout = (fn: () => Promise<unknown>, timeout: number) => {
  return Promise.race([fn(), new Promise((_, reject) => setTimeout(() => reject(new OperationTimedOut()), timeout))]);
};

class OperationTimedOut extends Error {
  constructor() {
    super('Operation timed out');
    this.name = 'TimeoutError';
  }
}
