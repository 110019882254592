import React, { ComponentType, ReactElement } from 'react';
import { cn } from '../utils';
import { IconProps } from 'iconsax-react';
import colors from '../styles/colors.scss';
import './IconicButton.scss';

export interface IconicButtonProps {
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  icon: ComponentType<IconProps>;
  onClick?: () => void;
  text: string;
  iconSize?: string;
  disabled?: boolean;
}

export function IconicButton(props: IconicButtonProps): ReactElement {
  const { iconSize = '14px', disabled = false } = props;

  const icon = <props.icon color={colors.cta} size={iconSize} />;
  return (
    <button className={cn('iconic-button', props.className)} disabled={disabled} onClick={props.onClick} type="button">
      <span className="iconic-button-icon">{icon}</span>
      <span className="iconic-button-text">{props.text}</span>
    </button>
  );
}
