import React, { type ReactElement } from 'react';
import { flexRender, type Header, type Table } from '@tanstack/react-table';
import { ArrowDown, ArrowUp } from 'iconsax-react';
import './TableHead.scss';
import { cn } from '../utils';
import { mapAlignToFlex } from './utils/react.table.utils';

export interface TableHeadProps<TData> {
  table: Table<TData>;
}

export function TableHead<TData>(props: TableHeadProps<TData>): ReactElement {
  return (
    <thead className="table-head">
      {props.table.getHeaderGroups().map((headerGroup) => (
        <tr className="table-head-header-group" key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th className={cn('table-header', mapAlignToFlex(header.column.columnDef.meta?.align))} key={header.id}>
              <TableHeaderContent header={header} />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

interface TableHeaderContentProps<TData> {
  header: Header<TData, unknown>;
}

function TableHeaderContent<TData>(props: TableHeaderContentProps<TData>): ReactElement | null {
  const { header } = props;

  if (header.isPlaceholder) {
    return null;
  }

  const { column } = header;

  const renderedContent = flexRender(column.columnDef.header, header.getContext());

  if (!column.getCanSort()) {
    return <>{renderedContent}</>;
  }

  return (
    <div
      className={cn('table-header-sortable-container', mapAlignToFlex(header.column.columnDef.meta?.align))}
      onClick={column.getToggleSortingHandler()}
      title={
        {
          asc: 'Sort ascending',
          desc: 'Sort descending',
          false: 'Clear sort'
        }[String(column.getNextSortingOrder())]
      }
    >
      {renderedContent}
      {
        {
          asc: <ArrowUp size={16} />,
          desc: <ArrowDown size={16} />,
          false: null
        }[String(column.getIsSorted())]
      }
    </div>
  );
}
