import React from 'react';
import { Hedge } from '@grain/admin-console-api-types';
import { createColumnHelper, Table, useControlledPagination, useTable } from '@grain/web-components/table-v2';
import { calcFromAmount } from '@grain/rate-utils';
import HedgeStatusBadge from '@grain/web-components/badges/HedgeStatusBadge';
import { asAmount, asDateString } from '@grain/common-utils';

type AdminHedgesTableProps = {
  hedges: Hedge[];
  totalCount?: number;
  paginated?: boolean;
  pageSize?: number;
  noHeader?: boolean;
  showEntityName?: boolean;
  loading?: boolean;
  page: number;
  setPage: (page: number) => void;
};

const columnHelper = createColumnHelper<Hedge>();

const columns = [
  columnHelper.accessor('company.organization.partner.name', {
    header: 'Partner Name'
  }),
  columnHelper.accessor('company.name', { header: 'Customer Name' }),
  columnHelper.accessor('fromCurrency', {
    header: 'From Currency Amount',
    cell: (hedgeCell) => {
      const hedge = hedgeCell.row.original;
      return `${hedge.fromCurrency} ${asAmount(calcFromAmount(hedge.toCurrencyAmount, hedge.quote!))}`;
    }
  }),
  columnHelper.accessor('toCurrencyAmount', {
    header: 'To Currency Amount',
    cell: (hedgeCell) => {
      const hedge = hedgeCell.row.original;
      return `${hedge.toCurrency} ${asAmount(hedge.toCurrencyAmount)}`;
    }
  }),
  columnHelper.accessor('id', { header: 'ID' }),
  columnHelper.accessor('acceptedAt', {
    header: 'Created',
    cell: (hedgeCell) => asDateString(hedgeCell.getValue())
  }),
  columnHelper.accessor('endAt', {
    header: 'Due Date',
    cell: (hedgeCell) => asDateString(hedgeCell.getValue())
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (info) => (
      <div style={{ textAlign: 'center' }}>
        <HedgeStatusBadge status={info.getValue()} />
      </div>
    )
  })
];

export default function AdminHedgesTable(props: AdminHedgesTableProps) {
  const { hedges, totalCount, page, setPage } = props;

  const { paginationProps } = useControlledPagination({
    serverSide: true,
    totalItems: totalCount || 0,
    initialPageSize: 25,
    pageIndex: Number(page) || 0,
    onPageIndexChange: setPage
  });

  const { table } = useTable(
    {
      columns,
      data: hedges
    },
    paginationProps
  );

  return (
    <div className="grow">
      <Table table={table} />
    </div>
  );
}
