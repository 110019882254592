import React, { useState } from 'react';
import Page from '../../layout/Page';
import BucketsTable from './BucketsTable';
import { useActiveBuckets } from '../../../hooks';
import Searchbar from '@grain/web-components/searchbar/Searchbar';

export default function ExposurePage() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { activeBuckets = [], listActiveBucketsLoading } = useActiveBuckets();

  return (
    <Page title="Buckets" subtitle="Manage Grain's Buckets Configurations">
      <div className="partner-config-row-flex mb-4">
        <Searchbar value={searchTerm} placeholder="Search symbol" onChange={setSearchTerm} />
      </div>
      <BucketsTable
        isLoading={listActiveBucketsLoading}
        buckets={activeBuckets.filter((bucket) => bucket.symbol.toLowerCase().includes(searchTerm.toLowerCase()))}
        setSearchTerm={(symbol: string) => setSearchTerm(symbol)}
      />
    </Page>
  );
}
